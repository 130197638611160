import axios from 'axios';
import { ICityCollectionDto, ICityCollectionPayload, ICityModelDto, ICreateCityDto } from 'entities/Cities/Cities.models';

const basePath = 'city';

export const cityTransport = {
  getCityCollection: (params: ICityCollectionPayload): Promise<ICityCollectionDto> => axios.get(`${basePath}`, { params }),
  getCityById: (id: number): Promise<ICityModelDto> => axios.get(`${basePath}/${id}`),
  createCity: (params: ICreateCityDto): Promise<ICityModelDto> => axios.post(`${basePath}`, params),
};
