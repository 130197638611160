import React from 'react';
import { Button } from 'antd';
import { EDatabaseActions } from 'common/const/enum.const';

interface IComponentProps {
  action?: string;
  withFooter?: boolean;
  saveDisabled?: boolean;
  cancelDisabled?: boolean;
  children: React.ReactNode;
  onCancel?: () => void;
}

export const CommonDatabaseContainer: React.FC<IComponentProps> = (props) => {
  const { action, withFooter, saveDisabled, cancelDisabled, children, onCancel } = props;

  return (
    <div className="content">
      <div className="content__header">
        <div className="content__header-title">{action ? (action === EDatabaseActions.Add ? 'Add' : 'Edit') : 'Database'}</div>
      </div>

      <div className="content__container database__container">
        <div className="content__container_main database__container_main">{children}</div>

        {withFooter && (
          <div className="database__container_footer">
            <Button className="btn-primary" htmlType="submit" disabled={saveDisabled}>
              Save
            </Button>

            <Button className="btn-gray" onClick={onCancel} disabled={cancelDisabled}>
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
