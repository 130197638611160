import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { Header } from 'common/components/Header';
import { PrivateLayout } from 'common/components/PrivateLayout';
import { ERoutes } from 'common/const/enum.const';
import { RootDispatch } from 'app/store';
import { Login } from 'entities/Auth/components/Login';

type AllType = ReturnType<typeof mapDispatch>;

const AppComponent: React.FC<AllType> = ({ initAuthModel }) => {
  useEffect(() => {
    initAuthModel();
  }, []);

  return (
    <Layout>
      <Header />

      <Routes>
        <Route path={ERoutes.Login} element={<Login />} />
        <Route path="*" element={<PrivateLayout />} />
      </Routes>
    </Layout>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  initAuthModel: dispatch.authModel.initAuthModel,
});

export const App = connect(null, mapDispatch)(AppComponent);
