import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import { Checkbox, DatePicker, Form } from 'antd';
import { disabledDate, validUntilCustomFormat } from 'common/helpers/date.helper';
import { EFormFieldMessages, ETechnicianFieldNames } from 'common/const/enum.const';
import { RootDispatch, RootState } from 'app/store';

dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const TechnicianCredentialsComponent: React.FC<AllType> = ({ certificateCollection, getCertificateCollection }) => {
  const { data } = certificateCollection;
  const options = data?.data.map((item) => ({ label: item.code, value: item.id }));

  useEffect(() => {
    getCertificateCollection({});
  }, []);

  if (!options?.length) {
    return null;
  }

  return (
    <div className="technician-credentials">
      <span className="technician-credentials__header">Credentials</span>

      <div className="technician-credentials__container">
        {options?.map((item) => {
          return (
            <div key={item.value} className="technician-credentials__item">
              <Form.Item name={[ETechnicianFieldNames.Certificates, item.value.toString()]} valuePropName="checked">
                <Checkbox>{item.label}</Checkbox>
              </Form.Item>

              <Form.Item key={item.value} noStyle shouldUpdate={true}>
                {({ getFieldValue }) => {
                  const certificate = getFieldValue([ETechnicianFieldNames.Certificates, item.value.toString()]);

                  return (
                    <Form.Item
                      name={[ETechnicianFieldNames.ValidUntil, item.value.toString()]}
                      rules={[{ required: certificate, message: EFormFieldMessages.required }]}
                    >
                      <DatePicker
                        className="technician-credentials__container_validUntil-picker"
                        allowClear={false}
                        inputReadOnly
                        placeholder=""
                        suffixIcon={false}
                        format={validUntilCustomFormat}
                        disabled={!certificate}
                        disabledDate={disabledDate}
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  certificateCollection: state.certificateCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCertificateCollection: dispatch.certificateCollection.getCertificateCollection,
});

export const TechnicianCredentials = connect(mapState, mapDispatch)(TechnicianCredentialsComponent);
