export enum ERoutes {
  Login = '/login',
  Dashboard = '/dashboard',
  TechnicianSchedule = '/technician-schedule',
  BEManagement = '/b&e-management',
  Database = '/database/:tab?/:action?/:id?',
}

export enum ENavOptions {
  Dashboard = 'Dashboard',
  TechnicianSchedule = 'Technician Schedule',
  BEManagement = 'B&E Management',
  Database = 'Database',
}

export enum EDatabaseMenuItems {
  Hospitals = 'Hospitals',
  Facilities = 'Facilities',
  SpecialEquipment = 'Special Equipment',
  Technicians = 'Technicians',
  Studies = 'Studies',
  Certificates = 'Certificates',
}

export enum EDatabaseTabValues {
  Hospitals = 'hospitals',
  Facilities = 'facilities',
  SpecialEquipment = 'special-equipment',
  Technicians = 'technicians',
  Studies = 'studies',
  Certificates = 'certificates',
}

export enum EDatabaseActions {
  Add = 'add',
  Edit = 'edit',
  View = 'view',
}

export enum ECommonOrderFields {
  Name = 'name',
}

export enum EOrderDirections {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EFormFieldMessages {
  required = 'Field is required',
}

export enum EDaysOfSchedule {
  Mon = 'mon',
  Tue = 'tue',
  Wed = 'wed',
  Thu = 'thu',
  Fri = 'fri',
  Sat = 'sat',
  Sun = 'sun',
}

export enum EScheduleFields {
  Shift = 'shift',
  Capaсity = 'capacity',
  FacilityId = 'facilityId',
}

export enum EShiftValues {
  DayOff = 'day_off',
  DayShift = 'day',
  NightShift = 'night',
}

export enum EDateFormat {
  ShortMonthShortDay = 'MMM D',
  ShortDayOfWeek = 'ddd',
  FullDayShortDayOfWeek = 'DD ddd',
  FullDate = 'YYYY-MM-DD',
  DateOfDay = 'D',
}

export enum EBedScheduleProperties {
  DayShiftBeds = 'dayShiftBeds',
  NightShiftBeds = 'nightShiftBeds',
  Equipments = 'equipments',
}

export enum EStudyFieldNames {
  Name = 'name',
  Certificates = 'certificates',
  Equipments = 'equipments',
}

export enum EStorageNames {
  Hospital = 'hospital',
  FacilityIds = 'facilityIds',
  Shift = 'shift',
}

export enum EAvailabilityStatus {
  GettingStatus = 'Getting the availability status',
  NotAvailable = 'Not Available for booking',
  Availability = 'Availability',
}

export enum ETechnicianFieldNames {
  Name = 'name',
  Capacity = 'capacity',
  Certificates = 'certificates',
  ValidUntil = 'validUntil',
  StandardSchedule = 'standardSchedule',
}
