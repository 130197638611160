import React, { useEffect, useState } from 'react';
import { Form, Input, InputNumber, Spin } from 'antd';
import { connect } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import {
  ECommonOrderFields,
  EDatabaseActions,
  EDatabaseTabValues,
  EFormFieldMessages,
  EOrderDirections,
} from 'common/const/enum.const';
import { toOptionsMapper } from 'common/helpers/data.helper';
import { IOption } from 'common/models';
import { Search } from 'common/components/Search';
import { RootDispatch, RootState, history } from 'app/store';
import { CommonDatabaseContainer } from 'entities/Database/components/CommonDatabaseContainer';
import { getDatabasePath } from 'entities/Database/Database.helper';
import { FacilityEquipments } from 'entities/Facilities/components/FacilityEquipments';
import { toFacilityPayload } from 'entities/Facilities/Facilities.helper';
import { FacilityCardCities } from 'entities/Facilities/components/FacilityCardCities';

interface IComponentProps {
  action?: string;
  id?: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const FacilityCardComponent: React.FC<AllType> = (props) => {
  const {
    action,
    id,
    stateCollection,
    facilityModel,
    hospitalModel,
    facilityEquipmentsCollection,
    getStateCollection,
    getStateById,
    getCityById,
    getHospitalModelById,
    getFacilityModelById,
    createFacilityModel,
    updateFacilityModel,
    clearFacility,
  } = props;

  const { data: stateCollectionData, loading: stateCollectionLoading } = stateCollection;
  const { data: facilityModelData, loading: facilityModelLoading } = facilityModel;
  const { data: facilityEquipmentsCollectionData } = facilityEquipmentsCollection;
  const { data: hospitalModelData, loading: hospitalModelLoading } = hospitalModel;

  const [state, setState] = useState<IOption>();
  const [form] = useForm();

  const externalId = id && Number(id);
  const formDisabled = action === EDatabaseActions.View;

  const onSubmit = (values: any) => {
    const path = getDatabasePath(EDatabaseTabValues.Facilities);
    const payload = toFacilityPayload(values, facilityEquipmentsCollectionData);

    if (action === EDatabaseActions.Add) {
      createFacilityModel({
        clinicId: Number(externalId),
        ...payload,
        onSuccess: () => history.push(path),
      });
    } else {
      updateFacilityModel({
        id: Number(externalId),
        ...payload,
        onSuccess: () => history.push(path),
      });
    }
  };

  const onCancel = () => {
    history.back();
  };

  useEffect(() => {
    if (action !== EDatabaseActions.Add && externalId) {
      getFacilityModelById(externalId);
    }

    if (action === EDatabaseActions.Add && externalId) {
      getHospitalModelById(externalId);
    }

    return () => {
      clearFacility();
    };
  }, [action, externalId]);

  useEffect(() => {
    if (action !== EDatabaseActions.Add && facilityModelData) {
      getCityById(facilityModelData.cityId).then((cityResponse) => {
        if (cityResponse) {
          getStateById(cityResponse.stateId).then((stateResponse) => {
            if (stateResponse) {
              setState({ label: stateResponse.name, value: stateResponse.id });

              form.setFieldsValue({
                name: facilityModelData.name,
                cityId: { label: cityResponse.name, value: cityResponse.id },
                capacity: facilityModelData.capacity,
                addressLine1: facilityModelData.addressLine1,
                addressLine2: facilityModelData.addressLine2,
                zip: facilityModelData.zip,
                phone: facilityModelData.phone,
                fax: facilityModelData.fax,
              });
            }
          });
        }
      });
    }
  }, [action, facilityModelData]);

  useEffect(() => {
    getStateCollection({
      limit: 0,
      orderField: ECommonOrderFields.Name,
      orderDirection: EOrderDirections.ASC,
    });
  }, []);

  return (
    <Form form={form} className="container__form" onFinish={onSubmit} disabled={formDisabled}>
      <CommonDatabaseContainer
        action={action}
        saveDisabled={facilityModelLoading || formDisabled}
        cancelDisabled={facilityModelLoading}
        withFooter
        onCancel={onCancel}
      >
        <Spin spinning={facilityModelLoading || hospitalModelLoading}>
          <div className="database__container_item">
            <Form.Item label="Hospital">
              <div>{action !== EDatabaseActions.Add ? facilityModelData?.clinicName : hospitalModelData?.name}</div>
            </Form.Item>
          </div>

          <div className="database__container_item">
            <Form.Item label="Name" name="name" rules={[{ required: true, message: EFormFieldMessages.required }]}>
              <Input />
            </Form.Item>
          </div>

          <div className="database__container_item">
            <Form.Item label="State">
              <Search
                value={state}
                disabled={action !== EDatabaseActions.Add}
                options={toOptionsMapper(stateCollectionData?.data)}
                loading={stateCollectionLoading}
                onFetch={(value) => {
                  getStateCollection({
                    name: value,
                    limit: 0,
                    orderField: ECommonOrderFields.Name,
                    orderDirection: EOrderDirections.ASC,
                  });
                }}
                onChange={(value) => {
                  setState(value);
                }}
              />
            </Form.Item>
          </div>

          <div className="database__container_item">
            <Form.Item label="City" name="cityId" rules={[{ required: true, message: EFormFieldMessages.required }]}>
              <FacilityCardCities stateId={state?.value as number} disabled={formDisabled} />
            </Form.Item>
          </div>

          <div className="database__container_item">
            <Form.Item
              label="Address Line 1"
              name="addressLine1"
              rules={[{ required: true, message: EFormFieldMessages.required }]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="database__container_item">
            <Form.Item label="Address Line 2" name="addressLine2">
              <Input />
            </Form.Item>
          </div>

          <div className="database__container_item">
            <Form.Item label="ZIP" name="zip" rules={[{ required: true, message: EFormFieldMessages.required }]}>
              <Input />
            </Form.Item>
          </div>

          <div className="database__container_item">
            <Form.Item label="Phone" name="phone" rules={[{ required: true, message: EFormFieldMessages.required }]}>
              <Input />
            </Form.Item>
          </div>

          <div className="database__container_item">
            <Form.Item label="Fax" name="fax">
              <InputNumber />
            </Form.Item>
          </div>

          <div className="database__container_item">
            <Form.Item label="Beds" name="capacity" rules={[{ required: true, message: EFormFieldMessages.required }]}>
              <InputNumber />
            </Form.Item>
          </div>

          <FacilityEquipments facilityEquipments={facilityEquipmentsCollectionData} />
        </Spin>
      </CommonDatabaseContainer>
    </Form>
  );
};

const mapState = (state: RootState) => ({
  stateCollection: state.stateCollection,
  cityCollection: state.cityCollection,
  cityModel: state.cityModel,
  facilityModel: state.facilityModel,
  hospitalModel: state.hospitalModel,
  facilityEquipmentsCollection: state.facilityEquipmentsCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getStateCollection: dispatch.stateCollection.getStateCollection,
  getStateById: dispatch.stateCollection.getStateById,
  getCityById: dispatch.cityCollection.getCityById,
  getHospitalModelById: dispatch.hospitalModel.getHospitalModelById,
  getFacilityModelById: dispatch.facilityModel.getFacilityModelById,
  createFacilityModel: dispatch.facilityModel.createFacilityModel,
  updateFacilityModel: dispatch.facilityModel.updateFacilityModel,
  clearFacility: dispatch.facilityModel.clearFacility,
});

export const FacilityCard = connect(mapState, mapDispatch)(FacilityCardComponent);
