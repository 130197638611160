import { EShiftValues } from 'common/const/enum.const';

export const scheduleShiftOptions = [
  {
    label: 'Day off',
    value: EShiftValues.DayOff,
  },
  {
    label: 'Day shift',
    value: EShiftValues.DayShift,
  },
  {
    label: 'Night shift',
    value: EShiftValues.NightShift,
  },
];
