import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { ERoutes } from 'common/const/enum.const';
import { Schedules } from 'common/components/Schedules';
import { RootState } from 'app/store';
import { Database } from 'entities/Database/components/Database';

const { Content } = Layout;

type AllType = ReturnType<typeof mapState>;

const PrivateLayoutComponent: React.FC<AllType> = ({ authModel }) => {
  const { data, loading } = authModel;

  if (!data && !loading) {
    return <Navigate to={ERoutes.Login} />;
  }

  if (!data && loading) {
    return null;
  }

  return (
    <Content className="container">
      <Routes>
        <Route element={<Schedules />} />
        <Route path={ERoutes.Database} element={<Database />} />
        <Route path="*" element={<Schedules />} />
      </Routes>
    </Content>
  );
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
});

export const PrivateLayout = connect(mapState)(PrivateLayoutComponent);
