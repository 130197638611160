import React, { useEffect, useState } from 'react';
import { Button, Divider, Input, Select, Space, Typography } from 'antd';
import { connect } from 'react-redux';
import { ECommonOrderFields, EOrderDirections } from 'common/const/enum.const';
import { toOptionsMapper } from 'common/helpers/data.helper';
import { DEFAULT_DEBOUNCE_DELAY, DEFAULT_PAGE_ID } from 'common/config';
import { showError } from 'common/helpers/error.helper';
import { IOption } from 'common/models';
import { useDebounce } from 'common/hooks/useDebounce';
import { RootState } from 'app/store';
import { ICityModelDto } from 'entities/Cities/Cities.models';
import { cityTransport } from 'entities/Cities/Cities.transport';

interface IComponentProps {
  stateId?: number;
  disabled: boolean;
  value?: IOption;
  onChange?: (value?: IOption) => void;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const SCROLLING_PAGE_SIZE = 100;

const FacilityCardCitiesComponent: React.FC<AllType> = (props) => {
  const { stateId, onChange, disabled, value } = props;

  const [cities, setCities] = useState<ICityModelDto[]>([]);
  const [citiesLoading, setCitiesLoading] = useState<boolean>(false);
  const [newCityName, setNewCityName] = useState<string>();
  const [page, setPage] = useState<number>(DEFAULT_PAGE_ID);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isDropdownOpened, setIsDropdownOpened] = useState<boolean>(false);

  const debouncedSearchValue = useDebounce(searchValue, DEFAULT_DEBOUNCE_DELAY);

  const onAddNewCity = () => {
    if (stateId && newCityName) {
      cityTransport
        .createCity({
          name: newCityName,
          stateId,
        })
        .then((city) => {
          handleCityChange({ value: city.id, label: city.name });
          setNewCityName(undefined);
          setIsDropdownOpened(false);
          setCities((cities) => [city, ...cities]);
        })
        .catch((error) => showError(error));
    }
  };

  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleScroll = (e: any) => {
    const selectPopup = e.target;
    const isAtBottom = selectPopup.scrollTop + selectPopup.clientHeight > selectPopup.scrollHeight - 450;

    if (isAtBottom && !citiesLoading) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleCityChange = (value: IOption) => {
    onChange && onChange(value);
  };

  useEffect(() => {
    setCitiesLoading(true);
    cityTransport
      .getCityCollection({
        offset: SCROLLING_PAGE_SIZE * (page - 1),
        limit: SCROLLING_PAGE_SIZE,
        stateId,
        name: searchValue,
        orderField: ECommonOrderFields.Name,
        orderDirection: EOrderDirections.ASC,
      })
      .then(({ data }) => {
        const items: any[] = [];
        const idsSet = new Set<number>();

        if (cities.length) {
          cities.forEach((item) => {
            if (!idsSet.has(item.id)) {
              idsSet.add(item.id);
              items.push(item);
            }
          });
        }

        if (data?.length) {
          data.forEach((item) => {
            if (!idsSet.has(item.id)) {
              idsSet.add(item.id);
              items.push(item);
            }
          });
        }
        setCities(items);
        setCitiesLoading(false);
      })
      .catch((error) => showError(error));
  }, [page]);

  useEffect(() => {
    cityTransport
      .getCityCollection({
        stateId,
        name: searchValue,
        limit: SCROLLING_PAGE_SIZE,
        orderField: ECommonOrderFields.Name,
        orderDirection: EOrderDirections.ASC,
      })
      .then(({ data }) => {
        setCities(data);
        setPage(DEFAULT_PAGE_ID);
      })
      .catch((error) => showError(error));
  }, [stateId, debouncedSearchValue]);

  const isDuplicate = cities.some((city) => city?.name?.toLocaleLowerCase() === newCityName?.toLocaleLowerCase());

  return (
    <Select
      value={value}
      showSearch
      filterOption={false}
      options={toOptionsMapper(cities)}
      loading={citiesLoading}
      labelInValue
      disabled={!stateId || disabled}
      onSearch={onSearch}
      onPopupScroll={handleScroll}
      open={isDropdownOpened}
      onChange={(value: IOption) => {
        handleCityChange(value);
      }}
      onDropdownVisibleChange={(open) => {
        setIsDropdownOpened(open);
      }}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input placeholder="City name" value={newCityName} onChange={(e) => setNewCityName(e.target.value)} />
            <Button type="text" disabled={!newCityName || isDuplicate} onClick={onAddNewCity}>
              Add city
            </Button>
            {isDuplicate && <Typography.Text type="warning">duplicate</Typography.Text>}
          </Space>
        </>
      )}
    />
  );
};

const mapState = (state: RootState) => ({
  cityCollection: state.cityCollection,
});

export const FacilityCardCities = connect(mapState)(FacilityCardCitiesComponent);
