import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'app/store';

interface IComponentProps {
  facilityId?: number;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const TechnicianScheduleLocationItemComponent: React.FC<AllType> = ({ facilityId, facilitiesCollection }) => {
  const { data } = facilitiesCollection;
  const facility = data?.data.find((item) => item.id === facilityId);

  return (
    <div className="technician-schedule__location-item">
      <div className="technician-schedule__location-item_name">{facility?.name}</div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  facilitiesCollection: state.facilitiesCollection,
});

export const TechnicianScheduleLocationItem = connect(mapState)(TechnicianScheduleLocationItemComponent);
