import axios from 'axios';
import {
  IHospitalModelCreatePayload,
  IHospitalModelDeletePayload,
  IHospitalModelDto,
  IHospitalModelUpdatePayload,
  IHospitalsCollectionDto,
  IHospitalsCollectionPayload,
} from 'entities/Hospitals/Hospitals.models';

const basePath = 'clinic';

export const hospitalsTransport = {
  getHospitalsCollection: (params: IHospitalsCollectionPayload): Promise<IHospitalsCollectionDto> =>
    axios.get(`${basePath}`, { params }),
  getHospitalModelById: (id: number): Promise<IHospitalModelDto> => axios.get(`${basePath}/${id}`),
  createHospitalModel: (payload: IHospitalModelCreatePayload): Promise<IHospitalModelDto> => axios.post(`${basePath}`, payload),
  updateHospitalModel: (payload: IHospitalModelUpdatePayload): Promise<IHospitalModelDto> => axios.put(`${basePath}`, payload),
  deleteHospitalModel: (payload: IHospitalModelDeletePayload): Promise<IHospitalModelDto> =>
    axios.delete(`${basePath}`, { data: payload }),
};
