import React from 'react';
import { Button } from 'antd';
import { ReactComponent as Plus } from 'app/assets/images/plus.svg';

interface IComponentProps {
  value: number;
  max: number;
  onChange: (value: number) => void;
}

export const IncrementBtn: React.FC<IComponentProps> = ({ value, max, onChange }) => {
  const handleClick = () => {
    if (value < max) {
      onChange(value + 1);
    }
  };

  return <Button className="btn-icon" icon={<Plus />} onClick={handleClick} />;
};
