import React from 'react';
import { Button } from 'antd';
import { IOption } from 'common/models';
import { StudyMandatoryCertificatesItem } from 'entities/Studies/components/StudyMandatoryCertificatesItem';

interface IComponentProps {
  certificatesOptions?: IOption[];
  certificatesIds: string[];
  onAddRuleClick: () => void;
  onDeleteClick: (id: string) => void;
}

export const StudyMandatoryCertificates: React.FC<IComponentProps> = (props) => {
  const { certificatesOptions, certificatesIds, onAddRuleClick, onDeleteClick } = props;

  return (
    <div className="study-card__mandatory-certification">
      <div className="study-card__mandatory-certification_title">Mandatory Certification</div>

      {certificatesIds.map((item) => {
        return (
          <StudyMandatoryCertificatesItem
            key={item}
            id={item}
            certificatesOptions={certificatesOptions}
            onDeleteClick={onDeleteClick}
          />
        );
      })}

      <Button className="btn-gray" onClick={onAddRuleClick}>
        Add Rule
      </Button>
    </div>
  );
};
