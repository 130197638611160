import React from 'react';
import { Tooltip } from 'antd';
import { EDaysOfSchedule } from 'common/const/enum.const';
import { ReactComponent as Technician } from 'app/assets/images/technician.svg';
import { ReactComponent as Info } from 'app/assets/images/info.svg';
import { IDashboardScheduleRecord, IScheduleEquipmentDto } from 'entities/DashboardSchedule/DashboardSchedule.models';

interface IComponentProps {
  record: IDashboardScheduleRecord;
  weekDay: EDaysOfSchedule;
}

export const DashboardScheduleItem: React.FC<IComponentProps> = ({ record, weekDay }) => {
  const equipments = record[weekDay].equipments;

  const getEquipmentsTooltip = () => {
    return (
      <div>
        {equipments?.map((item: IScheduleEquipmentDto, index: number) => (
          <div key={index}>
            <span className={`dashboard-schedule__item_equipment-name ${!item.studyCount && 'bold'}`}>
              {`${item.equipmentName}: `}
            </span>
            <span className="dashboard-schedule__item_equipment-count">{item.count}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="dashboard-schedule__item">
      <div className="dashboard-schedule__item-container">
        <div className="dashboard-schedule__item-technician">
          <Technician />

          <div className="dashboard-schedule__item-technician-name">{record[weekDay].technicianName}</div>

          {!!equipments?.length && (
            <Tooltip title={getEquipmentsTooltip}>
              <Info />
            </Tooltip>
          )}
        </div>

        <div className="dashboard-schedule__item-patient">{`${record[weekDay].patientName} (${record[weekDay].studyName})`}</div>
      </div>
    </div>
  );
};
