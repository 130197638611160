import React from 'react';
import { Switch } from 'antd';

interface IComponentProps {
  value?: boolean;
  onChange: (value: boolean) => void;
}

export const ShiftFilter: React.FC<IComponentProps> = ({ value, onChange }) => {
  return (
    <div className="shift-filter">
      <div className={`${!value && 'shift-filter__item-selected'}`}>Night shift</div>
      <Switch checked={value} defaultChecked onChange={onChange} />
      <div className={`${value && 'shift-filter__item-selected'}`}>Day shift</div>
    </div>
  );
};
