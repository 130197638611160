import React from 'react';
import { Select } from 'antd';
import { IOption } from 'common/models';
import { ReactComponent as ChevronDown } from 'app/assets/images/chevron-down.svg';

interface IComponentProps {
  className?: string;
  options?: IOption[];
  value?: IOption | IOption[];
  disabled?: boolean;
  mode?: 'multiple' | 'tags';
  onChange?: (value: IOption | IOption[]) => void;
  showSearch?: boolean;
}

export const Selector: React.FC<IComponentProps> = ({ className, showSearch, options, value, disabled, mode, onChange }) => {
  const filterOption = (input: string, option?: IOption) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Select
      showSearch={showSearch}
      filterOption={filterOption}
      className={className}
      mode={mode}
      labelInValue
      options={options}
      value={value}
      onChange={onChange}
      suffixIcon={<ChevronDown />}
      disabled={disabled}
    />
  );
};
