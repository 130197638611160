import React from 'react';
import { Menu, type MenuProps } from 'antd';
import { useParams } from 'react-router-dom';
import { EDatabaseTabValues } from 'common/const/enum.const';
import { history } from 'app/store';
import { databaseMenuItems } from 'entities/Database/Database.const';
import { Hospitals } from 'entities/Hospitals/components/Hospitals';
import { CommonDatabaseContainer } from 'entities/Database/components/CommonDatabaseContainer';
import { HospitalCard } from 'entities/Hospitals/components/HospitalCard';
import { getDatabasePath } from 'entities/Database/Database.helper';
import { Facilities } from 'entities/Facilities/components/Facilities';
import { FacilityCard } from 'entities/Facilities/components/FacilityCard';
import { Equipments } from 'entities/Equipments/components/Equipments';
import { EquipmentCard } from 'entities/Equipments/components/EquipmentCard';
import { Technicians } from 'entities/Technicians/components/Technicians';
import { TechnicianCard } from 'entities/Technicians/components/TechnicianCard';
import { Studies } from 'entities/Studies/components/Studies';
import { StudyCard } from 'entities/Studies/components/StudyCard';
import { Certificates } from 'entities/Certificates/components/Certificates';
import { CertificateCard } from 'entities/Certificates/components/CertificateCard';

export const Database: React.FC = () => {
  const { tab, action, id } = useParams();

  const onTabChange: MenuProps['onClick'] = (e) => {
    const path = getDatabasePath(e.key);

    history.push(path);
  };

  const getDatabaseContent = () => {
    switch (tab) {
      case EDatabaseTabValues.Facilities: {
        return <Facilities />;
      }
      case EDatabaseTabValues.SpecialEquipment: {
        return <Equipments />;
      }
      case EDatabaseTabValues.Technicians: {
        return <Technicians />;
      }
      case EDatabaseTabValues.Studies: {
        return <Studies />;
      }
      case EDatabaseTabValues.Certificates: {
        return <Certificates />;
      }
      default: {
        return <Hospitals />;
      }
    }
  };

  const getDatabaseCard = () => {
    switch (tab) {
      case EDatabaseTabValues.Facilities: {
        return <FacilityCard action={action} id={id} />;
      }
      case EDatabaseTabValues.SpecialEquipment: {
        return <EquipmentCard action={action} id={id} />;
      }
      case EDatabaseTabValues.Technicians: {
        return <TechnicianCard action={action} id={id} />;
      }
      case EDatabaseTabValues.Studies: {
        return <StudyCard action={action} id={id} />;
      }
      case EDatabaseTabValues.Certificates: {
        return <CertificateCard action={action} id={id} />;
      }
      default: {
        return <HospitalCard action={action} id={id} />;
      }
    }
  };

  if (action) {
    return getDatabaseCard();
  }

  return (
    <CommonDatabaseContainer>
      <div className="database__container_item database__container_main-menu">
        <Menu
          items={databaseMenuItems}
          mode="horizontal"
          selectedKeys={[tab || EDatabaseTabValues.Hospitals]}
          onClick={onTabChange}
        />
      </div>

      {getDatabaseContent()}
    </CommonDatabaseContainer>
  );
};
