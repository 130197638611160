import axios from 'axios';
import {
  IFacilityCollectionDto,
  IFacilityCollectionPayload,
  IFacilityModelCreatePayload,
  IFacilityModelDeletePayload,
  IFacilityModelDto,
  IFacilityModelUpdatePayload,
} from 'entities/Facilities/Facilities.models';

const basePath = 'facility';

export const facilitiesTransport = {
  getFasilitiesCollection: (params: IFacilityCollectionPayload): Promise<IFacilityCollectionDto> =>
    axios.get(`${basePath}`, { params }),
  getFacilityModelById: (id: number): Promise<IFacilityModelDto> => axios.get(`${basePath}/${id}`),
  createFacilityModel: (payload: IFacilityModelCreatePayload): Promise<IFacilityModelDto> => axios.post(`${basePath}`, payload),
  updateFacilityModel: (payload: IFacilityModelUpdatePayload): Promise<IFacilityModelDto> => axios.put(`${basePath}`, payload),
  deleteFacilityModel: (payload: IFacilityModelDeletePayload): Promise<IFacilityModelDto> =>
    axios.delete(`${basePath}`, { data: payload }),
};
