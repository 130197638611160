import { createModel } from '@rematch/core';
import { showError } from 'common/helpers/error.helper';
import { IRootModel } from 'app/store';
import { ICityCollection } from 'entities/Cities/Cities.models';
import { cityTransport } from 'entities/Cities/Cities.transport';

export const cityCollection = createModel<IRootModel>()({
  state: {
    data: [],
    count: 0,
    loading: false,
  } as ICityCollection,
  reducers: {
    setCityCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearCityCollection: (state) => ({ ...state, count: 0, data: [] }),
  },
  effects: (dispatch) => ({
    async getCityById(id) {
      dispatch.cityCollection.setCityCollectionLoading(true);
      return await cityTransport
        .getCityById(id)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          showError(error);
          return null;
        })
        .finally(() => {
          dispatch.cityCollection.setCityCollectionLoading(false);
        });
    },
  }),
});
