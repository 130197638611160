import React, { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { connect } from 'react-redux';
import { EDatabaseActions, EDatabaseTabValues, EOrderDirections, ECommonOrderFields } from 'common/const/enum.const';
import { DatabaseTable } from 'common/components/DatabaseTable';
import { toDataSourseMapper } from 'common/helpers/data.helper';
import { ConfirmationModal } from 'common/components/СonfirmationModal';
import { RootDispatch, RootState, history } from 'app/store';
import { getDatabaseColumns, getDatabasePath } from 'entities/Database/Database.helper';
import { databaseColumns } from 'entities/Database/Database.const';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const StudiesComponent: React.FC<AllType> = (props) => {
  const { studiesCollection, studyModel, getStudiesCollection, clearStudiesCollection, deleteStudyModel } = props;
  const [orderField, setOrderField] = useState<ECommonOrderFields>(ECommonOrderFields.Name);
  const [orderDirection, setOrderDirection] = useState<EOrderDirections | undefined>(EOrderDirections.ASC);
  const [studyId, setStudyId] = useState<number | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { data: studiesCollectionData, loading: studiesCollectionLoading } = studiesCollection;
  const { loading: studyModelLoading } = studyModel;

  const dataSource = toDataSourseMapper(studiesCollectionData?.data);

  const addStudies = () => {
    const path = getDatabasePath(EDatabaseTabValues.Studies, EDatabaseActions.Add);

    history.push(path);
  };

  const onEdit = (id: number) => {
    const path = getDatabasePath(EDatabaseTabValues.Studies, EDatabaseActions.Edit, id);

    history.push(path);
  };

  const onDelete = (id: number) => {
    setStudyId(id);
    setOpen(true);
  };

  const onYesClick = () => {
    studyId &&
      deleteStudyModel({
        id: studyId,
        onSuccess: () => {
          getStudiesCollection({ orderField, orderDirection });
          setStudyId(null);
          setOpen(false);
        },
      });
  };

  const onNoClick = () => {
    setStudyId(null);
    setOpen(false);
  };

  const handleSort = (orderField: ECommonOrderFields, orderDirection?: EOrderDirections) => {
    setOrderField(orderField);
    setOrderDirection(orderDirection);
  };

  const columns = getDatabaseColumns(databaseColumns, onEdit, onDelete);

  useEffect(() => {
    getStudiesCollection({ orderField, orderDirection });

    return () => {
      clearStudiesCollection();
    };
  }, [orderField, orderDirection]);

  return (
    <Spin spinning={studiesCollectionLoading}>
      <div className="database__container_main-header">
        <Button className="btn-primary" onClick={addStudies}>
          Add Studies
        </Button>
      </div>

      <DatabaseTable dataSource={dataSource} columns={columns} sort={handleSort} />

      <ConfirmationModal open={open} loading={studyModelLoading} onYesClick={onYesClick} onNoClick={onNoClick} />
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  studiesCollection: state.studiesCollection,
  studyModel: state.studyModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getStudiesCollection: dispatch.studiesCollection.getStudiesCollection,
  clearStudiesCollection: dispatch.studiesCollection.clearStudiesCollection,
  deleteStudyModel: dispatch.studyModel.deleteStudyModel,
});

export const Studies = connect(mapState, mapDispatch)(StudiesComponent);
