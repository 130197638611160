import React, { useEffect, useState } from 'react';
import { Form, Input, Spin } from 'antd';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { EDatabaseActions, EDatabaseTabValues, EFormFieldMessages, EStudyFieldNames } from 'common/const/enum.const';
import { toOptionsMapper } from 'common/helpers/data.helper';
import { Selector } from 'common/components/Selector';
import { IOption } from 'common/models';
import { RootDispatch, RootState, history } from 'app/store';
import { CommonDatabaseContainer } from 'entities/Database/components/CommonDatabaseContainer';
import { getDatabasePath } from 'entities/Database/Database.helper';
import { toStudyFormValuesMapper, toStudyPayloadMapper } from 'entities/Studies/Studies.helper';
import { StudyMandatoryCertificates } from 'entities/Studies/components/StudyMandatoryCertificates';
import { formValuesMapper } from 'entities/Technicians/Technicians.helper';

interface IComponentProps {
  action?: string;
  id?: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const StudyCardComponent: React.FC<AllType> = (props) => {
  const {
    action,
    id,
    studyModel,
    certificateCollection,
    equipmentsCollection,
    getStudyModelById,
    createStudyModel,
    updateStudyModel,
    clearStudyEntity,
    getCertificateCollection,
    getEquipmentsCollection,
  } = props;
  const { data: studyModelData, loading: studyModelLoading } = studyModel;
  const { data: certificateCollectionData, loading: certificateCollectionLoading } = certificateCollection;
  const { data: equipmentsCollectionData, loading: equipmentsCollectionLoading } = equipmentsCollection;

  const externalId = id && Number(id);
  const [form] = useForm();
  const [certificatesIds, setCertificatesIds] = useState<string[]>([]);
  const certificatesOptions = certificateCollectionData?.data.map((item) => ({ label: item.code, value: item.id }));
  const equipmentsOptions = toOptionsMapper(equipmentsCollectionData?.data);

  const onAddRuleClick = () => {
    setCertificatesIds([...certificatesIds, dayjs().valueOf().toString()]);
  };

  const onDeleteClick = (id: string) => {
    const filteredCertificatesIds = certificatesIds.filter((item) => item !== id);

    setCertificatesIds(filteredCertificatesIds);
  };

  const onValuesChange = (values: { [key: string]: string | IOption[] | Object }) => {
    const property = Object.keys(values)[0];

    if (property === EStudyFieldNames.Certificates) {
      const { rowId, colId, fieldId, value } = formValuesMapper(values);

      form.setFieldValue([rowId, colId as string, fieldId as string], value);
    }
  };

  const onSubmit = (values: { [key: string]: string | IOption[] | Object }) => {
    const payload = toStudyPayloadMapper(values);
    const path = getDatabasePath(EDatabaseTabValues.Studies);

    if (action === EDatabaseActions.Add) {
      createStudyModel({
        ...payload,
        onSuccess: () => history.push(path),
      });
    } else {
      updateStudyModel({
        id: externalId as number,
        ...payload,
        onSuccess: () => history.push(path),
      });
    }
  };

  const onCancel = () => {
    history.back();
  };

  useEffect(() => {
    if (action !== EDatabaseActions.Add && externalId) {
      getStudyModelById(externalId);
    }

    return () => {
      clearStudyEntity();
    };
  }, [action, externalId]);

  useEffect(() => {
    getCertificateCollection({});
    getEquipmentsCollection({});
  }, []);

  useEffect(() => {
    if (studyModelData) {
      setCertificatesIds(studyModelData.credentials.map((_, index) => index.toString()));
      const formValues = toStudyFormValuesMapper(studyModelData);

      form.setFieldsValue(formValues);
    }
  }, [studyModelData]);

  return (
    <Form form={form} className="container__form" onFinish={onSubmit} onValuesChange={onValuesChange}>
      <CommonDatabaseContainer
        action={action}
        saveDisabled={studyModelLoading}
        cancelDisabled={studyModelLoading}
        withFooter
        onCancel={onCancel}
      >
        <Spin spinning={studyModelLoading || certificateCollectionLoading || equipmentsCollectionLoading}>
          <div className="database__container_item">
            <Form.Item
              label="Name"
              name={EStudyFieldNames.Name}
              rules={[{ required: true, message: EFormFieldMessages.required }]}
            >
              <Input />
            </Form.Item>
          </div>

          <StudyMandatoryCertificates
            certificatesOptions={certificatesOptions}
            certificatesIds={certificatesIds}
            onAddRuleClick={onAddRuleClick}
            onDeleteClick={onDeleteClick}
          />

          <div className="database__container_item">
            <Form.Item label="Special Equipment required" name={EStudyFieldNames.Equipments}>
              <Selector options={equipmentsOptions} disabled={!equipmentsOptions?.length} mode="multiple" />
            </Form.Item>
          </div>
        </Spin>
      </CommonDatabaseContainer>
    </Form>
  );
};

const mapState = (state: RootState) => ({
  studyModel: state.studyModel,
  certificateCollection: state.certificateCollection,
  equipmentsCollection: state.equipmentsCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getStudyModelById: dispatch.studyModel.getStudyModelById,
  createStudyModel: dispatch.studyModel.createStudyModel,
  updateStudyModel: dispatch.studyModel.updateStudyModel,
  clearStudyEntity: dispatch.studyModel.clearStudyEntity,
  getCertificateCollection: dispatch.certificateCollection.getCertificateCollection,
  getEquipmentsCollection: dispatch.equipmentsCollection.getEquipmentsCollection,
});

export const StudyCard = connect(mapState, mapDispatch)(StudyCardComponent);
