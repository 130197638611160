import { createModel } from '@rematch/core';
import { showError } from 'common/helpers/error.helper';
import { IRootModel } from 'app/store';
import {
  IBedScheduleCollection,
  IBedScheduleCollectionChangePayload,
  IBedScheduleCollectionParams,
  IBedScheduleCollectionUpdatePayload,
  IBedScheduleDto,
} from 'entities/BedSchedules/BedSchedules.models';
import { bedScheduleTransport } from 'entities/BedSchedules/BedSchedules.transport';
import { toBedScheduleItemMapper } from 'entities/BedSchedules/BedSchedules.helper';

export const bedScheduleCollection = createModel<IRootModel>()({
  state: {
    data: null,
    formChanged: false,
    loading: false,
  } as IBedScheduleCollection,
  reducers: {
    setBedScheduleCollection: (state, payload: IBedScheduleDto[]) => ({ ...state, data: payload }),
    setBedScheduleCollectionFormChanged: (state, payload: boolean) => ({ ...state, formChanged: payload }),
    setBedScheduleCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearBedScheduleCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getBedScheduleCollection(params: IBedScheduleCollectionParams) {
      dispatch.bedScheduleCollection.setBedScheduleCollectionLoading(true);
      await bedScheduleTransport
        .getBedScheduleCollection(params)
        .then((response) => {
          dispatch.bedScheduleCollection.setBedScheduleCollectionFormChanged(false);
          dispatch.bedScheduleCollection.setBedScheduleCollection(response.data);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.bedScheduleCollection.setBedScheduleCollectionLoading(false);
        });
    },
    changeBedScheduleCollection(payload: IBedScheduleCollectionChangePayload, models) {
      dispatch.bedScheduleCollection.setBedScheduleCollectionFormChanged(true);
      const { record, weekDay } = payload;
      const schedule = models.bedScheduleCollection.data;

      if (schedule) {
        const scheduleItem = schedule.find((item) => item.facilityId === record.facilityId && item.date === record[weekDay].date);

        if (scheduleItem) {
          const newScheduleItem = toBedScheduleItemMapper(payload, scheduleItem);

          const filteredSchedule = schedule.filter(
            (item) => item.id !== scheduleItem.id || item.scheduleId !== scheduleItem.scheduleId,
          );
          // @ts-ignore
          dispatch.bedScheduleCollection.setBedScheduleCollection([...filteredSchedule, newScheduleItem]);
        } else {
          const newScheduleItem = toBedScheduleItemMapper(payload);
          // @ts-ignore
          dispatch.bedScheduleCollection.setBedScheduleCollection([...schedule, newScheduleItem]);
        }
      }
    },
    async updateBedScheduleCollection(payload: IBedScheduleCollectionUpdatePayload, models) {
      dispatch.bedScheduleCollection.setBedScheduleCollectionLoading(true);
      await bedScheduleTransport
        .updateBedScheduleCollection(payload)
        .then((response) => {
          if (response && response.items.every((item) => !item.error) && payload.onSuccess) {
            payload.onSuccess();
          }

          if (response.items.some((item) => item.error)) {
            const schedule = models.bedScheduleCollection.data || [];

            response.items.forEach((res) => {
              const scheduleItem = schedule.find(
                (item) => item.facilityId === res.sourceItem?.facilityId && item.date === res.sourceItem?.date,
              );

              if (scheduleItem) {
                scheduleItem.error = res.error;
              }

              dispatch.bedScheduleCollection.setBedScheduleCollection([...schedule]);
            });
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.bedScheduleCollection.setBedScheduleCollectionLoading(false);
        });
    },
  }),
});
