import { createModel } from '@rematch/core';
import { showError } from 'common/helpers/error.helper';
import { IRootModel } from 'app/store';
import { studiesTransport } from 'entities/Studies/Studies.transport';
import {
  IStudiesCollectionPayload,
  IStudiesCollectionDto,
  IStudyModelDto,
  IStudyModelCreatePayload,
  IStudyModelUpdatePayload,
  IStudyModelDeletePayload,
  IStudiesCollection,
  IStudyModel,
} from 'entities/Studies/Studies.models';

export const studiesCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IStudiesCollection,
  reducers: {
    setStudiesCollection: (state, payload: IStudiesCollectionDto) => ({ ...state, data: payload }),
    setStudiesCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearStudiesCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getStudiesCollection(params: IStudiesCollectionPayload) {
      dispatch.studiesCollection.setStudiesCollectionLoading(true);
      await studiesTransport
        .getStudiesCollection(params)
        .then((response) => {
          dispatch.studiesCollection.setStudiesCollection(response);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.studiesCollection.setStudiesCollectionLoading(false);
        });
    },
  }),
});

export const studyModel = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IStudyModel,
  reducers: {
    setStudyModel: (state, payload: IStudyModelDto) => ({ ...state, data: payload }),
    setStudyModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearStudyModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getStudyModelById(id: number) {
      dispatch.studyModel.setStudyModelLoading(true);
      await studiesTransport
        .getStudyModelById(id)
        .then((response) => {
          dispatch.studyModel.setStudyModel(response);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.studyModel.setStudyModelLoading(false);
        });
    },
    async createStudyModel(payload: IStudyModelCreatePayload) {
      dispatch.studyModel.setStudyModelLoading(true);
      await studiesTransport
        .createStudyModel(payload)
        .then((response) => {
          dispatch.studyModel.setStudyModel(response);

          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.studyModel.setStudyModelLoading(false);
        });
    },
    async updateStudyModel(payload: IStudyModelUpdatePayload) {
      dispatch.studyModel.setStudyModelLoading(true);
      await studiesTransport
        .updateStudyModel(payload)
        .then((response) => {
          dispatch.studyModel.setStudyModel(response);

          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.studyModel.setStudyModelLoading(false);
        });
    },
    async deleteStudyModel(payload: IStudyModelDeletePayload) {
      dispatch.studyModel.setStudyModelLoading(true);
      await studiesTransport
        .deleteStudyModel(payload)
        .then(() => {
          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.studyModel.setStudyModelLoading(false);
        });
    },
    clearStudyEntity() {
      dispatch.studyModel.clearStudyModel();
      dispatch.equipmentsCollection.clearEquipmentsCollection();
      dispatch.stateCollection.clearStateCollection();
    },
  }),
});
