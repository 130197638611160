import React from 'react';
import { Selector } from 'common/components/Selector';
import { IOption } from 'common/models';

interface IComponentProps {
  options?: IOption[];
  value: IOption;
  onChange: (value: IOption) => void;
}

export const TechnicianScheduleHeader: React.FC<IComponentProps> = ({ options, value, onChange }) => {
  const handleTechnicianChange = (value: IOption | IOption[]) => {
    onChange(value as IOption);
  };

  return (
    <div className="content__header technician-schedule__header">
      <div className="content__header-title">Technician Schedule</div>

      <div className="technician-schedule__header_technician-filter">
        <span>Technician</span>

        <Selector options={options} value={value} onChange={handleTechnicianChange} />
      </div>
    </div>
  );
};
