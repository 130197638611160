import { createModel } from '@rematch/core';
import { showError } from 'common/helpers/error.helper';
import { IRootModel } from 'app/store';
import { technicianScheduleTransport } from 'entities/TechnicianSchedule/TechnicianSchedule.transport';
import {
  ITechnicianScheduleCollection,
  ITechnicianScheduleCollectionPayload,
  ITechnicianScheduleCollectionUpdatePayload,
  ITechnicianScheduleDto,
  ITechnicianScheduleEntityChangePayload,
} from 'entities/TechnicianSchedule/TechnicianSchedule.models';
import { ITechnicianModelDto } from 'entities/Technicians/Technicians.models';
import { toTechnicianScheduleItemMapper } from 'entities/TechnicianSchedule/TechnicianSchedule.helper';

export const technicianScheduleCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ITechnicianScheduleCollection,
  reducers: {
    setTechnicianScheduleCollection: (state, payload: ITechnicianScheduleDto[]) => ({ ...state, data: payload }),
    setTechnicianScheduleCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearTechnicianScheduleCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getTechnicianScheduleCollection(params: ITechnicianScheduleCollectionPayload) {
      dispatch.technicianScheduleCollection.setTechnicianScheduleCollectionLoading(true);
      await technicianScheduleTransport
        .getTechnicianScheduleCollection(params)
        .then((response) => {
          dispatch.technicianScheduleCollection.setTechnicianScheduleCollection(response.data);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.technicianScheduleCollection.setTechnicianScheduleCollectionLoading(false);
        });
    },
    changeTechnicianScheduleCollection(payload: ITechnicianScheduleEntityChangePayload, models) {
      const schedule: ITechnicianScheduleDto[] = models.technicianScheduleCollection.data || [];
      const techniciansCollection: ITechnicianModelDto[] = models.techniciansCollection.data?.data || [];
      const scheduleItem = schedule?.find((item) => item.technicianId === Number(payload.rowId) && item.date === payload.colId);

      if (scheduleItem) {
        const newScheduleItem = toTechnicianScheduleItemMapper(payload, scheduleItem);

        const filteredSchedule = schedule?.filter(
          (item) => item.id !== scheduleItem.id || item.scheduleId !== scheduleItem.scheduleId,
        );

        if (filteredSchedule) {
          dispatch.technicianScheduleCollection.setTechnicianScheduleCollection([...filteredSchedule, newScheduleItem]);
        }
      } else {
        const technician = techniciansCollection.find((item) => item.id === Number(payload.rowId));
        const newScheduleItem = toTechnicianScheduleItemMapper(payload, scheduleItem, technician);

        dispatch.technicianScheduleCollection.setTechnicianScheduleCollection([...schedule, newScheduleItem]);
      }
    },
    async updateTechnicianScheduleCollection(payload: ITechnicianScheduleCollectionUpdatePayload) {
      dispatch.technicianScheduleCollection.setTechnicianScheduleCollectionLoading(true);
      return await technicianScheduleTransport
        .updateTechnicianScheduleCollection(payload)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          showError(error);
          return null;
        })
        .finally(() => {
          dispatch.technicianScheduleCollection.setTechnicianScheduleCollectionLoading(false);
        });
    },
    clearTechnicianSchedule() {
      dispatch.techniciansCollection.clearTechniciansCollection();
      dispatch.technicianScheduleCollection.clearTechnicianScheduleCollection();
    },
  }),
});
