import type { DatePickerProps } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { RangePickerProps } from 'antd/es/date-picker';
import { EDateFormat } from 'common/const/enum.const';

export const dateFilterCustomFormat: DatePickerProps['format'] = (value) =>
  `${dayjs(value).day(1).format(EDateFormat.ShortMonthShortDay)} - ${dayjs(value).day(7).format(EDateFormat.ShortMonthShortDay)}`;

export const validUntilCustomFormat: DatePickerProps['format'] = (value) => {
  const diff = value.diff(dayjs());
  const date = value.format(EDateFormat.FullDate);

  if (diff < 0) {
    return `expired ${date}`;
  }

  return `until ${date}`;
};

export const getWeekDays = (dateFrom: Dayjs) => {
  const week: { [key: string]: string } = {};

  // 1 - Monday, 7 - Sunday
  for (let i = 1; i <= 7; i++) {
    week[dateFrom.day(i).format(EDateFormat.ShortDayOfWeek).toLowerCase()] = dateFrom.day(i).format(EDateFormat.FullDate);
  }

  return week;
};

export const getColumnTitle = (dateFrom: Dayjs, index: number) => dateFrom.day(index).format(EDateFormat.FullDayShortDayOfWeek);

export const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current && current < dayjs().endOf('day');
};
