import axios from 'axios';
import {
  IAvailableEntitiesCollectionDto,
  IAvailableEntitiesCollectionPayload,
} from 'entities/AvailableEntities/AvailableEntities.models';

const basePath = 'available';

export const availableEntitiesTransport = {
  getAvailableEntitiesCollection: (payload: IAvailableEntitiesCollectionPayload): Promise<IAvailableEntitiesCollectionDto> =>
    axios.post(`${basePath}/studies`, payload),
};
