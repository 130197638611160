import React, { useEffect } from 'react';
import { Table } from 'antd';
import { connect } from 'react-redux';
import { toOptionsMapper } from 'common/helpers/data.helper';
import { RootDispatch, RootState } from 'app/store';
import {
  getTechnicianStandardScheduleRecords,
  toTechnicianStandardScheduleDataSourseMapper,
} from 'entities/Technicians/Technicians.helper';
import { ITechnicianStandardScheduleModelDto } from 'entities/Technicians/Technicians.models';

interface IComponentProps {
  schedule: ITechnicianStandardScheduleModelDto;
  clinicId?: number;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const TechnicianStandardScheduleComponent: React.FC<AllType> = (props) => {
  const { schedule, clinicId, facilitiesCollection, getFacilitiesCollection } = props;
  const { data: facilitiesCollectionData } = facilitiesCollection;

  const facilityOptions = toOptionsMapper(facilitiesCollectionData?.data);
  const dataSource = toTechnicianStandardScheduleDataSourseMapper(schedule);
  const columns = getTechnicianStandardScheduleRecords(facilityOptions);

  useEffect(() => {
    if (clinicId) {
      getFacilitiesCollection({ clinicId });
    }
  }, [clinicId]);

  return (
    <div className="technician-standard-schedule">
      <div className="technician-standard-schedule__header">Standard schedule</div>

      <Table className="schedule" dataSource={dataSource} columns={columns} pagination={false} />
    </div>
  );
};

const mapState = (state: RootState) => ({
  facilitiesCollection: state.facilitiesCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getFacilitiesCollection: dispatch.facilitiesCollection.getFacilitiesCollection,
});

export const TechnicianStandardSchedule = connect(mapState, mapDispatch)(TechnicianStandardScheduleComponent);
