import React from 'react';
import { Dayjs } from 'dayjs';
import type { ColumnsType } from 'antd/es/table';
import { IOption } from 'common/models';
import { EAvailabilityStatus, EDateFormat, EDaysOfSchedule, EShiftValues } from 'common/const/enum.const';
import { IDashboardScheduleRecord, IScheduleDto } from 'entities/DashboardSchedule/DashboardSchedule.models';
import { IFacilityModelDto } from 'entities/Facilities/Facilities.models';
import { DashboardScheduleItem } from 'entities/DashboardSchedule/components/DashboardScheduleItem';
import { DashboardScheduleColHeader } from 'entities/DashboardSchedule/components/DashboardScheduleColHeader';
import { IAvailableEntityDto } from 'entities/AvailableEntities/AvailableEntities.models';

export const toDashboardScheduleParamsMapper = (dateFrom: Dayjs, shift?: boolean, hospital?: IOption, facilityId?: number) => {
  return {
    limit: 0,
    shift: shift ? EShiftValues.DayShift : EShiftValues.NightShift,
    dateFrom: dateFrom.format(EDateFormat.FullDate),
    dateTo: dateFrom.day(7).format(EDateFormat.FullDate),
    facilityIds: facilityId ? facilityId?.toString() : undefined,
    clinicId: !facilityId && hospital ? Number(hospital.value) : undefined,
  };
};

const getSchedulesForDates = (dateFrom: Dayjs, schedule: IScheduleDto[]) => {
  const schedulesForDates = [];

  for (let i = 1; i <= 7; i++) {
    const date = dateFrom.day(i).format(EDateFormat.FullDate);
    const schedulesForDate = schedule
      .filter((item) => item.date === date)
      ?.sort((a, b) => a.createdAt.localeCompare(b.createdAt));

    schedulesForDates.push(schedulesForDate);
  }

  return schedulesForDates;
};

const getBedScheduleRecord = (facility: IFacilityModelDto, bedIndex: number, dateFrom: Dayjs, bedSchedule: IScheduleDto[]) => {
  const record: IDashboardScheduleRecord = {
    key: bedIndex,
    facilityBed: `${facility.name} Bed ${bedIndex + 1}`,
  };

  for (let i = 1; i <= 7; i++) {
    const dayShortNme = dateFrom.day(i).format(EDateFormat.ShortDayOfWeek).toLowerCase();

    if (bedSchedule[i - 1]) {
      record[dayShortNme] = bedSchedule[i - 1];
    }
  }

  return record;
};

export const toDashboardSchedulesDataSourceMapper = (
  dateFrom: Dayjs,
  schedule?: IScheduleDto[],
  facilityId?: number,
  facilities?: IFacilityModelDto[],
) => {
  const facility = facilities?.find((item) => item.id === facilityId);

  if (!schedule || !facility) {
    return [];
  }

  const dataSource = [];
  const schedulesForDates = getSchedulesForDates(dateFrom, schedule);
  const scheduleSizes = schedulesForDates.map((item) => item.length);
  const maxBedCount = Math.max(...scheduleSizes, facility.capacity);

  for (let i = 0; i < maxBedCount; i++) {
    const bedSchedule = schedulesForDates.map((item) => item[i]);

    const record = getBedScheduleRecord(facility, i, dateFrom, bedSchedule);

    dataSource.push(record);
  }

  return dataSource;
};

const renderDashboardScheduleCell = (record: IDashboardScheduleRecord, weekDay: EDaysOfSchedule) => {
  if (!record[weekDay]) {
    return null;
  }

  return <DashboardScheduleItem record={record} weekDay={weekDay} />;
};

const getDashboardScheduleColumnHeader = (dateFrom: Dayjs, dateIndex: number) => {
  const date = dateFrom.day(dateIndex);

  return <DashboardScheduleColHeader date={date} />;
};

export const renderDashboardScheduleRecords = (dateFrom: Dayjs) => {
  const cellClassName = 'dashboard-schedule__cell';

  return [
    {
      title: 'Facility Bed',
      dataIndex: 'facilityBed',
    },
    {
      title: () => getDashboardScheduleColumnHeader(dateFrom, 1),
      dataIndex: EDaysOfSchedule.Mon,
      onCell: (record) => {
        return {
          className: record[EDaysOfSchedule.Mon] && cellClassName,
        };
      },
      render: (_, record) => renderDashboardScheduleCell(record, EDaysOfSchedule.Mon),
    },
    {
      title: () => getDashboardScheduleColumnHeader(dateFrom, 2),
      dataIndex: EDaysOfSchedule.Tue,
      onCell: (record) => {
        return {
          className: record[EDaysOfSchedule.Tue] && cellClassName,
        };
      },
      render: (_, record) => renderDashboardScheduleCell(record, EDaysOfSchedule.Tue),
    },
    {
      title: () => getDashboardScheduleColumnHeader(dateFrom, 3),
      dataIndex: EDaysOfSchedule.Wed,
      onCell: (record) => {
        return {
          className: record[EDaysOfSchedule.Wed] && cellClassName,
        };
      },
      render: (_, record) => renderDashboardScheduleCell(record, EDaysOfSchedule.Wed),
    },
    {
      title: () => getDashboardScheduleColumnHeader(dateFrom, 4),
      dataIndex: EDaysOfSchedule.Thu,
      onCell: (record) => {
        return {
          className: record[EDaysOfSchedule.Thu] && cellClassName,
        };
      },
      render: (_, record) => renderDashboardScheduleCell(record, EDaysOfSchedule.Thu),
    },
    {
      title: () => getDashboardScheduleColumnHeader(dateFrom, 5),
      dataIndex: EDaysOfSchedule.Fri,
      onCell: (record) => {
        return {
          className: record[EDaysOfSchedule.Fri] && cellClassName,
        };
      },
      render: (_, record) => renderDashboardScheduleCell(record, EDaysOfSchedule.Fri),
    },
    {
      title: () => getDashboardScheduleColumnHeader(dateFrom, 6),
      dataIndex: EDaysOfSchedule.Sat,
      onCell: (record) => {
        return {
          className: record[EDaysOfSchedule.Sat] && cellClassName,
        };
      },
      render: (_, record) => renderDashboardScheduleCell(record, EDaysOfSchedule.Sat),
    },
    {
      title: () => getDashboardScheduleColumnHeader(dateFrom, 7),
      dataIndex: EDaysOfSchedule.Sun,
      onCell: (record) => {
        return {
          className: record[EDaysOfSchedule.Sun] && cellClassName,
        };
      },
      render: (_, record) => renderDashboardScheduleCell(record, EDaysOfSchedule.Sun),
    },
  ] as ColumnsType<IDashboardScheduleRecord>;
};

export const getAvailabilityView = (data?: IAvailableEntityDto[], loading?: boolean) => {
  if (loading || !data) {
    return { status: EAvailabilityStatus.GettingStatus, color: 'black' };
  }

  if (data?.every((item: any) => !item.isAvailable)) {
    return { status: EAvailabilityStatus.NotAvailable, color: 'red ' };
  }

  return { status: EAvailabilityStatus.Availability, color: 'blue' };
};
