import { init, RematchRootState, RematchDispatch, Models } from '@rematch/core';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { hospitalModel, hospitalsCollection } from 'entities/Hospitals/Hospitals.slice';
import { authModel } from 'entities/Auth/Auth.slice';
import { facilitiesCollection, facilityEquipmentsCollection, facilityModel } from 'entities/Facilities/Facilities.slice';
import { stateCollection } from 'entities/States/States.slice';
import { cityCollection } from 'entities/Cities/Cities.slice';
import { equipmentModel, equipmentsCollection } from 'entities/Equipments/Equipments.slice';
import { technicianModel, technicianStandardScheduleModel, techniciansCollection } from 'entities/Technicians/Technicians.slice';
import { studiesCollection, studyModel } from 'entities/Studies/Studies.slice';
import { technicianScheduleCollection } from 'entities/TechnicianSchedule/TechnicianSchedule.slice';
import { bedScheduleCollection } from 'entities/BedSchedules/BedSchedules.slice';
import { dashboardScheduleCollection } from 'entities/DashboardSchedule/DashboardSchedule.slice';
import { availableEntitiesCollection } from 'entities/AvailableEntities/AvailableEntities.slice';
import { certificateCollection, certificateModel } from 'entities/Certificates/Certificates.slice';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
  history: createBrowserHistory(),
});

const reducers = { router: routerReducer };

export interface IRootModel extends Models<IRootModel> {
  authModel: typeof authModel;
  hospitalsCollection: typeof hospitalsCollection;
  hospitalModel: typeof hospitalModel;
  facilitiesCollection: typeof facilitiesCollection;
  facilityModel: typeof facilityModel;
  facilityEquipmentsCollection: typeof facilityEquipmentsCollection;
  stateCollection: typeof stateCollection;
  cityCollection: typeof cityCollection;
  equipmentsCollection: typeof equipmentsCollection;
  equipmentModel: typeof equipmentModel;
  techniciansCollection: typeof techniciansCollection;
  technicianModel: typeof technicianModel;
  technicianStandardScheduleModel: typeof technicianStandardScheduleModel;
  studiesCollection: typeof studiesCollection;
  studyModel: typeof studyModel;
  technicianScheduleCollection: typeof technicianScheduleCollection;
  bedScheduleCollection: typeof bedScheduleCollection;
  dashboardScheduleCollection: typeof dashboardScheduleCollection;
  availableEntitiesCollection: typeof availableEntitiesCollection;
  certificateCollection: typeof certificateCollection;
  certificateModel: typeof certificateModel;
}

const models = {
  authModel,
  hospitalsCollection,
  hospitalModel,
  facilitiesCollection,
  facilityModel,
  facilityEquipmentsCollection,
  stateCollection,
  cityCollection,
  equipmentsCollection,
  equipmentModel,
  techniciansCollection,
  technicianModel,
  technicianStandardScheduleModel,
  studiesCollection,
  studyModel,
  technicianScheduleCollection,
  bedScheduleCollection,
  dashboardScheduleCollection,
  availableEntitiesCollection,
  certificateCollection,
  certificateModel,
} as IRootModel;

const store = init<IRootModel>({
  redux: {
    reducers,
    middlewares: [routerMiddleware],
    devtoolOptions: {},
  },
  models,
});

export default store;
export const history = createReduxHistory(store);
export type RootState = RematchRootState<IRootModel>;
export type RootDispatch = RematchDispatch<IRootModel>;
