import React from 'react';
import { connect } from 'react-redux';
import { Dayjs } from 'dayjs';
import { Tooltip } from 'antd';
import { EAvailabilityStatus, EDateFormat } from 'common/const/enum.const';
import { RootState } from 'app/store';
import { getAvailabilityView } from 'entities/DashboardSchedule/DashboardSchedule.helper';
import { IAvailableEntityDto } from 'entities/AvailableEntities/AvailableEntities.models';

interface IComponentProps {
  date: Dayjs;
}

type AllType = ReturnType<typeof mapState> & IComponentProps;

const DashboardScheduleColHeaderComponent: React.FC<AllType> = React.memo((props) => {
  const { date, availableEntitiesCollection } = props;
  const data = availableEntitiesCollection.data?.[date.format(EDateFormat.ShortDayOfWeek).toLowerCase()];
  const { status, color } = getAvailabilityView(data?.data, data?.loading);

  const getTooltipTitle = () => {
    return (
      <div>
        {data?.data.map((item: IAvailableEntityDto, index: number) => (
          <div key={index}>
            <span className="dashboard-schedule__cell_title-availability-item-name">{`${item.name}: `}</span>
            <span className={`dashboard-schedule__cell_title-availability-item--${item.isAvailable}`}>
              {item.isAvailable ? '+' : '-'}
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="dashboard-schedule__cell_title">
      <div>{date.format(EDateFormat.FullDayShortDayOfWeek)}</div>

      {status === EAvailabilityStatus.Availability ? (
        <Tooltip title={getTooltipTitle}>
          <div className={`dashboard-schedule__cell_title-availability--${color}`}>{status}</div>
        </Tooltip>
      ) : (
        <div className={`dashboard-schedule__cell_title-availability--${color}`}>{status}</div>
      )}
    </div>
  );
});

const mapState = (state: RootState) => ({
  availableEntitiesCollection: state.availableEntitiesCollection,
});

export const DashboardScheduleColHeader = connect(mapState)(DashboardScheduleColHeaderComponent);
