import { clearCreds, getCreds, saveCreds } from '@axmit/axios-patch-jwt';
import { createModel } from '@rematch/core';
import { showError } from 'common/helpers/error.helper';
import { IRootModel } from 'app/store';
import { authTransport } from 'entities/Auth/Auth.transport';
import { IAuthModel, ITokenModel, refreshTokenExpiration } from 'entities/Auth/Auth.models';

export const authModel = createModel<IRootModel>()({
  state: {
    data: null,
    loading: true,
  } as IAuthModel,
  reducers: {
    setAuthModel: (state, payload: ITokenModel) => ({ ...state, data: payload }),
    setAuthModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearAuthModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async initAuthModel() {
      dispatch.authModel.setAuthModelLoading(true);
      await getCreds()
        .then(async (response: ITokenModel) => {
          const now = Math.round(Date.now() / 1000);

          if (Object.values(response).length) {
            if (response?.refresh?.expiredAt && response?.refresh?.expiredAt < now) {
              await clearCreds();
              dispatch.authModel.clearAuthModel();
              return;
            }

            if (response?.access?.expiredAt && response?.access?.expiredAt < now) {
              if (response?.refresh?.token) {
                await dispatch.authModel.refreshTokenModel(response?.refresh?.token).then((refreshResponse) => {
                  const creds = {
                    access: {
                      id: response?.access?.id as string,
                      token: refreshResponse?.access_token as string,
                      userId: response?.access?.id as string,
                      issuedAt: now,
                      expiredAt: refreshResponse?.expires_in ? now + Number(refreshResponse?.expires_in) : 0,
                      uid: response?.access?.uid as number,
                      name: response?.access?.name as string,
                    },
                    refresh: {
                      id: response.refresh?.id as string,
                      token: String(refreshResponse?.refresh_token),
                      userId: response.refresh?.id as string,
                      issuedAt: now,
                      expiredAt: now + refreshTokenExpiration,
                    },
                  };

                  saveCreds(creds);
                  dispatch.authModel.setAuthModel(creds);
                });
                return;
              } else {
                await clearCreds();
                dispatch.authModel.clearAuthModel();
                return;
              }
            }

            dispatch.authModel.setAuthModel(response);
          } else {
            await clearCreds();
            dispatch.authModel.clearAuthModel();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.authModel.setAuthModelLoading(false);
        });
    },
    async addAuthModel() {
      dispatch.authModel.setAuthModelLoading(true);
      await authTransport
        // TODO Remove after BE is ready
        .addAuthModel({ email: 'persante@persante.com', password: 'persantethebest' })
        .then((response) => {
          const now = Math.round(Date.now() / 1000);

          const creds = {
            access: {
              id: response.id,
              token: response.access_token,
              userId: response.id,
              issuedAt: now,
              expiredAt: response.expires_in ? now + Number(response.expires_in) : 0,
              uid: response.uid,
              name: response.name,
            },
            refresh: {
              id: response.id,
              token: String(response.refresh_token),
              userId: response.id,
              issuedAt: now,
              expiredAt: now + refreshTokenExpiration,
            },
          };

          saveCreds(creds);
          dispatch.authModel.setAuthModel(creds);
        })
        .catch((error) => {
          showError(error);
          dispatch.authModel.clearAuthModel();
        })
        .finally(() => {
          dispatch.authModel.setAuthModelLoading(false);
        });
    },
    async refreshTokenModel(refreshToken: string) {
      return await authTransport
        .refreshToken(refreshToken)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          showError(error);
          return null;
        });
    },
    deleteAuthModel() {
      dispatch.authModel.setAuthModelLoading(true);
      authTransport
        .deleteAuthModel()
        .then(() => {
          clearCreds();
          dispatch.authModel.clearAuthModel();
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.authModel.setAuthModelLoading(false);
        });
    },
  }),
});
