import axios from 'axios';
import {
  ITechnicianScheduleCollectionDto,
  ITechnicianScheduleCollectionPayload,
  ITechnicianScheduleCollectionUpdatePayload,
  ITechnicianScheduleCollectionUpdateResponse,
} from 'entities/TechnicianSchedule/TechnicianSchedule.models';
const basePath = 'technician-schedule';

export const technicianScheduleTransport = {
  getTechnicianScheduleCollection: (params: ITechnicianScheduleCollectionPayload): Promise<ITechnicianScheduleCollectionDto> =>
    axios.get(`${basePath}`, { params }),
  updateTechnicianScheduleCollection: (
    payload: ITechnicianScheduleCollectionUpdatePayload,
  ): Promise<ITechnicianScheduleCollectionUpdateResponse> => axios.post(`${basePath}/upsert`, payload),
};
