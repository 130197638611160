import React from 'react';
import { Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ReactComponent as EditIcon } from 'app/assets/images/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'app/assets/images/delete-icon.svg';
import { IDatabaseColumnsType } from 'entities/Database/Database.models';
import { IEquipmentModelDto } from 'entities/Equipments/Equipments.models';
import { IFacilityEquipmentEntity } from 'entities/Facilities/Facilities.models';

export const getFacilityEquipmentsColumns = (onEdit: (id: number) => void, onDelete: (id: number) => void) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Available amount',
      dataIndex: 'count',
      sorter: (a, b) => Number(a.count) - Number(b.count),
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="database-table__actions">
            <Button className="btn-icon" icon={<EditIcon />} onClick={() => onEdit(Number(record.equipmentId))} />
            <Button className="btn-icon" icon={<DeleteIcon />} onClick={() => onDelete(Number(record.equipmentId))} />
          </div>
        );
      },
      width: '108px',
    },
  ] as ColumnsType<IDatabaseColumnsType>;
};

export const getFacilityEquipmentsOptions = (
  facilityEquipments: IFacilityEquipmentEntity[] | null,
  equipments?: IEquipmentModelDto[],
) => {
  const facilityEquipmentsIds = facilityEquipments?.map((item) => item.equipmentId);
  return equipments
    ?.filter((item) => !facilityEquipmentsIds?.includes(item.id))
    .map((item) => ({ label: item.name, value: item.id }));
};

export const toFacilityPayload = (values: any, equipments: IFacilityEquipmentEntity[] | null) => {
  return {
    name: values.name,
    cityId: Number(values.cityId.value),
    capacity: values.capacity,
    addressLine1: values.addressLine1,
    addressLine2: values.addressLine2,
    zip: values.zip,
    phone: values.phone,
    fax: values.fax,
    equipments: equipments?.map((item) => ({ equipmentId: item.equipmentId, count: item.count })),
  };
};
