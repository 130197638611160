import { createModel } from '@rematch/core';
import { showError } from 'common/helpers/error.helper';
import { IRootModel } from 'app/store';
import {
  IFacilityCollection,
  IFacilityCollectionDto,
  IFacilityCollectionPayload,
  IFacilityEquipmentCreatePayload,
  IFacilityEquipmentEntity,
  IFacilityEquipmentsCollection,
  IFacilityModel,
  IFacilityModelCreatePayload,
  IFacilityModelDeletePayload,
  IFacilityModelDto,
  IFacilityModelUpdatePayload,
} from 'entities/Facilities/Facilities.models';
import { facilitiesTransport } from 'entities/Facilities/Facilities.transport';

export const facilitiesCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IFacilityCollection,
  reducers: {
    setFacilitiesCollection: (state, payload: IFacilityCollectionDto) => ({ ...state, data: payload }),
    setFacilitiesCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearFacilitiesCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getFacilitiesCollection(params: IFacilityCollectionPayload) {
      dispatch.facilitiesCollection.setFacilitiesCollectionLoading(true);
      await facilitiesTransport
        .getFasilitiesCollection(params)
        .then((response) => {
          dispatch.facilitiesCollection.setFacilitiesCollection(response);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.facilitiesCollection.setFacilitiesCollectionLoading(false);
        });
    },
  }),
});

export const facilityModel = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IFacilityModel,
  reducers: {
    setFacilityModel: (state, payload: IFacilityModelDto) => ({ ...state, data: payload }),
    setFacilityModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearFacilityModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getFacilityModelById(id: number) {
      dispatch.facilityModel.setFacilityModelLoading(true);
      await facilitiesTransport
        .getFacilityModelById(id)
        .then((response) => {
          const equipments = response.equipments.map((item) => ({
            key: item.equipmentId,
            id: item.equipmentId,
            equipmentId: item.equipmentId,
            name: item.equipmentName,
            count: item.count,
          }));

          dispatch.facilityEquipmentsCollection.setFacilityEquipmentsCollection(equipments);
          dispatch.facilityModel.setFacilityModel(response);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.facilityModel.setFacilityModelLoading(false);
        });
    },
    async createFacilityModel(payload: IFacilityModelCreatePayload) {
      dispatch.facilityModel.setFacilityModelLoading(true);
      await facilitiesTransport
        .createFacilityModel(payload)
        .then((response) => {
          dispatch.facilityModel.setFacilityModel(response);

          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.facilityModel.setFacilityModelLoading(false);
        });
    },
    async updateFacilityModel(payload: IFacilityModelUpdatePayload) {
      dispatch.facilityModel.setFacilityModelLoading(true);
      await facilitiesTransport
        .updateFacilityModel(payload)
        .then((response) => {
          dispatch.facilityModel.setFacilityModel(response);

          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.facilityModel.setFacilityModelLoading(false);
        });
    },
    async deleteFacilityModel(payload: IFacilityModelDeletePayload) {
      dispatch.facilityModel.setFacilityModelLoading(true);
      await facilitiesTransport
        .deleteFacilityModel(payload)
        .then(() => {
          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.facilityModel.setFacilityModelLoading(false);
        });
    },
    clearFacility() {
      dispatch.facilityModel.clearFacilityModel();
      dispatch.facilityEquipmentsCollection.clearFacilityEquipmentsCollection();
      dispatch.stateCollection.clearStateCollection();
      dispatch.cityCollection.clearCityCollection();
      dispatch.hospitalModel.clearHospitalModel();
    },
  }),
});

export const facilityEquipmentsCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IFacilityEquipmentsCollection,
  reducers: {
    setFacilityEquipmentsCollection: (state, payload: IFacilityEquipmentEntity[]) => ({ ...state, data: payload }),
    clearFacilityEquipmentsCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    createFacilityEquipment(payload: IFacilityEquipmentCreatePayload, models) {
      const equipmentsCollection = models.facilityEquipmentsCollection.data || [];

      dispatch.facilityEquipmentsCollection.setFacilityEquipmentsCollection([
        {
          key: Number(payload.equipment.value),
          id: Number(payload.equipment.value),
          equipmentId: Number(payload.equipment.value),
          name: payload.equipment.label,
          count: payload.count,
        },
        ...equipmentsCollection,
      ]);
    },
    updateFacilityEquipment(payload: IFacilityEquipmentCreatePayload, models) {
      const equipmentsCollection = models.facilityEquipmentsCollection.data || [];
      const equipment = equipmentsCollection.find((item) => item.equipmentId === payload.equipment.value);

      if (equipment) {
        equipment.count = payload.count;
        dispatch.facilityEquipmentsCollection.setFacilityEquipmentsCollection([...equipmentsCollection]);
      }
    },
    deleteFacilityEquipment(id: number, models) {
      const equipmentsCollection = models.facilityEquipmentsCollection.data?.filter((item) => item.equipmentId !== id) || [];

      dispatch.facilityEquipmentsCollection.setFacilityEquipmentsCollection(equipmentsCollection);
    },
  }),
});
