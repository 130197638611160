import axios from 'axios';
import {
  ICertificateCollectionDto,
  ICertificateCollectionPayload,
  ICertificateDto,
  ICertificateModelCreatePayload,
  ICertificateModelDeletePayload,
  ICertificateModelUpdatePayload,
} from 'entities/Certificates/Certificates.models';

const basePath = 'credential';

export const certificatesTransport = {
  getCertificateCollection: (params: ICertificateCollectionPayload): Promise<ICertificateCollectionDto> =>
    axios.get(`${basePath}`, { params }),
  getCertificateModelById: (id: number): Promise<ICertificateDto> => axios.get(`${basePath}/${id}`),
  createCertificateModel: (payload: ICertificateModelCreatePayload): Promise<ICertificateDto> =>
    axios.post(`${basePath}`, payload),
  updateCertificateModel: (payload: ICertificateModelUpdatePayload): Promise<ICertificateDto> =>
    axios.put(`${basePath}`, payload),
  deleteCertificateModel: (payload: ICertificateModelDeletePayload): Promise<ICertificateDto> =>
    axios.delete(`${basePath}`, { data: payload }),
};
