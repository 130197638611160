import React from 'react';
import { Layout, Button, Spin } from 'antd';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { ERoutes } from 'common/const/enum.const';
import { RootDispatch, RootState } from 'app/store';

const { Content } = Layout;

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const LoginComponent: React.FC<AllType> = ({ authModel, addAuthModel }) => {
  const { data, loading } = authModel;

  if (data) {
    return <Navigate to={ERoutes.Dashboard} />;
  }

  return (
    <Content className="login">
      <Spin spinning={loading}>
        <Button className="btn-primary" onClick={addAuthModel}>
          Login
        </Button>
      </Spin>
    </Content>
  );
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  addAuthModel: dispatch.authModel.addAuthModel,
});

export const Login = connect(mapState, mapDispatch)(LoginComponent);
