import React from 'react';
import { Dropdown, Menu, type MenuProps } from 'antd';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { headerMenuItems } from 'common/const/header.const';
import { clearStorage } from 'common/helpers/data.helper';
import { ReactComponent as UserIcon } from 'app/assets/images/user-icon.svg';
import { ReactComponent as ChevronDown } from 'app/assets/images/chevron-down.svg';
import { RootState, RootDispatch } from 'app/store';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const HeaderComponent: React.FC<AllType> = ({ authModel, deleteAuthModel }) => {
  const { data } = authModel;
  const location = useLocation();

  const logout = () => {
    clearStorage();
    deleteAuthModel();
  };

  const headerDropdownItems: MenuProps['items'] = [
    {
      label: 'Logout',
      key: 'logout',
      onClick: logout,
    },
  ];

  return (
    <div className="header">
      <div className="container header-container">
        <div className="header-container__logo">System</div>

        {data && (
          <>
            <div className="header-container__menu">
              <Menu items={headerMenuItems} mode="horizontal" selectedKeys={[`/${location.pathname.split('/')[1]}`]} />
            </div>

            <div className="header-container__profile">
              <Dropdown menu={{ items: headerDropdownItems }} trigger={['click']}>
                <a onClick={(e) => e.preventDefault()}>
                  <UserIcon className="header-container__profile_user-icon" />
                  <div>{data?.access?.name}</div>
                  <ChevronDown />
                </a>
              </Dropdown>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  authModel: state.authModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  deleteAuthModel: dispatch.authModel.deleteAuthModel,
});

export const Header = connect(mapState, mapDispatch)(HeaderComponent);
