import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import { Button, Spin, Table } from 'antd';
import { IOption } from 'common/models';
import { DateFilter } from 'common/components/DateFilter';
import { RootDispatch, RootState } from 'app/store';
import { BedSchedulesFilters } from 'entities/BedSchedules/components/BedSchedulesFilters';
import {
  renderBedSchedulesRecords,
  toBedSchedulesDataSourceMapper,
  toBedSchedulesParamsMapper,
} from 'entities/BedSchedules/BedSchedules.helper';

interface IComponentProps {
  hospitalsOptions?: IOption[];
  facilitiesOptions?: IOption[];
  hospital?: IOption;
  facilityIds?: number[];
  loading: boolean;
  onHospitalChange: (value: IOption) => void;
  onFacilityIdsChange: (value?: number[]) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const BedSchedulesComponent: React.FC<AllType> = (props) => {
  const {
    hospitalsOptions,
    facilitiesOptions,
    hospital,
    facilityIds,
    loading,
    onHospitalChange,
    onFacilityIdsChange,
    facilitiesCollection,
    bedScheduleCollection,
    getBedScheduleCollection,
    updateBedScheduleCollection,
    clearBedScheduleCollection,
  } = props;
  const { data: facilitiesCollectionData } = facilitiesCollection;
  const { data: bedScheduleCollectionData, formChanged, loading: bedScheduleCollectionLoading } = bedScheduleCollection;

  // dayjs().day(1) - Mondey
  const [dateFrom, setDateFrom] = useState<Dayjs>(dayjs().day(1));

  const dataSource = toBedSchedulesDataSourceMapper(
    dateFrom,
    bedScheduleCollectionData,
    facilityIds,
    facilitiesCollectionData?.data,
  );

  const columns = renderBedSchedulesRecords(dateFrom);
  const params = toBedSchedulesParamsMapper(dateFrom, facilityIds, hospital);

  const handleDateChange = (value: Dayjs) => {
    setDateFrom(value);
  };

  const onSaveClick = () => {
    const items = bedScheduleCollectionData
      ?.filter((item) => item.edited)
      .map((item) => ({
        id: item.id,
        facilityId: item.facilityId,
        dayShiftBeds: item.dayShiftBeds,
        nightShiftBeds: item.nightShiftBeds,
        equipments: item.equipments.map((item) => ({ equipmentId: item.equipmentId, count: item.count })),
        date: item.date,
      }));

    if (items) {
      updateBedScheduleCollection({
        items,
        onSuccess: () => getBedScheduleCollection(params),
      });
    }
  };

  const onResetClick = () => {
    getBedScheduleCollection(params);
  };

  useEffect(() => {
    if (facilityIds?.length) {
      getBedScheduleCollection(params);
    }
  }, [facilityIds, hospital, dateFrom]);

  useEffect(() => {
    return () => {
      clearBedScheduleCollection();
    };
  }, []);

  return (
    <div className="content bed-schedule">
      <div className="content__header technician-schedule__header">
        <div className="content__header-title">Bed & Equipment Management</div>
      </div>

      <div className="content__container dashboard-schedule__container">
        <BedSchedulesFilters
          hospitalsOptions={hospitalsOptions}
          facilitiesOptions={facilitiesOptions}
          hospitalValue={hospital}
          facilitiesValue={facilityIds}
          onHospitalChange={onHospitalChange}
          onFacilitiesChange={onFacilityIdsChange}
        />

        <div className="content__container_main">
          <DateFilter value={dateFrom} onChange={handleDateChange} />

          <Spin spinning={loading || bedScheduleCollectionLoading}>
            <Table className="schedule" dataSource={dataSource} columns={columns} pagination={false} />

            {formChanged && (
              <div className="technician-schedule__footer">
                <Button className="btn-primary" onClick={onSaveClick}>
                  Save
                </Button>

                <Button className="btn-bordered" onClick={onResetClick}>
                  Reset to default
                </Button>
              </div>
            )}
          </Spin>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  facilitiesCollection: state.facilitiesCollection,
  bedScheduleCollection: state.bedScheduleCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getBedScheduleCollection: dispatch.bedScheduleCollection.getBedScheduleCollection,
  updateBedScheduleCollection: dispatch.bedScheduleCollection.updateBedScheduleCollection,
  clearBedScheduleCollection: dispatch.bedScheduleCollection.clearBedScheduleCollection,
});

export const BedSchedules = connect(mapState, mapDispatch)(BedSchedulesComponent);
