import React, { useEffect, useState } from 'react';
import { Button, Spin, TablePaginationConfig } from 'antd';
import { connect } from 'react-redux';
import { EDatabaseActions, EDatabaseTabValues, EOrderDirections, ECommonOrderFields } from 'common/const/enum.const';
import { DatabaseTable } from 'common/components/DatabaseTable';
import { toDataSourseMapper } from 'common/helpers/data.helper';
import { ConfirmationModal } from 'common/components/СonfirmationModal';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_ID } from 'common/config';
import { SearchInput } from 'common/components/SearchInput';
import { RootDispatch, RootState, history } from 'app/store';
import { getDatabaseColumns, getDatabasePath } from 'entities/Database/Database.helper';
import { databaseColumns } from 'entities/Database/Database.const';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const HospitalsComponent: React.FC<AllType> = (props) => {
  const { hospitalsCollection, hospitalModel, getHospitalsCollection, clearHospitalsCollection, deleteHospitalModel } = props;
  const [orderField, setOrderField] = useState<ECommonOrderFields>(ECommonOrderFields.Name);
  const [orderDirection, setOrderDirection] = useState<EOrderDirections | undefined>(EOrderDirections.ASC);
  const [hospitalId, setHospitalId] = useState<number | null>(null);
  const [pageId, setPageId] = useState<number>(DEFAULT_PAGE_ID);
  const [search, setSearch] = useState<string>();
  const [open, setOpen] = useState<boolean>(false);
  const { data: hospitalsCollectionData, loading: hospitalsCollectionLoading } = hospitalsCollection;
  const { loading: hospitalModelLoading } = hospitalModel;

  const dataSource = toDataSourseMapper(hospitalsCollectionData?.data);

  const addHospital = () => {
    const path = getDatabasePath(EDatabaseTabValues.Hospitals, EDatabaseActions.Add);

    history.push(path);
  };

  const onEdit = (id: number) => {
    const path = getDatabasePath(EDatabaseTabValues.Hospitals, EDatabaseActions.Edit, id);

    history.push(path);
  };

  const onDelete = (id: number) => {
    setHospitalId(id);
    setOpen(true);
  };

  const onYesClick = () => {
    hospitalId &&
      deleteHospitalModel({
        id: hospitalId,
        onSuccess: () => {
          getHospitalsCollection({ orderField, orderDirection });
          setHospitalId(null);
          setOpen(false);
        },
      });
  };

  const onNoClick = () => {
    setHospitalId(null);
    setOpen(false);
  };

  const handleSort = (orderField: ECommonOrderFields, orderDirection?: EOrderDirections) => {
    setOrderField(orderField);
    setOrderDirection(orderDirection);
  };

  const columns = getDatabaseColumns(databaseColumns, onEdit, onDelete);

  const loadCollection = () => {
    getHospitalsCollection({
      name: search,
      orderField,
      orderDirection,
      limit: DEFAULT_PAGE_SIZE,
      offset: (pageId - 1) * DEFAULT_PAGE_SIZE,
    });
  };

  useEffect(() => {
    loadCollection();

    return () => {
      clearHospitalsCollection();
    };
  }, [orderField, orderDirection, pageId]);

  useEffect(() => {
    setPageId(DEFAULT_PAGE_ID);
    loadCollection();

    return () => {
      clearHospitalsCollection();
    };
  }, [search]);

  let paginationConfig: TablePaginationConfig | undefined = undefined;

  if (hospitalsCollectionData?.count) {
    paginationConfig = {
      current: Number(pageId),
      total: hospitalsCollectionData?.count,
      onChange: (pageId: number) => setPageId(pageId),
      defaultPageSize: DEFAULT_PAGE_SIZE,
    };
  }

  return (
    <Spin spinning={hospitalsCollectionLoading}>
      <div className="database__container_main-header">
        <Button className="btn-primary" onClick={addHospital}>
          Add Hospital
        </Button>
        <div className="database__container_search">
          <SearchInput value={search} onChange={setSearch} placeholder="Find hospital" />
        </div>
      </div>

      <DatabaseTable dataSource={dataSource} columns={columns} sort={handleSort} paginationConfig={paginationConfig} />

      <ConfirmationModal open={open} loading={hospitalModelLoading} onYesClick={onYesClick} onNoClick={onNoClick} />
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  hospitalsCollection: state.hospitalsCollection,
  hospitalModel: state.hospitalModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getHospitalsCollection: dispatch.hospitalsCollection.getHospitalsCollection,
  clearHospitalsCollection: dispatch.hospitalsCollection.clearHospitalsCollection,
  deleteHospitalModel: dispatch.hospitalModel.deleteHospitalModel,
});

export const Hospitals = connect(mapState, mapDispatch)(HospitalsComponent);
