import React, { useEffect } from 'react';
import { Form, Input, Spin } from 'antd';
import { connect } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { EDatabaseActions, EDatabaseTabValues, EFormFieldMessages } from 'common/const/enum.const';
import { RootDispatch, RootState, history } from 'app/store';
import { CommonDatabaseContainer } from 'entities/Database/components/CommonDatabaseContainer';
import { getDatabasePath } from 'entities/Database/Database.helper';
import { ICertificateModelCreatePayload } from 'entities/Certificates/Certificates.models';

interface IComponentProps {
  action?: string;
  id?: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const CertificateCardComponent: React.FC<AllType> = (props) => {
  const {
    action,
    id,
    certificateModel,
    getCertificateModelById,
    createCertificateModel,
    updateCertificateModel,
    clearCertificateModel,
  } = props;
  const { data: certificateModelData, loading: certificateModelLoading } = certificateModel;

  const externalId = id && Number(id);
  const [form] = useForm();

  const onSubmit = (values: ICertificateModelCreatePayload) => {
    const { code, name, issuedBy } = values;
    const path = getDatabasePath(EDatabaseTabValues.Certificates);

    if (action === EDatabaseActions.Add) {
      createCertificateModel({
        code,
        name,
        issuedBy,
        onSuccess: () => history.push(path),
      });
    } else {
      updateCertificateModel({
        id: externalId as number,
        code,
        name,
        issuedBy,
        onSuccess: () => history.push(path),
      });
    }
  };

  const onCancel = () => {
    history.back();
  };

  useEffect(() => {
    if (action !== EDatabaseActions.Add && externalId) {
      getCertificateModelById(externalId);
    }

    return () => {
      clearCertificateModel();
    };
  }, [action, externalId]);

  useEffect(() => {
    if (certificateModelData) {
      form.setFieldsValue({
        code: certificateModelData.code,
        name: certificateModelData.name,
        issuedBy: certificateModelData.issuedBy,
      });
    }
  }, [certificateModelData]);

  return (
    <Form form={form} className="container__form" onFinish={onSubmit}>
      <CommonDatabaseContainer
        action={action}
        saveDisabled={certificateModelLoading}
        cancelDisabled={certificateModelLoading}
        withFooter
        onCancel={onCancel}
      >
        <Spin spinning={certificateModelLoading}>
          <div className="database__container_item">
            <Form.Item label="Code" name="code" rules={[{ required: true, message: EFormFieldMessages.required }]}>
              <Input />
            </Form.Item>
          </div>

          <div className="database__container_item">
            <Form.Item label="Name" name="name" rules={[{ required: true, message: EFormFieldMessages.required }]}>
              <Input />
            </Form.Item>
          </div>

          <div className="database__container_item">
            <Form.Item label="Issued By" name="issuedBy">
              <Input />
            </Form.Item>
          </div>
        </Spin>
      </CommonDatabaseContainer>
    </Form>
  );
};

const mapState = (state: RootState) => ({
  certificateModel: state.certificateModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCertificateModelById: dispatch.certificateModel.getCertificateModelById,
  createCertificateModel: dispatch.certificateModel.createCertificateModel,
  updateCertificateModel: dispatch.certificateModel.updateCertificateModel,
  clearCertificateModel: dispatch.certificateModel.clearCertificateModel,
});

export const CertificateCard = connect(mapState, mapDispatch)(CertificateCardComponent);
