import axios from 'axios';
import { IAuthModelDto, IAuthModelPayload } from 'entities/Auth/Auth.models';

const basePath = 'auth';

export const authTransport = {
  addAuthModel: (params: IAuthModelPayload): Promise<IAuthModelDto> => axios.post(`${basePath}/login`, { ...params }),
  deleteAuthModel: (): Promise<void> => axios.delete(`${basePath}`),
  refreshToken: (refreshToken: string): Promise<IAuthModelDto> => axios.put(`${basePath}`, { refreshToken }),
};
