import React from 'react';
import { Button, InputNumber, Modal } from 'antd';
import { Selector } from 'common/components/Selector';
import { IOption } from 'common/models';

interface IComponentProps {
  equipmentsOptions?: IOption[];
  open: boolean;
  equipmentValue?: IOption;
  countValue?: number;
  isEdit: boolean;
  onEquipmentChange: (value: IOption | IOption[]) => void;
  onCountChange: (value: number | null) => void;
  onSaveClick: () => void;
  onCancelClick: () => void;
}

export const FacilityEquipmentModal: React.FC<IComponentProps> = (props) => {
  const {
    equipmentsOptions,
    open,
    equipmentValue,
    countValue,
    isEdit,
    onEquipmentChange,
    onCountChange,
    onSaveClick,
    onCancelClick,
  } = props;

  return (
    <Modal className="facility-equipment-modal" title="Add Equipment" open={open} footer={false} onCancel={onCancelClick}>
      <div className="facility-equipment-modal__body">
        <div className="facility-equipment-modal__body-item">
          <span>Equipment</span>

          <Selector options={equipmentsOptions} value={equipmentValue} onChange={onEquipmentChange} disabled={isEdit} />
        </div>

        <div className="facility-equipment-modal__body-item">
          <span>Available amount</span>

          <InputNumber min={0} value={countValue} onChange={onCountChange} />
        </div>
      </div>

      <div className="facility-equipment-modal__footer">
        <Button className="btn-primary" onClick={onSaveClick} disabled={!equipmentValue || !countValue}>
          Save
        </Button>

        <Button className="btn-gray" onClick={onCancelClick}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
