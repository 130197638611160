import React from 'react';
import { Link } from 'react-router-dom';
import { type MenuProps } from 'antd';
import { EDatabaseTabValues, ENavOptions, ERoutes } from 'common/const/enum.const';
import { getDatabasePath } from 'entities/Database/Database.helper';

export const headerMenuItems: MenuProps['items'] = [
  {
    label: <Link to={ERoutes.Dashboard}>{ENavOptions.Dashboard}</Link>,
    key: ERoutes.Dashboard,
  },
  {
    label: <Link to={ERoutes.TechnicianSchedule}>{ENavOptions.TechnicianSchedule}</Link>,
    key: ERoutes.TechnicianSchedule,
  },
  {
    label: <Link to={ERoutes.BEManagement}>{ENavOptions.BEManagement}</Link>,
    key: ERoutes.BEManagement,
  },
  {
    label: <Link to={getDatabasePath(EDatabaseTabValues.Hospitals)}>Database</Link>,
    key: '/database',
  },
];
