import axios from 'axios';
import {
  IStudiesCollectionPayload,
  IStudiesCollectionDto,
  IStudyModelDto,
  IStudyModelCreatePayload,
  IStudyModelUpdatePayload,
  IStudyModelDeletePayload,
} from 'entities/Studies/Studies.models';

const basePath = 'study';

export const studiesTransport = {
  getStudiesCollection: (params: IStudiesCollectionPayload): Promise<IStudiesCollectionDto> =>
    axios.get(`${basePath}`, { params }),
  getStudyModelById: (id: number): Promise<IStudyModelDto> => axios.get(`${basePath}/${id}`),
  createStudyModel: (payload: IStudyModelCreatePayload): Promise<IStudyModelDto> => axios.post(`${basePath}`, payload),
  updateStudyModel: (payload: IStudyModelUpdatePayload): Promise<IStudyModelDto> => axios.put(`${basePath}`, payload),
  deleteStudyModel: (payload: IStudyModelDeletePayload): Promise<IStudyModelDto> =>
    axios.delete(`${basePath}`, { data: payload }),
};
