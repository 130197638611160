import React from 'react';
import { DecrementBtn } from 'common/components/DecrementBtn';
import { IncrementBtn } from 'common/components/IncrementBtn';

interface IComponentProps {
  title: string;
  total: number;
  value: number;
  onChange: (value: number) => void;
}

export const BedScheduleCounter: React.FC<IComponentProps> = ({ title, total, value, onChange }) => {
  return (
    <div className="bed-schedule__counter">
      <span>{title}</span>

      <div className="bed-schedule__counter_container">
        <div>
          <span className={`bed-schedule__counter_container-${value}`}>{value}</span>
          <span className="bed-schedule__counter_container-total">{total}</span>
        </div>

        <div className="bed-schedule__counter_actions">
          <DecrementBtn value={value} min={0} onChange={onChange} />

          <IncrementBtn value={value} max={total} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};
