import axios from 'axios';
import {
  IEquipmentsCollectionDto,
  IEquipmentsCollectionPayload,
  IEquipmentModelCreatePayload,
  IEquipmentModelDeletePayload,
  IEquipmentModelDto,
  IEquipmentModelUpdatePayload,
} from 'entities/Equipments/Equipments.models';

const basePath = 'equipment';

export const equipmentsTransport = {
  getEquipmentsCollection: (params: IEquipmentsCollectionPayload): Promise<IEquipmentsCollectionDto> =>
    axios.get(`${basePath}`, { params }),
  getEquipmentModelById: (id: number): Promise<IEquipmentModelDto> => axios.get(`${basePath}/${id}`),
  createEquipmentModel: (payload: IEquipmentModelCreatePayload): Promise<IEquipmentModelDto> =>
    axios.post(`${basePath}`, payload),
  updateEquipmentModel: (payload: IEquipmentModelUpdatePayload): Promise<IEquipmentModelDto> => axios.put(`${basePath}`, payload),
  deleteEquipmentModel: (payload: IEquipmentModelDeletePayload): Promise<IEquipmentModelDto> =>
    axios.delete(`${basePath}`, { data: payload }),
};
