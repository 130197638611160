import React from 'react';
import { Checkbox, Space } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { IOption } from 'common/models';
import { Selector } from 'common/components/Selector';

interface IComponentProps {
  hospitalsOptions?: IOption[];
  facilitiesOptions?: IOption[];
  hospitalValue?: IOption;
  facilitiesValue?: number[];
  onHospitalChange: (value: IOption) => void;
  onFacilitiesChange: (values: number[]) => void;
}

const BedSchedulesFiltersComponent: React.FC<IComponentProps> = (props) => {
  const { hospitalsOptions, facilitiesOptions, hospitalValue, facilitiesValue, onHospitalChange, onFacilitiesChange } = props;

  const handleHospitalChange = (value: IOption | IOption[]) => {
    onHospitalChange(value as IOption);
  };

  const handleFacilitiesChange = (value: CheckboxValueType[]) => {
    onFacilitiesChange(value as number[]);
  };

  return (
    <div className="content__container_aside">
      <span className="content__container_aside-title">Filters</span>

      <div className="content__container_aside-hospital-container">
        <span>Hospital</span>

        <Selector showSearch={true} options={hospitalsOptions} value={hospitalValue} onChange={handleHospitalChange} />
      </div>

      <Checkbox.Group onChange={handleFacilitiesChange} value={facilitiesValue}>
        <Space direction="vertical">
          {facilitiesOptions?.map((item) => {
            return (
              <Checkbox key={item.value} value={item.value}>
                {item.label}
              </Checkbox>
            );
          })}
        </Space>
      </Checkbox.Group>
    </div>
  );
};

export const BedSchedulesFilters = BedSchedulesFiltersComponent;
