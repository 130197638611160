import React, { useEffect, useState } from 'react';
import { Select, Spin } from 'antd';
import { IOption } from 'common/models';
import { useDebounce } from 'common/hooks/useDebounce';
import { DEFAULT_DEBOUNCE_DELAY } from 'common/config';
import { ReactComponent as ChevronDown } from 'app/assets/images/chevron-down.svg';

interface IComponentProps {
  className?: string;
  value?: IOption;
  options?: IOption[];
  loading: boolean;
  disabled?: boolean;
  onFetch: (value: string) => void;
  onChange?: (value: IOption) => void;
}

export const Search: React.FC<IComponentProps> = ({ className, value, options, loading, disabled, onFetch, onChange }) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const debouncedSearchValue = useDebounce(searchValue, DEFAULT_DEBOUNCE_DELAY);

  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    onFetch(searchValue);
  }, [debouncedSearchValue]);

  return (
    <Select
      className={className}
      showSearch
      labelInValue
      value={value}
      onSearch={onSearch}
      onChange={onChange}
      filterOption={false}
      options={options}
      disabled={disabled}
      suffixIcon={loading ? <Spin size="small" /> : <ChevronDown />}
    />
  );
};
