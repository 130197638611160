import { createModel } from '@rematch/core';
import { showError } from 'common/helpers/error.helper';
import { IRootModel } from 'app/store';
import {
  IEquipmentsCollection,
  IEquipmentsCollectionDto,
  IEquipmentsCollectionPayload,
  IEquipmentModelCreatePayload,
  IEquipmentModelDeletePayload,
  IEquipmentModelDto,
  IEquipmentModelUpdatePayload,
  IEquipmentModel,
} from 'entities/Equipments/Equipments.models';
import { equipmentsTransport } from 'entities/Equipments/Equipments.transport';

export const equipmentsCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IEquipmentsCollection,
  reducers: {
    setEquipmentsCollection: (state, payload: IEquipmentsCollectionDto) => ({ ...state, data: payload }),
    setEquipmentsCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearEquipmentsCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getEquipmentsCollection(params: IEquipmentsCollectionPayload) {
      dispatch.equipmentsCollection.setEquipmentsCollectionLoading(true);
      await equipmentsTransport
        .getEquipmentsCollection(params)
        .then((response) => {
          dispatch.equipmentsCollection.setEquipmentsCollection(response);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.equipmentsCollection.setEquipmentsCollectionLoading(false);
        });
    },
  }),
});

export const equipmentModel = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IEquipmentModel,
  reducers: {
    setEquipmentModel: (state, payload: IEquipmentModelDto) => ({ ...state, data: payload }),
    setEquipmentLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearEquipmentModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getEquipmentModelById(id: number) {
      dispatch.equipmentModel.setEquipmentLoading(true);
      await equipmentsTransport
        .getEquipmentModelById(id)
        .then((response) => {
          dispatch.equipmentModel.setEquipmentModel(response);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.equipmentModel.setEquipmentLoading(false);
        });
    },
    async createEquipmentModel(payload: IEquipmentModelCreatePayload) {
      dispatch.equipmentModel.setEquipmentLoading(true);
      await equipmentsTransport
        .createEquipmentModel(payload)
        .then((response) => {
          dispatch.equipmentModel.setEquipmentModel(response);

          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.equipmentModel.setEquipmentLoading(false);
        });
    },
    async updateEquipmentModel(payload: IEquipmentModelUpdatePayload) {
      dispatch.equipmentModel.setEquipmentLoading(true);
      await equipmentsTransport
        .updateEquipmentModel(payload)
        .then((response) => {
          dispatch.equipmentModel.setEquipmentModel(response);

          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.equipmentModel.setEquipmentLoading(false);
        });
    },
    async deleteEquipmentModel(payload: IEquipmentModelDeletePayload) {
      dispatch.equipmentModel.setEquipmentLoading(true);
      await equipmentsTransport
        .deleteEquipmentModel(payload)
        .then(() => {
          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.equipmentModel.setEquipmentLoading(false);
        });
    },
  }),
});
