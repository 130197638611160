import React from 'react';
import { Radio, RadioChangeEvent, Space } from 'antd';
import { Selector } from 'common/components/Selector';
import { IOption } from 'common/models';

interface IComponentProps {
  hospitalsOptions?: IOption[];
  facilitiesOptions?: IOption[];
  hospitalValue?: IOption;
  facilityValue?: number;
  onHospitalChange: (value: IOption) => void;
  onFacilityChange: (value: number[]) => void;
}

export const TechnicianScheduleFilters: React.FC<IComponentProps> = (props) => {
  const { hospitalsOptions, facilitiesOptions, hospitalValue, facilityValue, onHospitalChange, onFacilityChange } = props;

  const handleHospitalChange = (value: IOption | IOption[]) => {
    onHospitalChange(value as IOption);
  };

  const handleFacilityIdChange = (e: RadioChangeEvent) => {
    onFacilityChange([e.target.value as number]);
  };

  return (
    <div className="content__container_aside">
      <span className="content__container_aside-title">Filters</span>

      <div className="content__container_aside-hospital-container">
        <span>Hospital</span>

        <Selector showSearch={true} options={hospitalsOptions} value={hospitalValue} onChange={handleHospitalChange} />
      </div>

      <Radio.Group onChange={handleFacilityIdChange} value={facilityValue}>
        <Space direction="vertical">
          {facilitiesOptions?.map((item) => {
            return (
              <Radio key={item.value} value={item.value}>
                {item.label}
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
    </div>
  );
};
