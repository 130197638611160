import { createModel } from '@rematch/core';
import { EScheduleFields, EShiftValues } from 'common/const/enum.const';
import { showError } from 'common/helpers/error.helper';
import { IRootModel } from 'app/store';
import { techniciansTransport } from 'entities/Technicians/Technicians.transport';
import {
  ITechniciansCollection,
  ITechniciansCollectionDto,
  ITechnicianModelCreatePayload,
  ITechnicianModelDeletePayload,
  ITechnicianModelDto,
  ITechnicianModelUpdatePayload,
  ITechniciansCollectionPayload,
  ITechnicianModel,
  ITechnicianStandardScheduleModel,
  ITechnicianStandardScheduleModelDto,
} from 'entities/Technicians/Technicians.models';

export const techniciansCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ITechniciansCollection,
  reducers: {
    setTechniciansCollection: (state, payload: ITechniciansCollectionDto) => ({ ...state, data: payload }),
    setTechniciansCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearTechniciansCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getTechniciansCollection(params: ITechniciansCollectionPayload) {
      dispatch.techniciansCollection.setTechniciansCollectionLoading(true);
      await techniciansTransport
        .getTechniciansCollection(params)
        .then((response) => {
          dispatch.techniciansCollection.setTechniciansCollection(response);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.techniciansCollection.setTechniciansCollectionLoading(false);
        });
    },
  }),
});

export const technicianModel = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ITechnicianModel,
  reducers: {
    setTechnicianModel: (state, payload: ITechnicianModelDto) => ({ ...state, data: payload }),
    setTechnicianModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearTechnicianModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getTechnicianModelById(id: number) {
      dispatch.technicianModel.setTechnicianModelLoading(true);
      await techniciansTransport
        .getTechnicianModelById(id)
        .then((response) => {
          dispatch.technicianStandardScheduleModel.setTechnicianStandardScheduleModel(response.standardSchedule);
          dispatch.technicianModel.setTechnicianModel(response);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.technicianModel.setTechnicianModelLoading(false);
        });
    },
    async createTechnicianModel(payload: ITechnicianModelCreatePayload) {
      dispatch.technicianModel.setTechnicianModelLoading(true);
      await techniciansTransport
        .createTechnicianModel(payload)
        .then((response) => {
          dispatch.technicianModel.setTechnicianModel(response);

          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.technicianModel.setTechnicianModelLoading(false);
        });
    },
    async updateTechnicianModel(payload: ITechnicianModelUpdatePayload) {
      dispatch.technicianModel.setTechnicianModelLoading(true);
      await techniciansTransport
        .updateTechnicianModel(payload)
        .then((response) => {
          dispatch.technicianModel.setTechnicianModel(response);

          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.technicianModel.setTechnicianModelLoading(false);
        });
    },
    async deleteTechnicianModel(payload: ITechnicianModelDeletePayload) {
      dispatch.technicianModel.setTechnicianModelLoading(true);
      await techniciansTransport
        .deleteTechnicianModel(payload)
        .then(() => {
          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.technicianModel.setTechnicianModelLoading(false);
        });
    },
    clearTechnician() {
      dispatch.technicianModel.clearTechnicianModel();
      dispatch.technicianStandardScheduleModel.clearTechnicianStandardScheduleModel();
      dispatch.facilitiesCollection.clearFacilitiesCollection();
      dispatch.certificateCollection.clearCertificateCollection();
    },
  }),
});

export const technicianStandardScheduleModel = createModel<IRootModel>()({
  state: {
    data: {},
  } as ITechnicianStandardScheduleModel,
  reducers: {
    setTechnicianStandardScheduleModel: (state, payload: ITechnicianStandardScheduleModelDto) => ({ ...state, data: payload }),
    clearTechnicianStandardScheduleModel: (state) => ({ ...state, data: {} }),
  },
  effects: (dispatch) => ({
    changeTechnicianStandardScheduleModel(payload: any, models) {
      const schedule: any = models.technicianStandardScheduleModel.data;

      const newSchedule = {
        ...schedule,
        [payload.colId]:
          payload.value === EShiftValues.DayOff
            ? {
                [payload.fieldId]: payload.value,
              }
            : {
                ...schedule[payload.colId as string],
                [payload.fieldId]: payload.fieldId === EScheduleFields.Capaсity ? Number(payload.value) : payload.value,
              },
      };

      dispatch.technicianStandardScheduleModel.setTechnicianStandardScheduleModel(newSchedule);
    },
  }),
});
