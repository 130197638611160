import React from 'react';
import { FormInstance } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import dayjs, { Dayjs } from 'dayjs';
import { EDateFormat, EDaysOfSchedule, ETechnicianFieldNames } from 'common/const/enum.const';
import { scheduleShiftOptions } from 'common/const/schedule.const';
import { IOption } from 'common/models';
import { TechnicianScheduleItem } from 'entities/TechnicianSchedule/components/TechnicianScheduleItem';
import { ITechnicianModelDto, ITechnicianStandardScheduleModelDto } from 'entities/Technicians/Technicians.models';

export const toTechnicianStandardScheduleDataSourseMapper = (data?: ITechnicianStandardScheduleModelDto) => [
  { key: '0', ...data },
];

export const formValuesMapper = (values: { [key: string]: string | Object }) => {
  const rowId = Object.keys(values)[0];

  if (rowId) {
    const colEntity = Object.values(values)[0];
    const colId = Object.keys(colEntity)[0];
    const fieldEntity = Object.values(colEntity)[0];

    if (fieldEntity) {
      const fieldId = Object.keys(fieldEntity)[0];
      const value = Object.values(fieldEntity)[0];

      return { colId, fieldId, value, rowId };
    }

    return { colId, rowId };
  }

  return { rowId };
};

export const renderTechnicianStandardScheduleRecord = (
  rowId: string,
  colId: string,
  shift: string,
  facilityOptions?: IOption[],
) => {
  return (
    <TechnicianScheduleItem
      rowId={rowId}
      colId={colId}
      shiftOptions={scheduleShiftOptions}
      facilityOptions={facilityOptions}
      shiftValue={shift}
      withFacility
    />
  );
};

export const getTechnicianStandardScheduleRecords = (facilityOptions?: IOption[]) => {
  const rowId = ETechnicianFieldNames.StandardSchedule;

  return [
    {
      title: 'Mon',
      key: 0,
      dataIndex: rowId,
      onCell: (record) => ({
        className: record?.[EDaysOfSchedule.Mon]?.shift,
      }),
      render: (_, record) =>
        renderTechnicianStandardScheduleRecord(
          rowId,
          EDaysOfSchedule.Mon,
          record?.[EDaysOfSchedule.Mon]?.shift as string,
          facilityOptions,
        ),
    },
    {
      title: 'Tue',
      key: 1,
      dataIndex: rowId,
      onCell: (record) => ({
        className: record?.[EDaysOfSchedule.Tue]?.shift,
      }),
      render: (_, record) =>
        renderTechnicianStandardScheduleRecord(
          rowId,
          EDaysOfSchedule.Tue,
          record?.[EDaysOfSchedule.Tue]?.shift as string,
          facilityOptions,
        ),
    },
    {
      title: 'Wed',
      key: 2,
      dataIndex: rowId,
      onCell: (record) => ({
        className: record?.[EDaysOfSchedule.Wed]?.shift,
      }),
      render: (_, record) =>
        renderTechnicianStandardScheduleRecord(
          rowId,
          EDaysOfSchedule.Wed,
          record?.[EDaysOfSchedule.Wed]?.shift as string,
          facilityOptions,
        ),
    },
    {
      title: 'Thu',
      key: 3,
      dataIndex: rowId,
      onCell: (record) => ({
        className: record?.[EDaysOfSchedule.Thu]?.shift,
      }),
      render: (_, record) =>
        renderTechnicianStandardScheduleRecord(
          rowId,
          EDaysOfSchedule.Thu,
          record?.[EDaysOfSchedule.Thu]?.shift as string,
          facilityOptions,
        ),
    },
    {
      title: 'Fri',
      key: 4,
      dataIndex: rowId,
      onCell: (record) => ({
        className: record?.[EDaysOfSchedule.Fri]?.shift,
      }),
      render: (_, record) =>
        renderTechnicianStandardScheduleRecord(
          rowId,
          EDaysOfSchedule.Fri,
          record?.[EDaysOfSchedule.Fri]?.shift as string,
          facilityOptions,
        ),
    },
    {
      title: 'Sat',
      key: 5,
      dataIndex: rowId,
      onCell: (record) => ({
        className: record?.[EDaysOfSchedule.Sat]?.shift,
      }),
      render: (_, record) =>
        renderTechnicianStandardScheduleRecord(
          rowId,
          EDaysOfSchedule.Sat,
          record?.[EDaysOfSchedule.Sat]?.shift as string,
          facilityOptions,
        ),
    },
    {
      title: 'Sun',
      key: 6,
      dataIndex: rowId,
      onCell: (record) => ({
        className: record?.[EDaysOfSchedule.Sun]?.shift,
      }),
      render: (_, record) =>
        renderTechnicianStandardScheduleRecord(
          rowId,
          EDaysOfSchedule.Sun,
          record?.[EDaysOfSchedule.Sun]?.shift as string,
          facilityOptions,
        ),
    },
  ] as ColumnsType<ITechnicianStandardScheduleModelDto>;
};

export const toTechnicianPayloadMapper = (
  values: any,
  technicianStandardScheduleModelData: ITechnicianStandardScheduleModelDto,
) => {
  return {
    name: values.name,
    capacity: values.capacity,
    credentials: Object.entries(values.validUntil)
      .filter((item) => !!item[1])
      .map(([key, value]) => ({
        credentialId: Number(key),
        validUntil: (value as Dayjs).format(EDateFormat.FullDate),
      })),
    standardSchedule: technicianStandardScheduleModelData,
  };
};

export const toTechnicianFormValuesMapper = (technicianModelData: ITechnicianModelDto) => {
  const certificates = technicianModelData.credentials
    .filter((item) => dayjs(item.validUntil).diff(dayjs()) > 0)
    .map((item) => [item.credentialId, true]);
  const validUntil = technicianModelData.credentials.map((item) => [
    item.credentialId,
    item.validUntil ? dayjs(item.validUntil) : undefined,
  ]);

  return {
    name: technicianModelData.name,
    capacity: technicianModelData.capacity,
    certificates: Object.fromEntries(certificates),
    validUntil: Object.fromEntries(validUntil),
    standardSchedule: technicianModelData.standardSchedule,
  };
};

export const resetTechnicianValidUntilField = (form: FormInstance, values: { [key: string]: string | number[] | Object }) => {
  const certificateId = Object.keys(values[ETechnicianFieldNames.Certificates])[0];
  const validUntil = form.getFieldValue([ETechnicianFieldNames.ValidUntil, certificateId]);

  if (validUntil) {
    form.setFieldValue([ETechnicianFieldNames.ValidUntil, certificateId], undefined);
  }
};
