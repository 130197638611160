import { EStorageNames } from 'common/const/enum.const';
import { IOption } from 'common/models';

export const toDataSourseMapper = (data?: any[]) => data?.map((item) => ({ ...item, key: item.id }));

export const toOptionsMapper = (data?: any[], additionalOption?: IOption) => {
  const options = data?.map((item) => ({ label: item.name, value: item.id })) || [];

  if (data && additionalOption) {
    options.unshift(additionalOption);
  }

  return options;
};

export const getStorageItem = (name: string) => {
  return sessionStorage.getItem(name);
};

export const setStorageItem = (name: string, value: any) => {
  sessionStorage.setItem(name, JSON.stringify(value));
};

export const removeStorageItem = (name: string) => {
  sessionStorage.removeItem(name);
};

export const clearStorage = () => {
  sessionStorage.removeItem(EStorageNames.Shift);
  sessionStorage.removeItem(EStorageNames.FacilityIds);
  sessionStorage.removeItem(EStorageNames.Hospital);
};
