import axios from 'axios';
import {
  IBedScheduleCollectionDto,
  IBedScheduleCollectionParams,
  IBedScheduleCollectionUpdatePayload,
  IBedScheduleCollectionUpdateResponse,
} from 'entities/BedSchedules/BedSchedules.models';

const basePath = 'bed-schedule';

export const bedScheduleTransport = {
  getBedScheduleCollection: (params: IBedScheduleCollectionParams): Promise<IBedScheduleCollectionDto> =>
    axios.get(`${basePath}`, { params }),
  updateBedScheduleCollection: (payload: IBedScheduleCollectionUpdatePayload): Promise<IBedScheduleCollectionUpdateResponse> =>
    axios.post(`${basePath}/upsert`, payload),
};
