import axios from 'axios';
import {
  ITechniciansCollectionDto,
  ITechniciansCollectionPayload,
  ITechnicianModelCreatePayload,
  ITechnicianModelDeletePayload,
  ITechnicianModelDto,
  ITechnicianModelUpdatePayload,
} from 'entities/Technicians/Technicians.models';

const basePath = 'technician';

export const techniciansTransport = {
  getTechniciansCollection: (params: ITechniciansCollectionPayload): Promise<ITechniciansCollectionDto> =>
    axios.get(`${basePath}`, { params }),
  getTechnicianModelById: (id: number): Promise<ITechnicianModelDto> => axios.get(`${basePath}/${id}`),
  createTechnicianModel: (payload: ITechnicianModelCreatePayload): Promise<ITechnicianModelDto> =>
    axios.post(`${basePath}`, payload),
  updateTechnicianModel: (payload: ITechnicianModelUpdatePayload): Promise<ITechnicianModelDto> =>
    axios.put(`${basePath}`, payload),
  deleteTechnicianModel: (payload: ITechnicianModelDeletePayload): Promise<ITechnicianModelDto> =>
    axios.delete(`${basePath}`, { data: payload }),
};
