import { createModel } from '@rematch/core';
import { showError } from 'common/helpers/error.helper';
import { IRootModel } from 'app/store';
import {
  IDashboardScheduleCollection,
  IDashboardScheduleCollectionDto,
  IDashboardScheduleCollectionParams,
} from 'entities/DashboardSchedule/DashboardSchedule.models';
import { dashboardScheduleTransport } from 'entities/DashboardSchedule/DashboardSchedule.transport';

export const dashboardScheduleCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IDashboardScheduleCollection,
  reducers: {
    setDashboardScheduleCollection: (state, payload: IDashboardScheduleCollectionDto) => ({ ...state, data: payload }),
    setDashboardScheduleCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearDashboardScheduleCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getDashboardScheduleCollection(params: IDashboardScheduleCollectionParams) {
      dispatch.dashboardScheduleCollection.setDashboardScheduleCollectionLoading(true);
      await dashboardScheduleTransport
        .getDashboardScheduleCollection(params)
        .then((response) => {
          dispatch.dashboardScheduleCollection.setDashboardScheduleCollection(response);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.dashboardScheduleCollection.setDashboardScheduleCollectionLoading(false);
        });
    },
    clearDashboardSchedule() {
      dispatch.availableEntitiesCollection.clearAvailableEntitiesCollection();
      dispatch.dashboardScheduleCollection.clearDashboardScheduleCollection();
    },
  }),
});
