import React from 'react';
import { Button } from 'antd';
import { ReactComponent as Minus } from 'app/assets/images/minus.svg';

interface IComponentProps {
  value: number;
  min: number;
  onChange: (value: number) => void;
}

export const DecrementBtn: React.FC<IComponentProps> = ({ value, min, onChange }) => {
  const handleClick = () => {
    if (value > min) {
      onChange(value - 1);
    }
  };

  return <Button className="btn-icon" icon={<Minus />} onClick={handleClick} />;
};
