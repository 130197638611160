import { createModel } from '@rematch/core';
import { showError } from 'common/helpers/error.helper';
import { IRootModel } from 'app/store';
import {
  ICertificateCollection,
  ICertificateCollectionDto,
  ICertificateCollectionPayload,
  ICertificateDto,
  ICertificateModel,
  ICertificateModelCreatePayload,
  ICertificateModelDeletePayload,
  ICertificateModelUpdatePayload,
} from 'entities/Certificates/Certificates.models';
import { certificatesTransport } from 'entities/Certificates/Certificates.transport';

export const certificateCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ICertificateCollection,
  reducers: {
    setCertificateCollection: (state, payload: ICertificateCollectionDto) => ({ ...state, data: payload }),
    setCertificateCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearCertificateCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getCertificateCollection(params: ICertificateCollectionPayload) {
      dispatch.certificateCollection.setCertificateCollectionLoading(true);
      await certificatesTransport
        .getCertificateCollection(params)
        .then((response) => {
          dispatch.certificateCollection.setCertificateCollection(response);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.certificateCollection.setCertificateCollectionLoading(false);
        });
    },
  }),
});

export const certificateModel = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as ICertificateModel,
  reducers: {
    setCertificateModel: (state, payload: ICertificateDto) => ({ ...state, data: payload }),
    setCertificateModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearCertificateModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getCertificateModelById(id: number) {
      dispatch.certificateModel.setCertificateModelLoading(true);
      await certificatesTransport
        .getCertificateModelById(id)
        .then((response) => {
          dispatch.certificateModel.setCertificateModel(response);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.certificateModel.setCertificateModelLoading(false);
        });
    },
    async createCertificateModel(payload: ICertificateModelCreatePayload) {
      dispatch.certificateModel.setCertificateModelLoading(true);
      await certificatesTransport
        .createCertificateModel(payload)
        .then((response) => {
          dispatch.certificateModel.setCertificateModel(response);

          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.certificateModel.setCertificateModelLoading(false);
        });
    },
    async updateCertificateModel(payload: ICertificateModelUpdatePayload) {
      dispatch.certificateModel.setCertificateModelLoading(true);
      await certificatesTransport
        .updateCertificateModel(payload)
        .then((response) => {
          dispatch.certificateModel.setCertificateModel(response);

          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.certificateModel.setCertificateModelLoading(false);
        });
    },
    async deleteCertificateModel(payload: ICertificateModelDeletePayload) {
      dispatch.certificateModel.setCertificateModelLoading(true);
      await certificatesTransport
        .deleteCertificateModel(payload)
        .then(() => {
          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.certificateModel.setCertificateModelLoading(false);
        });
    },
  }),
});
