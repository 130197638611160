import React from 'react';
import { Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { EOrderDirections, ECommonOrderFields } from 'common/const/enum.const';
import { IDatabaseColumnsType } from 'entities/Database/Database.models';

interface IComponentProps {
  columns: ColumnsType<IDatabaseColumnsType>;
  dataSource?: IDatabaseColumnsType[];
  paginationConfig?: TablePaginationConfig;
  sort?: (orderField: ECommonOrderFields, orderDirection?: EOrderDirections) => void;
}

export const DatabaseTable: React.FC<IComponentProps> = ({ columns, dataSource, paginationConfig, sort }) => {
  const onChange = (pagination: any, filters: any, sorter: any) => {
    sort?.(
      sorter.field,
      sorter.order ? (sorter.order !== 'descend' ? EOrderDirections.DESC : EOrderDirections.ASC) : EOrderDirections.ASC,
    );
  };

  return (
    <Table
      className="database-table"
      rowSelection={{
        type: 'checkbox',
        onChange: (selectedRowKeys: React.Key[], selectedRows: IDatabaseColumnsType[]) => {
          // TODO
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
      }}
      columns={columns}
      dataSource={dataSource}
      pagination={
        paginationConfig
          ? {
              ...paginationConfig,
              style: { marginTop: '20px' },
            }
          : false
      }
      onChange={onChange}
      showSorterTooltip={false}
    />
  );
};
