import React, { useEffect, useState } from 'react';
import { Button, Spin, TablePaginationConfig } from 'antd';
import { connect } from 'react-redux';
import { EDatabaseActions, EDatabaseTabValues, EOrderDirections, ECommonOrderFields } from 'common/const/enum.const';
import { DatabaseTable } from 'common/components/DatabaseTable';
import { toDataSourseMapper, toOptionsMapper } from 'common/helpers/data.helper';
import { Selector } from 'common/components/Selector';
import { IOption } from 'common/models';
import { ConfirmationModal } from 'common/components/СonfirmationModal';
import { DEFAULT_PAGE_ID, DEFAULT_PAGE_SIZE } from 'common/config';
import { RootDispatch, RootState, history } from 'app/store';
import { getDatabaseColumns, getDatabasePath } from 'entities/Database/Database.helper';
import { databaseColumns } from 'entities/Database/Database.const';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const TechniciansComponent: React.FC<AllType> = (props) => {
  const {
    hospitalsCollection,
    techniciansCollection,
    technicianModel,
    getHospitalsCollection,
    clearHospitalsCollection,
    getTechniciansCollection,
    clearTechniciansCollection,
    deleteTechnicianModel,
  } = props;

  const [hospital, setHospital] = useState<IOption>();
  const [orderField, setOrderField] = useState<ECommonOrderFields>(ECommonOrderFields.Name);
  const [orderDirection, setOrderDirection] = useState<EOrderDirections | undefined>(EOrderDirections.ASC);
  const [technicianId, setTechnicianId] = useState<number | null>(null);
  const [pageId, setPageId] = useState<number>(DEFAULT_PAGE_ID);
  const [open, setOpen] = useState<boolean>(false);
  const { data: hospitalsCollectionData, loading: hospitalsCollectionLoading } = hospitalsCollection;
  const { data: techniciansCollectionData, loading: techniciansCollectionLoading } = techniciansCollection;
  const { loading: technicianModelLoading } = technicianModel;
  const hospitalId = hospital && Number(hospital?.value);

  const hospitalsOptions = toOptionsMapper(hospitalsCollectionData?.data);
  const dataSource = toDataSourseMapper(techniciansCollectionData?.data);

  const handleHospitalChange = (value: IOption | IOption[]) => {
    setHospital(value as IOption);
  };

  const addTechnician = () => {
    const path = getDatabasePath(EDatabaseTabValues.Technicians, EDatabaseActions.Add, hospitalId);

    history.push(path);
  };

  const onEdit = (id: number) => {
    const path = getDatabasePath(EDatabaseTabValues.Technicians, EDatabaseActions.Edit, id);

    history.push(path);
  };

  const onDelete = (id: number) => {
    setTechnicianId(id);
    setOpen(true);
  };

  const onYesClick = () => {
    technicianId &&
      deleteTechnicianModel({
        id: technicianId,
        onSuccess: () => {
          getTechniciansCollection({ orderField, orderDirection });
          setTechnicianId(null);
          setOpen(false);
        },
      });
  };

  const onNoClick = () => {
    setTechnicianId(null);
    setOpen(false);
  };

  const onView = (id: number) => {
    const path = getDatabasePath(EDatabaseTabValues.Technicians, EDatabaseActions.View, id);

    history.push(path);
  };

  const handleSort = (orderField: ECommonOrderFields, orderDirection?: EOrderDirections) => {
    setOrderField(orderField);
    setOrderDirection(orderDirection);
  };

  const columns = getDatabaseColumns(databaseColumns, onEdit, onDelete, onView);

  useEffect(() => {
    getHospitalsCollection({ limit: 0, orderField: ECommonOrderFields.Name, orderDirection: EOrderDirections.ASC });

    return () => {
      clearHospitalsCollection();
    };
  }, []);

  useEffect(() => {
    getTechniciansCollection({
      clinicId: hospitalId,
      orderField,
      orderDirection,
      limit: DEFAULT_PAGE_SIZE,
      offset: (pageId - 1) * DEFAULT_PAGE_SIZE,
    });

    return () => {
      clearTechniciansCollection();
    };
  }, [hospitalId, orderField, orderDirection, pageId]);

  let paginationConfig: TablePaginationConfig | undefined = undefined;

  if (techniciansCollectionData?.count) {
    paginationConfig = {
      current: Number(pageId),
      total: techniciansCollectionData?.count,
      onChange: (pageId: number) => setPageId(pageId),
      defaultPageSize: DEFAULT_PAGE_SIZE,
    };
  }

  return (
    <Spin spinning={hospitalsCollectionLoading || techniciansCollectionLoading}>
      <div className="database__container_main-header">
        <Button className="btn-primary" onClick={addTechnician} disabled={!hospitalId}>
          Add Technician
        </Button>

        <div className="database__container_main-header-hospitals">
          <span>Hospital</span>

          <Selector showSearch={true} options={hospitalsOptions} value={hospital} onChange={handleHospitalChange} />
        </div>
      </div>

      <DatabaseTable dataSource={dataSource} columns={columns} sort={handleSort} paginationConfig={paginationConfig} />

      <ConfirmationModal open={open} loading={technicianModelLoading} onYesClick={onYesClick} onNoClick={onNoClick} />
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  hospitalsCollection: state.hospitalsCollection,
  techniciansCollection: state.techniciansCollection,
  technicianModel: state.technicianModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getHospitalsCollection: dispatch.hospitalsCollection.getHospitalsCollection,
  clearHospitalsCollection: dispatch.hospitalsCollection.clearHospitalsCollection,
  getTechniciansCollection: dispatch.techniciansCollection.getTechniciansCollection,
  clearTechniciansCollection: dispatch.techniciansCollection.clearTechniciansCollection,
  deleteTechnicianModel: dispatch.technicianModel.deleteTechnicianModel,
});

export const Technicians = connect(mapState, mapDispatch)(TechniciansComponent);
