import React, { useEffect } from 'react';
import { Form, Input, InputNumber, Spin } from 'antd';
import { connect } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { EDatabaseActions, EDatabaseTabValues, EFormFieldMessages, ETechnicianFieldNames } from 'common/const/enum.const';
import { RootDispatch, RootState, history } from 'app/store';
import { CommonDatabaseContainer } from 'entities/Database/components/CommonDatabaseContainer';
import { getDatabasePath } from 'entities/Database/Database.helper';
import { TechnicianStandardSchedule } from 'entities/Technicians/components/TechnicianStandardSchedule';
import {
  formValuesMapper,
  resetTechnicianValidUntilField,
  toTechnicianFormValuesMapper,
  toTechnicianPayloadMapper,
} from 'entities/Technicians/Technicians.helper';
import { TechnicianCredentials } from 'entities/Technicians/components/TechnicianCredentials';

interface IComponentProps {
  action?: string;
  id?: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const TechnicianCardComponent: React.FC<AllType> = (props) => {
  const {
    action,
    id,
    technicianModel,
    technicianStandardScheduleModel,
    getTechnicianModelById,
    createTechnicianModel,
    updateTechnicianModel,
    clearTechnician,
    changeTechnicianStandardScheduleModel,
  } = props;
  const { data: technicianModelData, loading: technicianModelLoading } = technicianModel;
  const { data: technicianStandardScheduleModelData } = technicianStandardScheduleModel;

  const [form] = useForm();

  const externalId = id && Number(id);
  const formDisabled = action === EDatabaseActions.View;

  const onValuesChange = (values: { [key: string]: string | number[] | Object }) => {
    const { colId, fieldId, value, rowId } = formValuesMapper(values);

    if (rowId === ETechnicianFieldNames.Certificates) {
      resetTechnicianValidUntilField(form, values);
    }

    if (rowId === ETechnicianFieldNames.StandardSchedule && colId && fieldId) {
      changeTechnicianStandardScheduleModel({ colId, fieldId, value });
    }
  };

  const onSubmit = (values: any) => {
    const path = getDatabasePath(EDatabaseTabValues.Technicians);
    const payload = toTechnicianPayloadMapper(values, technicianStandardScheduleModelData);

    if (action === EDatabaseActions.Add) {
      createTechnicianModel({
        clinicId: Number(externalId),
        ...payload,
        onSuccess: () => history.push(path),
      });
    } else {
      updateTechnicianModel({
        id: Number(externalId),
        ...payload,
        onSuccess: () => history.push(path),
      });
    }
  };

  const onCancel = () => {
    history.back();
  };

  useEffect(() => {
    if (action !== EDatabaseActions.Add && externalId) {
      getTechnicianModelById(externalId);
    }

    return () => {
      clearTechnician();
    };
  }, [action, externalId]);

  useEffect(() => {
    if (technicianModelData) {
      const formValues = toTechnicianFormValuesMapper(technicianModelData);

      form.setFieldsValue(formValues);
    }
  }, [technicianModelData]);

  return (
    <Form form={form} className="container__form" onFinish={onSubmit} disabled={formDisabled} onValuesChange={onValuesChange}>
      <CommonDatabaseContainer
        action={action}
        saveDisabled={technicianModelLoading}
        cancelDisabled={technicianModelLoading}
        withFooter
        onCancel={onCancel}
      >
        <Spin spinning={technicianModelLoading}>
          <div className="database__container_item">
            <Form.Item
              label="Name"
              name={ETechnicianFieldNames.Name}
              rules={[{ required: true, message: EFormFieldMessages.required }]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className="database__container_item">
            <Form.Item
              label="Default Capacity"
              name={ETechnicianFieldNames.Capacity}
              rules={[{ required: true, message: EFormFieldMessages.required }]}
            >
              <InputNumber />
            </Form.Item>
          </div>

          <TechnicianCredentials />

          <TechnicianStandardSchedule
            schedule={technicianStandardScheduleModelData}
            clinicId={action === EDatabaseActions.Add ? externalId : technicianModelData?.clinicId}
          />
        </Spin>
      </CommonDatabaseContainer>
    </Form>
  );
};

const mapState = (state: RootState) => ({
  technicianModel: state.technicianModel,
  technicianStandardScheduleModel: state.technicianStandardScheduleModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getTechnicianModelById: dispatch.technicianModel.getTechnicianModelById,
  createTechnicianModel: dispatch.technicianModel.createTechnicianModel,
  updateTechnicianModel: dispatch.technicianModel.updateTechnicianModel,
  clearTechnician: dispatch.technicianModel.clearTechnician,
  changeTechnicianStandardScheduleModel: dispatch.technicianStandardScheduleModel.changeTechnicianStandardScheduleModel,
});

export const TechnicianCard = connect(mapState, mapDispatch)(TechnicianCardComponent);
