import React from 'react';
import { Button, Modal } from 'antd';
import { DecrementBtn } from 'common/components/DecrementBtn';
import { IncrementBtn } from 'common/components/IncrementBtn';
import { IBedScheduleEquipmentEntity } from 'entities/BedSchedules/BedSchedules.models';

interface IComponentProps {
  open: boolean;
  equipments: IBedScheduleEquipmentEntity[];
  onChange: (equipmentId: number, value: number) => void;
  onSave: () => void;
  onCancel: () => void;
}

export const BedScheduleEquipmentModal: React.FC<IComponentProps> = ({ open, equipments, onChange, onSave, onCancel }) => {
  return (
    <Modal className="bed-schedule__item_equipment_modal" open={open} title="Equipment" footer={false} onCancel={onCancel}>
      {equipments?.map((item) => {
        return (
          <div className="bed-schedule__item_equipment_modal-item" key={item.equipmentId}>
            <span>{item.equipmentName}:</span>

            <DecrementBtn
              value={item.count}
              min={0}
              onChange={(value) => {
                onChange(item.equipmentId, value);
              }}
            />

            <span>
              {item.count}/{item.total}
            </span>

            <IncrementBtn
              value={item.count}
              max={item.total}
              onChange={(value) => {
                onChange(item.equipmentId, value);
              }}
            />
          </div>
        );
      })}

      <div className="bed-schedule__item_equipment_modal-footer">
        <Button className="btn-primary" onClick={onSave}>
          Save
        </Button>

        <Button className="btn-gray" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
