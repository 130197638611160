import { Button, Modal, Spin } from 'antd';
import React from 'react';

interface IComponentProps {
  open: boolean;
  loading: boolean;
  onYesClick: () => void;
  onNoClick: () => void;
}

export const ConfirmationModal: React.FC<IComponentProps> = ({ open, loading, onYesClick, onNoClick }) => {
  return (
    <Modal className="confirmation-modal" title="Are you sure?" open={open} footer={false} closeIcon={false}>
      <Spin spinning={loading}>
        <Button className="btn-primary" onClick={onYesClick}>
          Yes
        </Button>
        <Button className="btn-gray" onClick={onNoClick}>
          No
        </Button>
      </Spin>
    </Modal>
  );
};
