import axios from 'axios';
import {
  IDashboardScheduleCollectionDto,
  IDashboardScheduleCollectionParams,
} from 'entities/DashboardSchedule/DashboardSchedule.models';

const basePath = 'schedule';

export const dashboardScheduleTransport = {
  getDashboardScheduleCollection: (params: IDashboardScheduleCollectionParams): Promise<IDashboardScheduleCollectionDto> =>
    axios.get(`${basePath}`, { params }),
};
