import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ECommonOrderFields, EOrderDirections, ERoutes, EStorageNames } from 'common/const/enum.const';
import { getStorageItem, removeStorageItem, setStorageItem, toOptionsMapper } from 'common/helpers/data.helper';
import { IFilterHookParams, IOption } from 'common/models';
import { allOption } from 'common/const/data.const';

export const useFilters = (params: IFilterHookParams) => {
  const {
    hospitalsCollection,
    facilitiesCollection,
    techniciansCollection,
    getHospitalsCollection,
    getFacilitiesCollection,
    getTechniciansCollection,
  } = params;

  const { data: hospitalsCollectionData, loading: hospitalsCollectionLoading } = hospitalsCollection;
  const { data: facilitiesCollectionData, loading: facilitiesCollectionLoading } = facilitiesCollection;
  const { data: techniciansCollectionData, loading: techniciansCollectionLoading } = techniciansCollection;
  const loading = hospitalsCollectionLoading || facilitiesCollectionLoading || techniciansCollectionLoading;

  const location = useLocation();

  const [hospitalsOptions, setHospitalOptions] = useState<IOption[]>();
  const [facilitiesOptions, setFacilitiesOptions] = useState<IOption[]>();
  const techniciansOptions = toOptionsMapper(techniciansCollectionData?.data, allOption);

  const [hospital, setHospital] = useState<IOption>();
  const [facilityIds, setFacilityIds] = useState<number[]>();
  const [shift, setShift] = useState<boolean>();
  const [technician, setTechnician] = useState<IOption>(allOption);

  const onHospitalChange = (value: IOption) => {
    removeStorageItem(EStorageNames.FacilityIds);
    setFacilitiesOptions(undefined);
    setFacilityIds([]);
    setTechnician(allOption);
    setHospital(value);
  };

  const onFacilityIdsChange = (value: number[]) => {
    setFacilityIds(value);
  };

  const onShiftChange = (value: boolean) => {
    setShift(value);
  };

  const onTechnicianChange = (value: IOption) => {
    setTechnician(value);
  };

  useEffect(() => {
    getHospitalsCollection({ limit: 0, orderField: ECommonOrderFields.Name, orderDirection: EOrderDirections.ASC });
  }, []);

  useEffect(() => {
    if (hospitalsCollectionData && !hospital) {
      const storageHospital = getStorageItem(EStorageNames.Hospital);
      const hospitalsOptions = toOptionsMapper(hospitalsCollectionData.data);

      setHospitalOptions(hospitalsOptions);

      if (storageHospital) {
        setHospital(JSON.parse(storageHospital));
      } else {
        setHospital({ label: hospitalsCollectionData.data[0].name, value: hospitalsCollectionData.data[0].id });
      }
    }
  }, [hospitalsCollectionData, hospital]);

  useEffect(() => {
    if (hospital) {
      setStorageItem(EStorageNames.Hospital, hospital);

      getFacilitiesCollection({
        clinicId: Number(hospital.value),
        limit: 0,
        orderField: ECommonOrderFields.Name,
        orderDirection: EOrderDirections.ASC,
      });
    }
  }, [hospital]);

  useEffect(() => {
    if (facilitiesCollectionData && facilitiesCollectionData.data.length && !facilitiesCollectionLoading) {
      const storageFacilityIds = getStorageItem(EStorageNames.FacilityIds);

      if (storageFacilityIds && JSON.parse(storageFacilityIds).length) {
        setFacilityIds(JSON.parse(storageFacilityIds));
      } else {
        setFacilityIds([facilitiesCollectionData.data[0].id]);
      }
    }
  }, [facilitiesCollectionData, facilitiesCollectionLoading]);

  useEffect(() => {
    if (facilitiesCollectionData) {
      const facilitiesOptions = toOptionsMapper(facilitiesCollectionData?.data);

      setFacilitiesOptions(facilitiesOptions);
    }
  }, [facilitiesCollectionData]);

  useEffect(() => {
    if (facilityIds) {
      setStorageItem(EStorageNames.FacilityIds, facilityIds);
    }
  }, [facilityIds]);

  useEffect(() => {
    if (shift === undefined) {
      const storageShift = getStorageItem(EStorageNames.Shift);

      if (storageShift) {
        setShift(JSON.parse(storageShift));
      } else {
        setShift(true);
      }
    }
  }, [shift]);

  useEffect(() => {
    setStorageItem(EStorageNames.Shift, shift);
  }, [shift]);

  useEffect(() => {
    if (facilitiesCollectionData && facilitiesCollectionData.data.length) {
      const storageFacilityIds = getStorageItem(EStorageNames.FacilityIds);

      if (storageFacilityIds && !JSON.parse(storageFacilityIds).length) {
        setFacilityIds([facilitiesCollectionData.data[0].id]);
      }
    }
  }, [location, facilitiesCollectionData]);

  useEffect(() => {
    if (hospital && location.pathname === ERoutes.TechnicianSchedule) {
      getTechniciansCollection({
        clinicId: Number(hospital.value),
        limit: 0,
        orderField: ECommonOrderFields.Name,
        orderDirection: EOrderDirections.ASC,
      });
    }
  }, [hospital, location]);

  return {
    hospitalsOptions,
    facilitiesOptions,
    techniciansOptions,
    hospital,
    facilityIds,
    shift,
    technician,
    loading,
    onHospitalChange,
    onFacilityIdsChange,
    onShiftChange,
    onTechnicianChange,
  };
};
