import React, { useEffect } from 'react';
import { Form, Input, Spin } from 'antd';
import { connect } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { EDatabaseActions, EDatabaseTabValues, EFormFieldMessages } from 'common/const/enum.const';
import { RootDispatch, RootState, history } from 'app/store';
import { CommonDatabaseContainer } from 'entities/Database/components/CommonDatabaseContainer';
import { IHospitalModelCreatePayload } from 'entities/Hospitals/Hospitals.models';
import { getDatabasePath } from 'entities/Database/Database.helper';

interface IComponentProps {
  action?: string;
  id?: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const HospitalCardComponent: React.FC<AllType> = (props) => {
  const { action, id, hospitalModel, getHospitalModelById, createHospitalModel, updateHospitalModel, clearHospitalModel } = props;
  const { data, loading } = hospitalModel;

  const externalId = id && Number(id);
  const [form] = useForm();

  const onSubmit = (values: IHospitalModelCreatePayload) => {
    const { name } = values;
    const path = getDatabasePath(EDatabaseTabValues.Hospitals);

    if (action === EDatabaseActions.Add) {
      createHospitalModel({
        name,
        onSuccess: () => history.push(path),
      });
    } else {
      updateHospitalModel({
        id: externalId as number,
        name,
        onSuccess: () => history.push(path),
      });
    }
  };

  const onCancel = () => {
    history.back();
  };

  useEffect(() => {
    if (action !== EDatabaseActions.Add && externalId) {
      getHospitalModelById(externalId);
    }

    return () => {
      clearHospitalModel();
    };
  }, [action, externalId]);

  useEffect(() => {
    if (data) {
      form.setFieldValue('name', data.name);
    }
  }, [data]);

  return (
    <Form form={form} className="container__form" onFinish={onSubmit}>
      <CommonDatabaseContainer action={action} saveDisabled={loading} cancelDisabled={loading} withFooter onCancel={onCancel}>
        <Spin spinning={loading}>
          <div className="database__container_item">
            <Form.Item label="Name" name="name" rules={[{ required: true, message: EFormFieldMessages.required }]}>
              <Input />
            </Form.Item>
          </div>
        </Spin>
      </CommonDatabaseContainer>
    </Form>
  );
};

const mapState = (state: RootState) => ({
  hospitalModel: state.hospitalModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getHospitalModelById: dispatch.hospitalModel.getHospitalModelById,
  createHospitalModel: dispatch.hospitalModel.createHospitalModel,
  updateHospitalModel: dispatch.hospitalModel.updateHospitalModel,
  clearHospitalModel: dispatch.hospitalModel.clearHospitalModel,
});

export const HospitalCard = connect(mapState, mapDispatch)(HospitalCardComponent);
