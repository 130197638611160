import React, { useEffect, useState } from 'react';
import { Button, Form, Spin, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { connect } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';
import { IOption } from 'common/models';
import { DateFilter } from 'common/components/DateFilter';
import { RootDispatch, RootState } from 'app/store';
import {
  renderTechnicianScheduleRecords,
  toTechnicianScheduleFormValuesMapper,
  toTechnicianScheduleParamsMapper,
  toTechnicianScheduleDataSourceMapper,
  getTechnicianScheduleFieldError,
} from 'entities/TechnicianSchedule/TechnicianSchedule.helper';
import { formValuesMapper } from 'entities/Technicians/Technicians.helper';
import { TechnicianScheduleHeader } from 'entities/TechnicianSchedule/components/TechnicianScheduleHeader';
import { TechnicianScheduleFilters } from 'entities/TechnicianSchedule/components/TechnicianScheduleFilters';

interface IComponentProps {
  hospitalsOptions?: IOption[];
  facilitiesOptions?: IOption[];
  techniciansOptions?: IOption[];
  hospital?: IOption;
  facilityIds?: number[];
  technician: IOption;
  loading: boolean;
  onHospitalChange: (value: IOption) => void;
  onFacilityIdsChange: (value?: number[]) => void;
  onTechnicianChange: (value: IOption) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const TechnicianScheduleComponent: React.FC<AllType> = (props) => {
  const {
    hospitalsOptions,
    facilitiesOptions,
    techniciansOptions,
    hospital,
    facilityIds,
    technician,
    loading,
    onHospitalChange,
    onFacilityIdsChange,
    onTechnicianChange,
    techniciansCollection,
    technicianScheduleCollection,
    getTechnicianScheduleCollection,
    changeTechnicianScheduleCollection,
    updateTechnicianScheduleCollection,
    clearTechnicianSchedule,
  } = props;
  const { data: techniciansCollectionData } = techniciansCollection;
  const { data: technicianScheduleCollectionData, loading: technicianScheduleCollectionLoading } = technicianScheduleCollection;
  const facilityId = facilityIds?.[0];

  // dayjs().day(1) - Mondey
  const [dateFrom, setDateFrom] = useState<Dayjs>(dayjs().day(1));
  const [formChanged, setFormChanged] = useState<boolean>(false);
  const [form] = useForm();

  const dataSource = toTechnicianScheduleDataSourceMapper(
    dateFrom,
    technician,
    technicianScheduleCollectionData,
    techniciansCollectionData?.data,
  );
  const columns = renderTechnicianScheduleRecords(dateFrom, facilityId);
  const params = toTechnicianScheduleParamsMapper(technician, dateFrom, hospital);

  const handleDateChange = (value: Dayjs) => {
    setDateFrom(value);
  };

  const onFormValuesChange = (values: { [key: string]: string }) => {
    const { colId, fieldId, value, rowId } = formValuesMapper(values);

    if (rowId && colId && fieldId) {
      setFormChanged(true);

      form.setFields([{ name: [Number(rowId), colId, fieldId], errors: [] }]);

      changeTechnicianScheduleCollection({
        rowId,
        colId,
        fieldId,
        value: value as string,
        facilityId,
      });
    }
  };

  const onSubmit = () => {
    const filteredSchedule = technicianScheduleCollectionData
      ?.filter((item) => item.edited)
      .map((item) => ({
        technicianName: item.technicianName,
        id: item.id,
        technicianId: item.technicianId,
        facilityId: item.facilityId,
        shift: item.shift,
        capacity: item.capacity,
        date: item.date,
      }));

    if (filteredSchedule) {
      updateTechnicianScheduleCollection({ items: filteredSchedule }).then((response) => {
        if (response && response.items.every((item) => !item.error)) {
          getTechnicianScheduleCollection(params);
          setFormChanged(false);
        }

        if (response && response.items.some((item) => item.error)) {
          const errors = getTechnicianScheduleFieldError(response.items);

          form.setFields(errors);
        }
      });
    }
  };

  const onCancelClick = () => {
    getTechnicianScheduleCollection(params);
    setFormChanged(false);
  };

  useEffect(() => {
    if (hospital) {
      getTechnicianScheduleCollection(params);
    }
  }, [technician, dateFrom, hospital]);

  useEffect(() => {
    const values = toTechnicianScheduleFormValuesMapper(dataSource, dateFrom);

    form.setFieldsValue(values);
  }, [dataSource, dateFrom]);

  useEffect(() => {
    return () => {
      clearTechnicianSchedule();
    };
  }, []);

  return (
    <div className="content technician-schedule">
      <TechnicianScheduleHeader options={techniciansOptions} value={technician} onChange={onTechnicianChange} />

      <div className="content__container dashboard-schedule__container">
        <TechnicianScheduleFilters
          hospitalsOptions={hospitalsOptions}
          facilitiesOptions={facilitiesOptions}
          hospitalValue={hospital}
          facilityValue={facilityId}
          onHospitalChange={onHospitalChange}
          onFacilityChange={onFacilityIdsChange}
        />

        <div className="content__container_main">
          <DateFilter value={dateFrom} onChange={handleDateChange} />

          <Spin spinning={loading || technicianScheduleCollectionLoading}>
            <Form form={form} onValuesChange={onFormValuesChange} onFinish={onSubmit}>
              <Table className="schedule" dataSource={dataSource} columns={columns} pagination={false} />

              {formChanged && (
                <div className="technician-schedule__footer">
                  <Button className="btn-primary" htmlType="submit">
                    Save
                  </Button>

                  <Button className="btn-bordered" onClick={onCancelClick}>
                    Reset to default
                  </Button>
                </div>
              )}
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  techniciansCollection: state.techniciansCollection,
  technicianScheduleCollection: state.technicianScheduleCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getTechnicianScheduleCollection: dispatch.technicianScheduleCollection.getTechnicianScheduleCollection,
  changeTechnicianScheduleCollection: dispatch.technicianScheduleCollection.changeTechnicianScheduleCollection,
  updateTechnicianScheduleCollection: dispatch.technicianScheduleCollection.updateTechnicianScheduleCollection,
  clearTechnicianSchedule: dispatch.technicianScheduleCollection.clearTechnicianSchedule,
});

export const TechnicianSchedule = connect(mapState, mapDispatch)(TechnicianScheduleComponent);
