import { createModel } from '@rematch/core';
import { showError } from 'common/helpers/error.helper';
import { IRootModel } from 'app/store';
import { hospitalsTransport } from 'entities/Hospitals/Hospitals.transport';
import {
  IHospitalModel,
  IHospitalModelCreatePayload,
  IHospitalModelDeletePayload,
  IHospitalModelDto,
  IHospitalModelUpdatePayload,
  IHospitalsCollection,
  IHospitalsCollectionDto,
  IHospitalsCollectionPayload,
} from 'entities/Hospitals/Hospitals.models';

export const hospitalsCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IHospitalsCollection,
  reducers: {
    setHospitalsCollection: (state, payload: IHospitalsCollectionDto) => ({ ...state, data: payload }),
    setHospitalsCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearHospitalsCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getHospitalsCollection(params: IHospitalsCollectionPayload) {
      dispatch.hospitalsCollection.setHospitalsCollectionLoading(true);
      await hospitalsTransport
        .getHospitalsCollection(params)
        .then((response) => {
          dispatch.hospitalsCollection.setHospitalsCollection(response);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.hospitalsCollection.setHospitalsCollectionLoading(false);
        });
    },
  }),
});

export const hospitalModel = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IHospitalModel,
  reducers: {
    setHospitalModel: (state, payload: IHospitalModelDto) => ({ ...state, data: payload }),
    setHospitalModelLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearHospitalModel: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getHospitalModelById(id: number) {
      dispatch.hospitalModel.setHospitalModelLoading(true);
      await hospitalsTransport
        .getHospitalModelById(id)
        .then((response) => {
          dispatch.hospitalModel.setHospitalModel(response);
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.hospitalModel.setHospitalModelLoading(false);
        });
    },
    async createHospitalModel(payload: IHospitalModelCreatePayload) {
      dispatch.hospitalModel.setHospitalModelLoading(true);
      await hospitalsTransport
        .createHospitalModel(payload)
        .then((response) => {
          dispatch.hospitalModel.setHospitalModel(response);

          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.hospitalModel.setHospitalModelLoading(false);
        });
    },
    async updateHospitalModel(payload: IHospitalModelUpdatePayload) {
      dispatch.hospitalModel.setHospitalModelLoading(true);
      await hospitalsTransport
        .updateHospitalModel(payload)
        .then((response) => {
          dispatch.hospitalModel.setHospitalModel(response);

          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.hospitalModel.setHospitalModelLoading(false);
        });
    },
    async deleteHospitalModel(payload: IHospitalModelDeletePayload) {
      dispatch.hospitalModel.setHospitalModelLoading(true);
      await hospitalsTransport
        .deleteHospitalModel(payload)
        .then(() => {
          if (payload.onSuccess) {
            payload.onSuccess();
          }
        })
        .catch((error) => {
          showError(error);
        })
        .finally(() => {
          dispatch.hospitalModel.setHospitalModelLoading(false);
        });
    },
  }),
});
