import React, { useState } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { EBedScheduleProperties } from 'common/const/enum.const';
import { ReactComponent as EditIcon } from 'app/assets/images/edit-icon.svg';
import { RootDispatch, RootState } from 'app/store';
import { BedScheduleCounter } from 'entities/BedSchedules/components/BedScheduleCounter';
import { IBedScheduleEquipmentEntity, IBedScheduleEntity } from 'entities/BedSchedules/BedSchedules.models';
import { BedScheduleEquipmentModal } from 'entities/BedSchedules/components/BedScheduleEquipmentModal';
import { createScheduleItemEquipments } from 'entities/BedSchedules/BedSchedules.helper';

interface IComponentProps {
  record: IBedScheduleEntity;
  weekDay: string;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const BedScheduleItemComponent: React.FC<AllType> = (props) => {
  const { record, weekDay, bedScheduleCollection, facilitiesCollection, changeBedScheduleCollection } = props;
  const { data: bedScheduleCollectionData } = bedScheduleCollection;
  const { data: facilitiesCollectionData } = facilitiesCollection;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [equipments, setEquipments] = useState<IBedScheduleEquipmentEntity[]>([]);

  const scheduleItem = bedScheduleCollectionData?.find(
    (item) => item.facilityId === record.facilityId && item.date === record[weekDay].date,
  );
  const facility = facilitiesCollectionData?.data.find((item) => item.id === record.facilityId);
  const initialEquipments = createScheduleItemEquipments(facility?.equipments, scheduleItem?.equipments);
  const dayShiftBeds = record[weekDay].dayShiftBeds;
  const nightShiftBeds = record[weekDay].nightShiftBeds;

  const onDayBedsChange = (value: number) => {
    changeBedScheduleCollection({ record, weekDay, property: EBedScheduleProperties.DayShiftBeds, value });
  };

  const onNightBedsChange = (value: number) => {
    changeBedScheduleCollection({ record, weekDay, property: EBedScheduleProperties.NightShiftBeds, value });
  };

  const onEditClick = () => {
    setEquipments(initialEquipments);
    setShowModal(true);
  };

  const onEquipmentCountChange = (equipmentId: number, value: number) => {
    const equipment = equipments.find((item) => item.equipmentId === equipmentId);

    if (equipment) {
      equipment.count = value;

      setEquipments([...equipments]);
    }
  };

  const onSave = () => {
    changeBedScheduleCollection({ record, weekDay, property: EBedScheduleProperties.Equipments, value: equipments });
    setShowModal(false);
  };

  const onCancel = () => {
    setEquipments(initialEquipments);
    setShowModal(false);
  };

  return (
    <div className="bed-schedule__item">
      <BedScheduleCounter
        title="Beds for day shift"
        total={record.bedsTotalCount}
        value={dayShiftBeds}
        onChange={onDayBedsChange}
      />

      <BedScheduleCounter
        title="Beds for night shift"
        total={record.bedsTotalCount}
        value={nightShiftBeds}
        onChange={onNightBedsChange}
      />

      {!!initialEquipments.length && (
        <div className="bed-schedule__item_equipment">
          <span>Equipment</span>

          <Button className="btn-icon" icon={<EditIcon />} onClick={onEditClick} disabled={false} />
        </div>
      )}

      <div className="bed-schedule__item_tooltip">{record[weekDay].error}</div>

      <BedScheduleEquipmentModal
        open={showModal}
        equipments={equipments}
        onChange={onEquipmentCountChange}
        onSave={onSave}
        onCancel={onCancel}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  bedScheduleCollection: state.bedScheduleCollection,
  facilitiesCollection: state.facilitiesCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  changeBedScheduleCollection: dispatch.bedScheduleCollection.changeBedScheduleCollection,
});

export const BedScheduleItem = connect(mapState, mapDispatch)(BedScheduleItemComponent);
