import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { DatabaseTable } from 'common/components/DatabaseTable';
import { IOption } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import { FacilityEquipmentModal } from 'entities/Facilities/components/FacilityEquipmentModal';
import { getFacilityEquipmentsColumns, getFacilityEquipmentsOptions } from 'entities/Facilities/Facilities.helper';
import { IFacilityEquipmentEntity } from 'entities/Facilities/Facilities.models';

interface IComponentProps {
  facilityEquipments: IFacilityEquipmentEntity[];
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const FacilityEquipmentsComponent: React.FC<AllType> = (props) => {
  const {
    facilityEquipments = [],
    equipmentsCollection,
    facilityEquipmentsCollection,
    getEquipmentsCollection,
    createFacilityEquipment,
    updateFacilityEquipment,
    deleteFacilityEquipment,
  } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [equipmentValue, setEquipmentValue] = useState<IOption>();
  const [countValue, setCountValue] = useState<number>();

  const { data: equipmentsCollectionData } = equipmentsCollection;
  const { data: facilityEquipmentsCollectionData } = facilityEquipmentsCollection;
  const equipmentsOptions = getFacilityEquipmentsOptions(facilityEquipmentsCollectionData, equipmentsCollectionData?.data);

  const onEdit = (id: number) => {
    const equipment = facilityEquipmentsCollectionData?.find((item) => item.equipmentId === id);

    if (equipment) {
      setIsEdit(true);
      setEquipmentValue({ label: equipment.name, value: equipment.equipmentId });
      setCountValue(equipment.count);
      setShowModal(true);
    }
  };

  const onDelete = (id: number) => {
    deleteFacilityEquipment(id);
  };

  const columns = getFacilityEquipmentsColumns(onEdit, onDelete);

  const addEquipment = () => {
    setShowModal(true);
  };

  const onEquipmentChange = (value: IOption | IOption[]) => {
    setEquipmentValue(value as IOption);
  };

  const onCountChange = (value: number | null) => {
    value ? setCountValue(value) : setCountValue(undefined);
  };

  const onSaveClick = () => {
    if (equipmentValue && countValue) {
      if (isEdit) {
        updateFacilityEquipment({ equipment: equipmentValue, count: countValue });

        setIsEdit(false);
      } else {
        createFacilityEquipment({ equipment: equipmentValue, count: countValue });
      }
    }

    setEquipmentValue(undefined);
    setCountValue(undefined);
    setShowModal(false);
  };

  const onCancelClick = () => {
    setEquipmentValue(undefined);
    setCountValue(undefined);
    setShowModal(false);
  };

  useEffect(() => {
    getEquipmentsCollection({});
  }, []);

  return (
    <div className="facility-card__equipments">
      <div className="facility-card__equipments-header">
        <span>Special Equipment</span>

        <Button className="btn-primary" onClick={addEquipment}>
          Add Equipment
        </Button>
      </div>

      <DatabaseTable dataSource={facilityEquipments} columns={columns} />

      <FacilityEquipmentModal
        equipmentsOptions={equipmentsOptions}
        open={showModal}
        equipmentValue={equipmentValue}
        countValue={countValue}
        isEdit={isEdit}
        onEquipmentChange={onEquipmentChange}
        onCountChange={onCountChange}
        onSaveClick={onSaveClick}
        onCancelClick={onCancelClick}
      />
    </div>
  );
};

const mapState = (state: RootState) => ({
  equipmentsCollection: state.equipmentsCollection,
  facilityEquipmentsCollection: state.facilityEquipmentsCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getEquipmentsCollection: dispatch.equipmentsCollection.getEquipmentsCollection,
  createFacilityEquipment: dispatch.facilityEquipmentsCollection.createFacilityEquipment,
  updateFacilityEquipment: dispatch.facilityEquipmentsCollection.updateFacilityEquipment,
  deleteFacilityEquipment: dispatch.facilityEquipmentsCollection.deleteFacilityEquipment,
});

export const FacilityEquipments = connect(mapState, mapDispatch)(FacilityEquipmentsComponent);
