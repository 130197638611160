import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input } from 'antd';
import { useDebounce } from 'common/hooks/useDebounce';
import { DEFAULT_DEBOUNCE_DELAY } from 'common/config';

interface IComponentProps {
  value?: string;
  placeholder: string;
  onChange: (value: string) => void;
}

export const SearchInput: React.FC<IComponentProps> = ({ value = '', placeholder, onChange }) => {
  const [searchValue, setSearchValue] = useState<string>(value);

  const debouncedSearchValue = useDebounce(searchValue, DEFAULT_DEBOUNCE_DELAY);

  useEffect(() => {
    onChange(searchValue);
  }, [debouncedSearchValue]);

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <Input
      placeholder={placeholder}
      suffix={<div className="icon search-input-suffix" />}
      value={searchValue}
      onChange={handleValueChange}
    />
  );
};
