import React from 'react';
import ReactDOM from 'react-dom/client';
import 'app/assets/sass/index.scss';
import reportWebVitals from 'reportWebVitals';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { IAsyncStorage, WebStorageDecorator } from '@axmit/universal-storage';
import { configureAxiosJWTInterseptors } from '@axmit/axios-patch-jwt';
import axios from 'axios';
import { App } from 'app/pages/App';
import store, { history } from 'app/store';

// Axios initialization
const storage: IAsyncStorage = new WebStorageDecorator(localStorage);
configureAxiosJWTInterseptors({
  storage,
  // @ts-ignore
  axios,
  refreshTokenEndpoint: '/auth',
});

axios.defaults.baseURL = `/api`;
axios.interceptors.request.use((config) => config);
axios.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    if (error.response) {
      const { status, statusText, data } = error.response;
      return Promise.reject({ data, status, statusText });
    } else {
      return Promise.reject(error);
    }
  },
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
