import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import type { ColumnsType } from 'antd/es/table';
import { EBedScheduleProperties, EDateFormat, EDaysOfSchedule } from 'common/const/enum.const';
import { IOption } from 'common/models';
import { getColumnTitle } from 'common/helpers/date.helper';
import { BedScheduleItem } from 'entities/BedSchedules/components/BedScheduleItem';
import {
  IBedScheduleCollectionChangePayload,
  IBedScheduleDto,
  IBedScheduleEntity,
  IBedScheduleEquipmentDto,
  IBedScheduleEquipmentEntity,
} from 'entities/BedSchedules/BedSchedules.models';
import { IFacilityEquipmentDto, IFacilityModelDto } from 'entities/Facilities/Facilities.models';

export const getBedSceduleEquipmentStatus = (record: IBedScheduleEntity, weekDay: string) => {
  const equipments = record[weekDay].equipments;

  if (equipments?.some((item: IBedScheduleEquipmentEntity) => item.count === 0)) {
    return 'none';
  }

  if (!equipments || equipments?.every((item: IBedScheduleEquipmentEntity) => item.count >= item.total)) {
    return 'all';
  }

  return 'partially';
};

export const toBedSchedulesParamsMapper = (dateFrom: Dayjs, facilityIds?: number[], hospital?: IOption) => {
  return {
    limit: 0,
    dateFrom: dateFrom.format(EDateFormat.FullDate),
    dateTo: dateFrom.day(7).format(EDateFormat.FullDate),
    facilityIds: facilityIds?.length ? facilityIds.join(',') : undefined,
    clinicId: !facilityIds?.length && hospital ? Number(hospital.value) : undefined,
  };
};

export const createScheduleItemEquipments = (
  facilityEquipments?: IFacilityEquipmentDto[],
  scheduleItemEquipments?: IBedScheduleEquipmentDto[],
) => {
  return (
    facilityEquipments?.map((facilityEquipment) => {
      const scheduleItemEquipment = scheduleItemEquipments?.find(
        (scheduleItemEquipment) => facilityEquipment.equipmentId === scheduleItemEquipment.equipmentId,
      );

      if (scheduleItemEquipment) {
        return {
          equipmentId: scheduleItemEquipment.equipmentId,
          equipmentName: scheduleItemEquipment.equipmentName,
          count: scheduleItemEquipment.count,
          total: facilityEquipment.count,
        };
      } else {
        return {
          equipmentId: facilityEquipment.equipmentId,
          equipmentName: facilityEquipment.equipmentName,
          count: facilityEquipment.count,
          total: facilityEquipment.count,
        };
      }
    }) || []
  );
};

export const toBedSchedulesDataSourceMapper = (
  dateFrom: Dayjs,
  schedule: IBedScheduleDto[] | null,
  facilityIds?: number[],
  facilities?: IFacilityModelDto[],
) => {
  if (!schedule) {
    return [];
  }

  const dataSource: IBedScheduleEntity[] = [];

  facilities?.forEach((facility) => {
    if (facilityIds?.includes(facility.id) || !facilityIds?.length) {
      const facilitySchedule = schedule.filter((item) => item.facilityId === facility.id);

      const entity: IBedScheduleEntity = {
        key: facility.id,
        facilityId: facility.id,
        facilityName: facility.name,
        bedsTotalCount: facility.capacity,
      };

      for (let i = 1; i <= 7; i++) {
        const date = dateFrom.day(i).format(EDateFormat.FullDate);
        const scheduleItem = facilitySchedule.find((item) => item.date === date);

        if (scheduleItem) {
          entity[dateFrom.day(i).format(EDateFormat.ShortDayOfWeek).toLowerCase()] = {
            date,
            dayShiftBeds: scheduleItem.dayShiftBeds,
            equipments: createScheduleItemEquipments(facility.equipments, scheduleItem.equipments),
            nightShiftBeds: scheduleItem.nightShiftBeds,
            error: scheduleItem.error,
          };
        } else {
          entity[dateFrom.day(i).format(EDateFormat.ShortDayOfWeek).toLowerCase()] = {
            date,
            dayShiftBeds: facility.capacity,
            equipments: createScheduleItemEquipments(facility.equipments),
            nightShiftBeds: facility.capacity,
          };
        }
      }

      dataSource.push(entity);
    }
  });

  return dataSource;
};

const getBedScheduleCellClassName = (record: IBedScheduleEntity, weekDay: string) => {
  const equipmentStatus = getBedSceduleEquipmentStatus(record, weekDay);
  const dayShiftBeds = record[weekDay].dayShiftBeds;
  const nightShiftBeds = record[weekDay].nightShiftBeds;
  const bedsTotalCount = record.bedsTotalCount;
  let className = '';

  if (record[weekDay].error) {
    className += 'cell-error ';
  }

  if (dayShiftBeds === 0 || nightShiftBeds === 0 || equipmentStatus === 'none') {
    return (className += 'cell-red');
  }

  if (dayShiftBeds >= bedsTotalCount && nightShiftBeds >= bedsTotalCount && equipmentStatus === 'all') {
    return (className += 'cell-green');
  }

  return (className += 'cell-yellow');
};

export const renderBedSchedulesCell = (record: IBedScheduleEntity, weekDay: string) => {
  return <BedScheduleItem record={record} weekDay={weekDay} />;
};

export const renderBedSchedulesRecords = (dateFrom: Dayjs) => {
  return [
    {
      title: 'Hospital',
      dataIndex: 'facilityName',
      render: (_, record) => record.facilityName,
    },
    {
      title: getColumnTitle(dateFrom, 1),
      dataIndex: EDaysOfSchedule.Mon,
      onCell: (record) => {
        return {
          className: getBedScheduleCellClassName(record, EDaysOfSchedule.Mon),
        };
      },
      render: (_, record) => renderBedSchedulesCell(record, EDaysOfSchedule.Mon),
    },
    {
      title: getColumnTitle(dateFrom, 2),
      dataIndex: EDaysOfSchedule.Tue,
      onCell: (record) => {
        return {
          className: getBedScheduleCellClassName(record, EDaysOfSchedule.Tue),
        };
      },
      render: (_, record) => renderBedSchedulesCell(record, EDaysOfSchedule.Tue),
    },
    {
      title: getColumnTitle(dateFrom, 3),
      dataIndex: EDaysOfSchedule.Wed,
      onCell: (record) => {
        return {
          className: getBedScheduleCellClassName(record, EDaysOfSchedule.Wed),
        };
      },
      render: (_, record) => renderBedSchedulesCell(record, EDaysOfSchedule.Wed),
    },
    {
      title: getColumnTitle(dateFrom, 4),
      dataIndex: EDaysOfSchedule.Thu,
      onCell: (record) => {
        return {
          className: getBedScheduleCellClassName(record, EDaysOfSchedule.Thu),
        };
      },
      render: (_, record) => renderBedSchedulesCell(record, EDaysOfSchedule.Thu),
    },
    {
      title: getColumnTitle(dateFrom, 5),
      dataIndex: EDaysOfSchedule.Fri,
      onCell: (record) => {
        return {
          className: getBedScheduleCellClassName(record, EDaysOfSchedule.Fri),
        };
      },
      render: (_, record) => renderBedSchedulesCell(record, EDaysOfSchedule.Fri),
    },
    {
      title: getColumnTitle(dateFrom, 6),
      dataIndex: EDaysOfSchedule.Sat,
      onCell: (record) => {
        return {
          className: getBedScheduleCellClassName(record, EDaysOfSchedule.Sat),
        };
      },
      render: (_, record) => renderBedSchedulesCell(record, EDaysOfSchedule.Sat),
    },
    {
      title: getColumnTitle(dateFrom, 7),
      dataIndex: EDaysOfSchedule.Sun,
      onCell: (record) => {
        return {
          className: getBedScheduleCellClassName(record, EDaysOfSchedule.Sun),
        };
      },
      render: (_, record) => renderBedSchedulesCell(record, EDaysOfSchedule.Sun),
    },
  ] as ColumnsType<IBedScheduleEntity>;
};

export const toBedScheduleItemMapper = (payload: IBedScheduleCollectionChangePayload, scheduleItem?: IBedScheduleDto) => {
  const { record, weekDay, property, value } = payload;
  // @ts-ignore
  const newScheduleItem: IBedScheduleDto = {
    edited: true,
  };

  if (scheduleItem) {
    newScheduleItem.id = scheduleItem.id;
    newScheduleItem.createdAt = scheduleItem.createdAt;
    newScheduleItem.updatedAt = scheduleItem.updatedAt;
    newScheduleItem.facilityId = scheduleItem.facilityId;
    newScheduleItem.facilityName = scheduleItem.facilityName;
    newScheduleItem.date = scheduleItem.date;
  } else {
    newScheduleItem.scheduleId = dayjs().valueOf();
    newScheduleItem.createdAt = dayjs().toISOString();
    newScheduleItem.updatedAt = dayjs().toISOString();
    newScheduleItem.facilityId = record.facilityId;
    newScheduleItem.facilityName = record.facilityName;
    newScheduleItem.date = record[weekDay].date;
  }

  if (property === EBedScheduleProperties.DayShiftBeds) {
    newScheduleItem.nightShiftBeds = record[weekDay].nightShiftBeds;
    newScheduleItem.equipments = record[weekDay].equipments;
    newScheduleItem.dayShiftBeds = value as number;
  }

  if (property === EBedScheduleProperties.NightShiftBeds) {
    newScheduleItem.dayShiftBeds = record[weekDay].dayShiftBeds;
    newScheduleItem.equipments = record[weekDay].equipments;
    newScheduleItem.nightShiftBeds = value as number;
  }

  if (property === EBedScheduleProperties.Equipments) {
    newScheduleItem.dayShiftBeds = record[weekDay].dayShiftBeds;
    newScheduleItem.nightShiftBeds = record[weekDay].nightShiftBeds;
    // @ts-ignore
    newScheduleItem.equipments = value as IBedScheduleEquipmentEntity[];
  }

  return newScheduleItem;
};
