import React, { useEffect, useState } from 'react';
import { Button, Spin, TablePaginationConfig } from 'antd';
import { connect } from 'react-redux';
import { EDatabaseActions, EDatabaseTabValues, EOrderDirections, ECommonOrderFields } from 'common/const/enum.const';
import { DatabaseTable } from 'common/components/DatabaseTable';
import { toDataSourseMapper, toOptionsMapper } from 'common/helpers/data.helper';
import { Selector } from 'common/components/Selector';
import { IOption } from 'common/models';
import { ConfirmationModal } from 'common/components/СonfirmationModal';
import { DEFAULT_PAGE_ID, DEFAULT_PAGE_SIZE } from 'common/config';
import { RootDispatch, RootState, history } from 'app/store';
import { getDatabaseColumns, getDatabasePath } from 'entities/Database/Database.helper';
import { databaseColumns } from 'entities/Database/Database.const';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

interface IHospitalOption extends IOption {
  value: number;
  label: string;
}

const FacilitiesComponent: React.FC<AllType> = (props) => {
  const {
    hospitalsCollection,
    facilitiesCollection,
    facilityModel,
    getHospitalsCollection,
    getFacilitiesCollection,
    clearHospitalsCollection,
    clearFacilitiesCollection,
    deleteFacilityModel,
  } = props;

  const [hospital, setHospital] = useState<IHospitalOption>();
  const [orderField, setOrderField] = useState<ECommonOrderFields>(ECommonOrderFields.Name);
  const [orderDirection, setOrderDirection] = useState<EOrderDirections | undefined>(EOrderDirections.ASC);
  const [facilityId, setFacilityId] = useState<number | null>(null);
  const [pageId, setPageId] = useState<number>(DEFAULT_PAGE_ID);
  const [open, setOpen] = useState<boolean>(false);
  const { data: hospitalsCollectionData, loading: hospitalsCollectionLoading } = hospitalsCollection;
  const { data: facilitiesCollectionData, loading: facilitiesCollectionLoading } = facilitiesCollection;
  const { loading: facilityModelLoading } = facilityModel;

  const hospitalsOptions = toOptionsMapper(hospitalsCollectionData?.data);
  const dataSource = toDataSourseMapper(facilitiesCollectionData?.data);

  if (!hospital && hospitalsOptions.length) {
    setHospital(hospitalsOptions[0]);
  }

  const handleHospitalChange = (value: IOption | IOption[]) => {
    const selected = Array.isArray(value) ? value[0] : value;
    setHospital({
      value: Number(selected.value),
      label: selected.label,
    });
  };

  const addFacilities = () => {
    const path = getDatabasePath(EDatabaseTabValues.Facilities, EDatabaseActions.Add, hospital?.value);

    history.push(path);
  };

  const onEdit = (id: number) => {
    const path = getDatabasePath(EDatabaseTabValues.Facilities, EDatabaseActions.Edit, id);

    history.push(path);
  };

  const onDelete = (id: number) => {
    setFacilityId(id);
    setOpen(true);
  };

  const onYesClick = () => {
    facilityId &&
      deleteFacilityModel({
        id: facilityId,
        onSuccess: () => {
          getFacilitiesCollection({ clinicId: hospital?.value, orderField, orderDirection });
          setFacilityId(null);
          setOpen(false);
        },
      });
  };

  const onNoClick = () => {
    setFacilityId(null);
    setOpen(false);
  };

  const onView = (id: number) => {
    const path = getDatabasePath(EDatabaseTabValues.Facilities, EDatabaseActions.View, id);

    history.push(path);
  };

  const handleSort = (orderField: ECommonOrderFields, orderDirection?: EOrderDirections) => {
    setOrderField(orderField);
    setOrderDirection(orderDirection);
  };

  const columns = getDatabaseColumns(databaseColumns, onEdit, onDelete, onView);

  useEffect(() => {
    getHospitalsCollection({ orderDirection: EOrderDirections.ASC, orderField: ECommonOrderFields.Name, limit: 0 });

    return () => {
      clearHospitalsCollection();
    };
  }, []);

  useEffect(() => {
    getFacilitiesCollection({
      clinicId: hospital?.value,
      orderField,
      orderDirection,
      limit: DEFAULT_PAGE_SIZE,
      offset: (pageId - 1) * DEFAULT_PAGE_SIZE,
    });

    return () => {
      clearFacilitiesCollection();
    };
  }, [hospital, orderField, orderDirection, pageId]);

  let paginationConfig: TablePaginationConfig | undefined = undefined;

  if (facilitiesCollectionData?.count) {
    paginationConfig = {
      current: Number(pageId),
      total: facilitiesCollectionData?.count,
      onChange: (pageId: number) => setPageId(pageId),
      defaultPageSize: DEFAULT_PAGE_SIZE,
    };
  }

  return (
    <Spin spinning={hospitalsCollectionLoading || facilitiesCollectionLoading}>
      <div className="database__container_main-header">
        <Button className="btn-primary" onClick={addFacilities} disabled={!hospital?.value}>
          Add Facilities
        </Button>

        <div className="database__container_main-header-hospitals">
          <span>Hospital</span>

          <Selector showSearch={true} options={hospitalsOptions} value={hospital} onChange={handleHospitalChange} />
        </div>
      </div>

      <DatabaseTable dataSource={dataSource} columns={columns} sort={handleSort} paginationConfig={paginationConfig} />

      <ConfirmationModal open={open} loading={facilityModelLoading} onYesClick={onYesClick} onNoClick={onNoClick} />
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  hospitalsCollection: state.hospitalsCollection,
  facilitiesCollection: state.facilitiesCollection,
  facilityModel: state.facilityModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getHospitalsCollection: dispatch.hospitalsCollection.getHospitalsCollection,
  getFacilitiesCollection: dispatch.facilitiesCollection.getFacilitiesCollection,
  clearHospitalsCollection: dispatch.hospitalsCollection.clearHospitalsCollection,
  clearFacilitiesCollection: dispatch.facilitiesCollection.clearFacilitiesCollection,
  deleteFacilityModel: dispatch.facilityModel.deleteFacilityModel,
});

export const Facilities = connect(mapState, mapDispatch)(FacilitiesComponent);
