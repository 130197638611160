import { allOption } from 'common/const/data.const';
import { IOption } from 'common/models';
import { IStudyModelDto } from 'entities/Studies/Studies.models';

export const toStudyFormValuesMapper = (studyModelData: IStudyModelDto) => {
  const certificates = studyModelData.credentials.map((item) => ({
    stateId: { label: item.stateName || allOption.label, value: item.stateId || allOption.value },
    credentials: item.credentials.map((credential) => ({ label: credential.credentialCode, value: credential.credentialId })),
  }));

  return {
    name: studyModelData.name,
    equipments: studyModelData.equipments.map((item) => ({ label: item.equipmentName, value: item.equipmentId })),
    certificates: Object.assign({}, certificates),
  };
};

export const toStudyPayloadMapper = (values: any) => {
  return {
    name: values.name,
    equipments: values.equipments?.map((item: IOption) => ({ equipmentId: item.value, count: 1 })),
    credentials: values.certificates
      ? Object.values(values.certificates).map((item: any) => ({
          stateId: item.stateId.value !== allOption.value ? item.stateId.value : undefined,
          credentials: item.credentials.map((credential: IOption) => credential.value),
        }))
      : [],
  };
};
