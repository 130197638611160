import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import { ERoutes } from 'common/const/enum.const';
import { useFilters } from 'common/hooks/useFilters';
import { RootDispatch, RootState } from 'app/store';
import { DashboardSchedule } from 'entities/DashboardSchedule/components/DashboardSchedule';
import { TechnicianSchedule } from 'entities/TechnicianSchedule/components/TechnicianSchedule';
import { BedSchedules } from 'entities/BedSchedules/components/BedSchedules';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const SchedulesComponent: React.FC<AllType> = (props) => {
  const {
    hospitalsCollection,
    facilitiesCollection,
    techniciansCollection,
    getHospitalsCollection,
    getFacilitiesCollection,
    getTechniciansCollection,
  } = props;

  const {
    hospitalsOptions,
    facilitiesOptions,
    techniciansOptions,
    hospital,
    facilityIds,
    shift,
    technician,
    loading,
    onHospitalChange,
    onFacilityIdsChange,
    onShiftChange,
    onTechnicianChange,
  } = useFilters({
    hospitalsCollection,
    facilitiesCollection,
    techniciansCollection,
    getHospitalsCollection,
    getFacilitiesCollection,
    getTechniciansCollection,
  });

  return (
    <Routes>
      <Route
        path={ERoutes.Dashboard}
        element={
          <DashboardSchedule
            hospitalsOptions={hospitalsOptions}
            facilitiesOptions={facilitiesOptions}
            hospital={hospital}
            facilityIds={facilityIds}
            shift={shift}
            loading={loading}
            onHospitalChange={onHospitalChange}
            onFacilityIdsChange={onFacilityIdsChange}
            onShiftChange={onShiftChange}
          />
        }
      />
      <Route
        path={ERoutes.TechnicianSchedule}
        element={
          <TechnicianSchedule
            hospitalsOptions={hospitalsOptions}
            facilitiesOptions={facilitiesOptions}
            techniciansOptions={techniciansOptions}
            hospital={hospital}
            facilityIds={facilityIds}
            technician={technician}
            loading={loading}
            onHospitalChange={onHospitalChange}
            onFacilityIdsChange={onFacilityIdsChange}
            onTechnicianChange={onTechnicianChange}
          />
        }
      />
      <Route
        path={ERoutes.BEManagement}
        element={
          <BedSchedules
            hospitalsOptions={hospitalsOptions}
            facilitiesOptions={facilitiesOptions}
            hospital={hospital}
            facilityIds={facilityIds}
            loading={loading}
            onHospitalChange={onHospitalChange}
            onFacilityIdsChange={onFacilityIdsChange}
          />
        }
      />
      <Route path="*" element={<Navigate to={ERoutes.Dashboard} replace />} />
    </Routes>
  );
};

const mapState = (state: RootState) => ({
  hospitalsCollection: state.hospitalsCollection,
  facilitiesCollection: state.facilitiesCollection,
  techniciansCollection: state.techniciansCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getHospitalsCollection: dispatch.hospitalsCollection.getHospitalsCollection,
  getFacilitiesCollection: dispatch.facilitiesCollection.getFacilitiesCollection,
  getTechniciansCollection: dispatch.techniciansCollection.getTechniciansCollection,
});

export const Schedules = connect(mapState, mapDispatch)(SchedulesComponent);
