import { createModel } from '@rematch/core';
import { showError } from 'common/helpers/error.helper';
import { IRootModel } from 'app/store';
import { IStateCollection, IStateCollectionDto } from 'entities/States/States.models';
import { statesTransport } from 'entities/States/States.transport';

export const stateCollection = createModel<IRootModel>()({
  state: {
    data: null,
    loading: false,
  } as IStateCollection,
  reducers: {
    setStateCollection: (state, payload: IStateCollectionDto) => ({ ...state, data: payload }),
    setStateCollectionLoading: (state, payload: boolean) => ({ ...state, loading: payload }),
    clearStateCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    async getStateCollection(params: any) {
      dispatch.stateCollection.setStateCollectionLoading(true);
      return await statesTransport
        .getStateCollection(params)
        .then((response) => {
          dispatch.stateCollection.setStateCollection(response);
          return response;
        })
        .catch((error) => {
          showError(error);
          return null;
        })
        .finally(() => {
          dispatch.stateCollection.setStateCollectionLoading(false);
        });
    },
    async getStateById(id: number) {
      dispatch.stateCollection.setStateCollectionLoading(true);
      return await statesTransport
        .getStateById(id)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          showError(error);
          return null;
        })
        .finally(() => {
          dispatch.stateCollection.setStateCollectionLoading(false);
        });
    },
  }),
});
