import React from 'react';
import { Form, InputNumber, Select } from 'antd';
import { EFormFieldMessages, EScheduleFields, EShiftValues } from 'common/const/enum.const';
import { IOption } from 'common/models';
import { ReactComponent as ChevronDown } from 'app/assets/images/chevron-down.svg';

interface IComponentProps {
  rowId: string | number;
  colId: string;
  shiftOptions: IOption[];
  facilityOptions?: IOption[];
  shiftValue?: string;
  withFacility?: boolean;
}

export const TechnicianScheduleItem: React.FC<IComponentProps> = (props) => {
  const { rowId, colId, shiftOptions, facilityOptions, shiftValue, withFacility } = props;

  return (
    <div className="technician-schedule__item">
      <Form.Item name={[rowId, colId, EScheduleFields.Shift]}>
        <Select options={shiftOptions} suffixIcon={<ChevronDown />} />
      </Form.Item>

      {shiftValue !== EShiftValues.DayOff && (
        <>
          <div className="technician-schedule__item-container">
            <span>Capacity</span>

            <Form.Item
              name={[rowId, colId, EScheduleFields.Capaсity]}
              rules={[{ required: true, message: EFormFieldMessages.required }]}
            >
              <InputNumber />
            </Form.Item>
          </div>

          {withFacility && (
            <div className="technician-schedule__item-container">
              <span>Facility</span>

              <Form.Item
                name={[rowId, colId, EScheduleFields.FacilityId]}
                rules={[{ required: true, message: EFormFieldMessages.required }]}
              >
                <Select options={facilityOptions} suffixIcon={<ChevronDown />} />
              </Form.Item>
            </div>
          )}
        </>
      )}
    </div>
  );
};
