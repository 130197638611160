import { createModel } from '@rematch/core';
import dayjs from 'dayjs';
import { EDateFormat } from 'common/const/enum.const';
import { showError } from 'common/helpers/error.helper';
import { IRootModel } from 'app/store';
import {
  IAvailableEntitiesCollectionPayload,
  IAvailableEntitiesCollection,
  IAvailableEntitiesCollectionDataPayload,
  IAvailableEntitiesCollectionLoadingPayload,
} from 'entities/AvailableEntities/AvailableEntities.models';
import { availableEntitiesTransport } from 'entities/AvailableEntities/AvailableEntities.transport';

export const availableEntitiesCollection = createModel<IRootModel>()({
  state: {
    data: null,
  } as IAvailableEntitiesCollection,
  reducers: {
    setAvailableEntitiesCollection: (state, payload: IAvailableEntitiesCollectionDataPayload) => ({
      ...state,
      data: {
        ...state.data,
        [payload.weekDay]: { ...state.data?.[payload.weekDay], data: payload.data },
      },
    }),
    setAvailableEntitiesCollectionLoading: (state, payload: IAvailableEntitiesCollectionLoadingPayload) => ({
      ...state,
      data: {
        ...state.data,
        [payload.weekDay]: { ...state.data?.[payload.weekDay], loading: payload.loading },
      },
    }),
    clearAvailableEntitiesCollection: (state) => ({ ...state, data: null }),
  },
  effects: (dispatch) => ({
    getAvailableEntitiesCollection(payload: IAvailableEntitiesCollectionPayload) {
      const { date: dateFrom } = payload;

      for (let i = 1; i <= 7; i++) {
        const date = dayjs(dateFrom).day(i).format(EDateFormat.FullDate);
        const weekDay = dayjs(dateFrom).day(i).format(EDateFormat.ShortDayOfWeek).toLowerCase();

        dispatch.availableEntitiesCollection.setAvailableEntitiesCollectionLoading({ weekDay, loading: true });

        availableEntitiesTransport
          .getAvailableEntitiesCollection({ ...payload, date })
          .then((response) => {
            dispatch.availableEntitiesCollection.setAvailableEntitiesCollection({ weekDay, data: response.data });
          })
          .catch((error) => {
            showError(error);
          })
          .finally(() => {
            dispatch.availableEntitiesCollection.setAvailableEntitiesCollectionLoading({ weekDay, loading: false });
          });
      }
    },
  }),
});
