import React from 'react';
import { Button, DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { dateFilterCustomFormat } from 'common/helpers/date.helper';
import { EDateFormat } from 'common/const/enum.const';
import { ReactComponent as ChevronDown } from 'app/assets/images/chevron-down.svg';
import { ReactComponent as Calendar } from 'app/assets/images/calendar.svg';

dayjs.extend(weekOfYear);
dayjs.extend(updateLocale);
dayjs.updateLocale('en', {
  weekStart: 1,
});

interface IComponentProps {
  value: Dayjs;
  onChange: (value: Dayjs) => void;
}

export const DateFilter: React.FC<IComponentProps> = ({ value, onChange }) => {
  const decrement = () => {
    onChange(value?.day(1).subtract(1, 'w'));
  };

  const increment = () => {
    onChange(value?.day(1).add(1, 'w'));
  };

  const handleDateChange = (value: Dayjs | null) => {
    if (value) {
      onChange(value.day(1));
    }
  };

  return (
    <div className="date-filter">
      <Button className="date-filter__btn date-filter__btn-decrement" icon={<ChevronDown />} onClick={decrement} />
      <div className="date-filter__container">
        <DatePicker
          value={value}
          className="date-filter__calendar"
          picker="week"
          onChange={handleDateChange}
          suffixIcon={<Calendar />}
          allowClear={false}
          inputReadOnly
          format={dateFilterCustomFormat}
          cellRender={(date) => {
            return (
              <div className={`date-filter__calendar_cell ${dayjs().isSame(date, 'D') && 'today'}`}>
                {date.format(EDateFormat.DateOfDay)}
              </div>
            );
          }}
        />
        <ChevronDown className="date-filter__calendar-arrow" />
      </div>
      <Button className="date-filter__btn date-filter__btn-increment" icon={<ChevronDown />} onClick={increment} />
    </div>
  );
};
