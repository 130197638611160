import { message } from 'antd';
import { ERROR_MESSAGE_DURATION_S } from 'common/config';

export const showError = (error: any) => {
  console.error(error);

  if (!error.status) {
    message.error(error?.message, ERROR_MESSAGE_DURATION_S);
    return;
  }

  if (error.status !== 422) {
    message.error(error?.data?.error, ERROR_MESSAGE_DURATION_S);
    return;
  }

  const errorValue = Object.values(error?.data?.errors)[0];

  if (Array.isArray(errorValue)) {
    message.error(errorValue, ERROR_MESSAGE_DURATION_S);
    return;
  }

  if (errorValue) {
    Object.values(errorValue)?.forEach((item) => {
      message.error(Object.values(item)[0] as string[], ERROR_MESSAGE_DURATION_S);
    });
  }
};
