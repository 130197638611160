import React, { useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { connect } from 'react-redux';
import { EDatabaseActions, EDatabaseTabValues, EOrderDirections, ECommonOrderFields } from 'common/const/enum.const';
import { DatabaseTable } from 'common/components/DatabaseTable';
import { toDataSourseMapper } from 'common/helpers/data.helper';
import { ConfirmationModal } from 'common/components/СonfirmationModal';
import { RootDispatch, RootState, history } from 'app/store';
import { getDatabaseColumns, getDatabasePath } from 'entities/Database/Database.helper';
import { databaseColumns } from 'entities/Database/Database.const';

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch>;

const CertificatesComponent: React.FC<AllType> = (props) => {
  const {
    certificateCollection,
    certificateModel,
    getCertificateCollection,
    clearCertificateCollection,
    deleteCertificateModel,
  } = props;
  const [orderField, setOrderField] = useState<ECommonOrderFields>(ECommonOrderFields.Name);
  const [orderDirection, setOrderDirection] = useState<EOrderDirections | undefined>(EOrderDirections.ASC);
  const [certificateId, setCertificateId] = useState<number | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { data: certificateCollectionData, loading: certificateCollectionLoading } = certificateCollection;
  const { loading: certificateModelLoading } = certificateModel;

  const dataSource = toDataSourseMapper(certificateCollectionData?.data);

  const addPatients = () => {
    const path = getDatabasePath(EDatabaseTabValues.Certificates, EDatabaseActions.Add);

    history.push(path);
  };

  const onEdit = (id: number) => {
    const path = getDatabasePath(EDatabaseTabValues.Certificates, EDatabaseActions.Edit, id);

    history.push(path);
  };

  const onDelete = (id: number) => {
    setCertificateId(id);
    setOpen(true);
  };

  const onYesClick = () => {
    certificateId &&
      deleteCertificateModel({
        id: certificateId,
        onSuccess: () => {
          getCertificateCollection({ orderField, orderDirection });
          setCertificateId(null);
          setOpen(false);
        },
      });
  };

  const onNoClick = () => {
    setCertificateId(null);
    setOpen(false);
  };

  const handleSort = (orderField: ECommonOrderFields, orderDirection?: EOrderDirections) => {
    setOrderField(orderField);
    setOrderDirection(orderDirection);
  };

  const columns = getDatabaseColumns(databaseColumns, onEdit, onDelete);

  useEffect(() => {
    getCertificateCollection({ orderField, orderDirection });

    return () => {
      clearCertificateCollection();
    };
  }, [orderField, orderDirection]);

  return (
    <Spin spinning={certificateCollectionLoading}>
      <div className="database__container_main-header">
        <Button className="btn-primary" onClick={addPatients}>
          Add Patients
        </Button>
      </div>

      <DatabaseTable dataSource={dataSource} columns={columns} sort={handleSort} />

      <ConfirmationModal open={open} loading={certificateModelLoading} onYesClick={onYesClick} onNoClick={onNoClick} />
    </Spin>
  );
};

const mapState = (state: RootState) => ({
  certificateCollection: state.certificateCollection,
  certificateModel: state.certificateModel,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getCertificateCollection: dispatch.certificateCollection.getCertificateCollection,
  clearCertificateCollection: dispatch.certificateCollection.clearCertificateCollection,
  deleteCertificateModel: dispatch.certificateModel.deleteCertificateModel,
});

export const Certificates = connect(mapState, mapDispatch)(CertificatesComponent);
