import React, { useState } from 'react';
import { Button, Form } from 'antd';
import { connect } from 'react-redux';
import { Search } from 'common/components/Search';
import { IOption } from 'common/models';
import { toOptionsMapper } from 'common/helpers/data.helper';
import { Selector } from 'common/components/Selector';
import { ECommonOrderFields, EFormFieldMessages, EOrderDirections, EStudyFieldNames } from 'common/const/enum.const';
import { allOption } from 'common/const/data.const';
import { RootDispatch } from 'app/store';
import { ReactComponent as DeleteIcon } from 'app/assets/images/delete-icon.svg';

interface IComponentProps {
  id: string;
  certificatesOptions?: IOption[];
  onDeleteClick: (id: string) => void;
}

type AllType = ReturnType<typeof mapDispatch> & IComponentProps;

const StudyMandatoryCertificatesItemComponent: React.FC<AllType> = (props) => {
  const { id, certificatesOptions, onDeleteClick, getStateCollection } = props;
  const [stateOptions, setStateOptions] = useState<IOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <div className="study-card__mandatory-certification_item">
      <Form.Item name={[EStudyFieldNames.Certificates, id, 'stateId']}>
        <Search
          className="study-card__mandatory-certification_item-search"
          options={[allOption, ...stateOptions]}
          loading={loading}
          onFetch={(value) => {
            setLoading(true);
            getStateCollection({
              name: value,
              limit: 0,
              orderField: ECommonOrderFields.Name,
              orderDirection: EOrderDirections.ASC,
            }).then((response) => {
              if (response) {
                setStateOptions(toOptionsMapper(response.data));
                setLoading(false);
              }
            });
          }}
        />
      </Form.Item>

      <Form.Item
        name={[EStudyFieldNames.Certificates, id, 'credentials']}
        rules={[{ required: true, message: EFormFieldMessages.required }]}
      >
        <Selector className="study-card__mandatory-certification_item-select" options={certificatesOptions} mode="multiple" />
      </Form.Item>

      <Button className="btn-icon" icon={<DeleteIcon />} onClick={() => onDeleteClick(id)} />
    </div>
  );
};

const mapDispatch = (dispatch: RootDispatch) => ({
  getStateCollection: dispatch.stateCollection.getStateCollection,
});

export const StudyMandatoryCertificatesItem = connect(null, mapDispatch)(StudyMandatoryCertificatesItemComponent);
