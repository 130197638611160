import React from 'react';
import { Button } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { EDatabaseTabValues } from 'common/const/enum.const';
import { ReactComponent as ViewIcon } from 'app/assets/images/view-icon.svg';
import { ReactComponent as EditIcon } from 'app/assets/images/edit-icon.svg';
import { ReactComponent as DeleteIcon } from 'app/assets/images/delete-icon.svg';
import { IDatabaseColumnsType } from 'entities/Database/Database.models';

export const getDatabasePath = (tab?: string, action?: string, id?: number) => {
  let path = '/database';

  if (tab) {
    path += `/${tab}`;
  } else {
    path += `/${EDatabaseTabValues.Hospitals}`;
  }

  if (action) {
    path += `/${action}`;
  }

  if (id) {
    path += `/${id}`;
  }

  return path;
};

export const getDatabaseColumns = (
  columns: ColumnsType<IDatabaseColumnsType>,
  onEdit: (id: number) => void,
  onDelete: (id: number) => void,
  onView?: (id: number) => void,
) => {
  return [
    ...columns,
    {
      title: '',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="database-table__actions">
            {onView && <Button className="btn-icon" icon={<ViewIcon />} onClick={() => onView(record.id)} />}
            <Button className="btn-icon" icon={<EditIcon />} onClick={() => onEdit(record.id)} />
            <Button className="btn-icon" icon={<DeleteIcon />} onClick={() => onDelete(record.id)} />
          </div>
        );
      },
      width: '108px',
    },
  ] as ColumnsType<IDatabaseColumnsType>;
};
