import React from 'react';
import dayjs, { Dayjs } from 'dayjs';
import type { ColumnsType } from 'antd/es/table';
import { EDateFormat, EScheduleFields, EShiftValues } from 'common/const/enum.const';
import { scheduleShiftOptions } from 'common/const/schedule.const';
import { IOption } from 'common/models';
import { allOption } from 'common/const/data.const';
import { getColumnTitle, getWeekDays } from 'common/helpers/date.helper';
import { TechnicianScheduleItem } from 'entities/TechnicianSchedule/components/TechnicianScheduleItem';
import {
  IScheduleItem,
  ITechnicianScheduleCollectionUpsertItem,
  ITechnicianScheduleDto,
  ITechnicianScheduleEntity,
  ITechnicianScheduleEntityChangePayload,
} from 'entities/TechnicianSchedule/TechnicianSchedule.models';
import { ITechnicianModelDto } from 'entities/Technicians/Technicians.models';
import { TechnicianScheduleLocationItem } from 'entities/TechnicianSchedule/components/TechnicianScheduleLocationItem';

export const toTechnicianScheduleDataSourceMapper = (
  dateFrom: Dayjs,
  technician: IOption,
  schedule: ITechnicianScheduleDto[] | null,
  techniciansCollection?: ITechnicianModelDto[],
) => {
  if (!schedule) {
    return [];
  }

  const dataSource: ITechnicianScheduleEntity[] = [];

  const selectedTechnicians = techniciansCollection?.filter((item) =>
    technician.value === allOption.value ? item : item.id === technician.value,
  );

  selectedTechnicians?.forEach((technician) => {
    const technicianSchedule = schedule.filter((item) => item.technicianId === technician.id);

    const entity: ITechnicianScheduleEntity = {
      key: technician.id,
      technicianId: technician.id,
      technicianName: technician.name,
    };

    for (let i = 1; i <= 7; i++) {
      const date = dateFrom.day(i).format(EDateFormat.FullDate);
      const scheduleItem = technicianSchedule.find((item) => item.date === date);

      if (scheduleItem) {
        entity.id = scheduleItem.id;
        entity.facilityId = scheduleItem.facilityId;
        entity[date] = {
          facilityId: scheduleItem.facilityId,
          capacity: scheduleItem.capacity,
          shift: scheduleItem.shift,
        };
      }
    }

    dataSource.push(entity);
  });

  return dataSource;
};

export const renderTechnicianScheduleCell = (record: ITechnicianScheduleEntity, weekDay: string, facilityId?: number) => {
  const scheduleFacility = (record?.[weekDay] as IScheduleItem)?.facilityId;

  if (scheduleFacility && scheduleFacility !== facilityId) {
    return <TechnicianScheduleLocationItem facilityId={(record?.[weekDay] as IScheduleItem)?.facilityId} />;
  }

  return (
    <TechnicianScheduleItem
      rowId={record?.technicianId as number}
      colId={weekDay}
      shiftOptions={scheduleShiftOptions}
      shiftValue={(record?.[weekDay] as IScheduleItem)?.shift}
    />
  );
};

export const getTechnicianScheduleCellClassname = (record: ITechnicianScheduleEntity, weekDay: string, facilityId?: number) => {
  const scheduleFacility = (record?.[weekDay] as IScheduleItem)?.facilityId;

  if (scheduleFacility && scheduleFacility !== facilityId) {
    return;
  }

  return (record?.[weekDay] as IScheduleItem)?.shift;
};

export const renderTechnicianScheduleRecords = (dateFrom: Dayjs, facilityId?: number) => {
  const { mon, tue, wed, thu, fri, sat, sun } = getWeekDays(dateFrom);

  return [
    {
      title: 'Technician',
      dataIndex: 'technicianId',
      render: (_, record) => record.technicianName,
    },
    {
      title: getColumnTitle(dateFrom, 1),
      dataIndex: mon,
      onCell: (record) => {
        return {
          className: getTechnicianScheduleCellClassname(record, mon, facilityId),
        };
      },
      render: (_, record) => renderTechnicianScheduleCell(record, mon, facilityId),
    },
    {
      title: getColumnTitle(dateFrom, 2),
      dataIndex: tue,
      onCell: (record) => {
        return {
          className: getTechnicianScheduleCellClassname(record, tue, facilityId),
        };
      },
      render: (_, record) => renderTechnicianScheduleCell(record, tue, facilityId),
    },
    {
      title: getColumnTitle(dateFrom, 3),
      dataIndex: wed,
      onCell: (record) => {
        return {
          className: getTechnicianScheduleCellClassname(record, wed, facilityId),
        };
      },
      render: (_, record) => renderTechnicianScheduleCell(record, wed, facilityId),
    },
    {
      title: getColumnTitle(dateFrom, 4),
      dataIndex: thu,
      onCell: (record) => {
        return {
          className: getTechnicianScheduleCellClassname(record, thu, facilityId),
        };
      },
      render: (_, record) => renderTechnicianScheduleCell(record, thu, facilityId),
    },
    {
      title: getColumnTitle(dateFrom, 5),
      dataIndex: fri,
      onCell: (record) => {
        return {
          className: getTechnicianScheduleCellClassname(record, fri, facilityId),
        };
      },
      render: (_, record) => renderTechnicianScheduleCell(record, fri, facilityId),
    },
    {
      title: getColumnTitle(dateFrom, 6),
      dataIndex: sat,
      onCell: (record) => {
        return {
          className: getTechnicianScheduleCellClassname(record, sat, facilityId),
        };
      },
      render: (_, record) => renderTechnicianScheduleCell(record, sat, facilityId),
    },
    {
      title: getColumnTitle(dateFrom, 7),
      dataIndex: sun,
      onCell: (record) => {
        return {
          className: getTechnicianScheduleCellClassname(record, sun, facilityId),
        };
      },
      render: (_, record) => renderTechnicianScheduleCell(record, sun, facilityId),
    },
  ] as ColumnsType<ITechnicianScheduleEntity>;
};

export const toTechnicianScheduleFormValuesMapper = (data: ITechnicianScheduleEntity[], dateFrom: Dayjs) => {
  const { mon, tue, wed, thu, fri, sat, sun } = getWeekDays(dateFrom);
  const values: { [key: number]: Object } = {};

  data.forEach((item) => {
    values[item.technicianId as number] = {
      [mon]: item[mon],
      [tue]: item[tue],
      [wed]: item[wed],
      [thu]: item[thu],
      [fri]: item[fri],
      [sat]: item[sat],
      [sun]: item[sun],
    };
  });

  return values;
};

export const toTechnicianScheduleParamsMapper = (technician: IOption, dateFrom: Dayjs, hospital?: IOption) => {
  return {
    technicianId: technician.value === allOption.value ? undefined : Number(technician.value),
    dateFrom: dateFrom.format(EDateFormat.FullDate),
    dateTo: dateFrom.day(7).format(EDateFormat.FullDate),
    clinicId: hospital && Number(hospital.value),
    limit: 0,
  };
};

export const getTechnicianScheduleFieldError = (items: ITechnicianScheduleCollectionUpsertItem[]) => {
  const errors: { name: (string | number)[]; errors: string[] }[] = [];

  items.forEach((item) => {
    errors.push(
      {
        name: [item.sourceItem?.technicianId as number, item.sourceItem?.date as string, 'capacity'],
        errors: [item.error as string],
      },
      {
        name: [item.sourceItem?.technicianId as number, item.sourceItem?.date as string, 'shift'],
        errors: [item.error as string],
      },
    );
  });

  return errors;
};

export const toTechnicianScheduleItemMapper = (
  payload: ITechnicianScheduleEntityChangePayload,
  scheduleItem?: ITechnicianScheduleDto,
  technician?: ITechnicianModelDto,
) => {
  // @ts-ignore
  const newScheduleItem: ITechnicianScheduleDto = {
    edited: true,
  };

  if (scheduleItem) {
    newScheduleItem.technicianName = scheduleItem.technicianName;
    newScheduleItem.id = scheduleItem.id;
    newScheduleItem.technicianId = scheduleItem.technicianId;
    newScheduleItem.date = scheduleItem.date;
    newScheduleItem.scheduleId = scheduleItem.scheduleId;
  } else {
    newScheduleItem.technicianName = technician?.name as string;
    newScheduleItem.scheduleId = dayjs().valueOf();
    newScheduleItem.technicianId = Number(payload.rowId);
    newScheduleItem.date = payload.colId;
  }

  if (payload.fieldId === EScheduleFields.Shift) {
    newScheduleItem.shift = payload.value as EShiftValues;
    newScheduleItem.capacity = scheduleItem?.capacity;
    newScheduleItem.facilityId = scheduleItem?.facilityId ? scheduleItem?.facilityId : payload.facilityId;
  }

  if (payload.fieldId === EScheduleFields.Capaсity) {
    newScheduleItem.shift = scheduleItem?.shift as EShiftValues;
    newScheduleItem.capacity = payload.value !== null ? Number(payload.value) : undefined;
    newScheduleItem.facilityId = scheduleItem?.facilityId ? scheduleItem?.facilityId : payload.facilityId;
  }

  if (payload.value === EShiftValues.DayOff) {
    newScheduleItem.capacity = undefined;
    newScheduleItem.facilityId = undefined;
  }

  return newScheduleItem;
};
