import { type MenuProps } from 'antd';
import { EDatabaseMenuItems, EDatabaseTabValues } from 'common/const/enum.const';

export const databaseMenuItems: MenuProps['items'] = [
  {
    label: EDatabaseMenuItems.Hospitals,
    key: EDatabaseTabValues.Hospitals,
  },
  {
    label: EDatabaseMenuItems.Facilities,
    key: EDatabaseTabValues.Facilities,
  },
  {
    label: EDatabaseMenuItems.SpecialEquipment,
    key: EDatabaseTabValues.SpecialEquipment,
  },
  {
    label: EDatabaseMenuItems.Technicians,
    key: EDatabaseTabValues.Technicians,
  },
  {
    label: EDatabaseMenuItems.Studies,
    key: EDatabaseTabValues.Studies,
  },
  {
    label: EDatabaseMenuItems.Certificates,
    key: EDatabaseTabValues.Certificates,
  },
];

export const databaseColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    sorter: true,
  },
];
