import '@axmit/persante-hospitals';

export interface IAuthModelPayload extends Components.Schemas.LoginRequest {}
export interface IAuthModelDto extends Components.Schemas.LoginResponse {}

export interface ITokenModel {
  access?: {
    id: string;
    token: string;
    userId: string;
    issuedAt: number;
    expiredAt: number;
    uid: number;
    name: string;
  };
  refresh?: {
    id: string;
    token: string;
    userId: string;
    issuedAt: number;
    expiredAt: number;
  };
}

export interface IAuthModel {
  data: ITokenModel | null;
  loading: boolean;
}

export const refreshTokenExpiration = 30 * 86400; // = 30 days
