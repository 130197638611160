import React, { useEffect, useState } from 'react';
import { Spin, Table } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { connect } from 'react-redux';
import { ShiftFilter } from 'common/components/ShiftFilter';
import { DateFilter } from 'common/components/DateFilter';
import { IOption } from 'common/models';
import { RootDispatch, RootState } from 'app/store';
import { DashboardScheduleFilters } from 'entities/DashboardSchedule/components/DashboardScheduleFilters';
import {
  renderDashboardScheduleRecords,
  toDashboardScheduleParamsMapper,
  toDashboardSchedulesDataSourceMapper,
} from 'entities/DashboardSchedule/DashboardSchedule.helper';

interface IComponentProps {
  hospitalsOptions?: IOption[];
  facilitiesOptions?: IOption[];
  hospital?: IOption;
  facilityIds?: number[];
  shift?: boolean;
  loading: boolean;
  onHospitalChange: (value: IOption) => void;
  onFacilityIdsChange: (value?: number[]) => void;
  onShiftChange: (value: boolean) => void;
}

type AllType = ReturnType<typeof mapState> & ReturnType<typeof mapDispatch> & IComponentProps;

const DashboardScheduleComponent: React.FC<AllType> = (props) => {
  const {
    hospitalsOptions,
    facilitiesOptions,
    hospital,
    facilityIds,
    shift,
    loading,
    onHospitalChange,
    onFacilityIdsChange,
    onShiftChange,
    facilitiesCollection,
    dashboardScheduleCollection,
    getDashboardScheduleCollection,
    clearDashboardSchedule,
    getAvailableEntitiesCollection,
  } = props;
  const { data: facilitiesCollectionData } = facilitiesCollection;
  const { data: dashboardScheduleCollectionData, loading: dashboardScheduleCollectionLoading } = dashboardScheduleCollection;
  const facilityId = facilityIds?.[0];

  const [dateFrom, setDateFrom] = useState<Dayjs>(dayjs().day(1));

  const params = toDashboardScheduleParamsMapper(dateFrom, shift, hospital, facilityId);
  const columns = renderDashboardScheduleRecords(dateFrom);
  const dataSource = toDashboardSchedulesDataSourceMapper(
    dateFrom,
    dashboardScheduleCollectionData?.data,
    facilityId,
    facilitiesCollectionData?.data,
  );

  const handleDateChange = (value: Dayjs) => {
    setDateFrom(value);
  };

  useEffect(() => {
    if (facilityId) {
      // @ts-ignore
      getDashboardScheduleCollection(params);

      if (facilityId) {
        // @ts-ignore
        getAvailableEntitiesCollection({ date: params.dateFrom, shift: params.shift, facilityId });
      }
    }
  }, [facilityId, hospital, dateFrom, shift]);

  useEffect(() => {
    return () => {
      clearDashboardSchedule();
    };
  }, []);

  return (
    <div className="content dashboard-schedule">
      <div className="content__header">
        <div className="content__header-title dashboard-schedule__title">Dashboard</div>

        <ShiftFilter value={shift} onChange={onShiftChange} />
      </div>

      <div className="content__container dashboard-schedule__container">
        <DashboardScheduleFilters
          hospitalsOptions={hospitalsOptions}
          facilitiesOptions={facilitiesOptions}
          hospitalValue={hospital}
          facilityValue={facilityId}
          onHospitalChange={onHospitalChange}
          onFacilityChange={onFacilityIdsChange}
        />

        <div className="content__container_main">
          <DateFilter value={dateFrom} onChange={handleDateChange} />

          <Spin spinning={loading || dashboardScheduleCollectionLoading}>
            <Table className="schedule" dataSource={dataSource} columns={columns} pagination={false} />
          </Spin>
        </div>
      </div>
    </div>
  );
};

const mapState = (state: RootState) => ({
  facilitiesCollection: state.facilitiesCollection,
  dashboardScheduleCollection: state.dashboardScheduleCollection,
});
const mapDispatch = (dispatch: RootDispatch) => ({
  getDashboardScheduleCollection: dispatch.dashboardScheduleCollection.getDashboardScheduleCollection,
  clearDashboardSchedule: dispatch.dashboardScheduleCollection.clearDashboardSchedule,
  getAvailableEntitiesCollection: dispatch.availableEntitiesCollection.getAvailableEntitiesCollection,
});

export const DashboardSchedule = connect(mapState, mapDispatch)(DashboardScheduleComponent);
